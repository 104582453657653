import Vue from 'vue'

// import ui components
import Btn from './buttons/Btn'

import Autocomplete from './fields/Autocomplete'
import SelectField from './fields/SelectField'
import Textarea from './fields/Textarea'
import TextField from './fields/TextField'
import MenuDropdown from './fields/MenuDropdown'
import Radios from './fields/Radios'

import Text from './typographies/Text'

// add ui component in vue global scope
Vue.component('MybbBtn', Btn)
Vue.component('MybbText', Text)

Vue.component('MybbAutocomplete', Autocomplete)
Vue.component('MybbSelectField', SelectField)
Vue.component('MybbSelect', SelectField)
Vue.component('MybbTextarea', Textarea)
Vue.component('MybbTextField', TextField)
Vue.component('MybbMenuDropdown', MenuDropdown)
Vue.component('MybbRadios', Radios)
