module.exports = {
  container: 'gathering/transport/container',
  configuration: {
    button: 'gathering/transport/configuration/button',
    modal: {
      container: 'gathering/transport/configuration/modal/container',
      transferEnabled: 'gathering/transport/configuration/modal/transferEnabled',
      businessUnit: 'gathering/transport/configuration/modal/businessUnit',
      companyCode: 'gathering/transport/configuration/modal/companyCode',
      geoCode: 'gathering/transport/configuration/modal/geoCode',
      centerCost: 'gathering/transport/configuration/modal/centerCost',
      accountCode: 'gathering/transport/configuration/modal/accountCode',
      localCode: 'gathering/transport/configuration/modal/localCode',
      productCode: 'gathering/transport/configuration/modal/productCode',
      poCode: 'gathering/transport/configuration/modal/poCode',
      comment: 'gathering/transport/configuration/modal/comment',
      submit: 'gathering/transport/configuration/modal/submit'
    }
  }
}
