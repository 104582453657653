<template>
  <v-text-field v-bind="props" v-on="$listeners" class="CboTextField" height="46" outlined>
    <template v-if="icon" v-slot:prepend-inner>
      <v-icon>{{ icon }}</v-icon>
    </template>

    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
  </v-text-field>
</template>

<script>
export default {
  name: 'CongrexBoTextField',
  props: {
    // Reverse [hide-details] props to [true] by default (most used case)
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false
    }
  },
  computed: {
    props() {
      const hideDetails = this.hideDetails && !this.$attrs['error-messages']
      return Object.assign({}, this.$props, this.$attrs, { hideDetails })
    }
  }
}
</script>

<style lang="scss">
@import './mixin.scss';

.CboTextField {
  @include cbo-fields;
}
</style>
