import gql from 'graphql-tag'

import WITH_USER from '../Participant/fragments/WithUser'

export const EXPENSE_NOTE = gql`
  fragment expenseNote on ExpenseNote {
    comments {
      expenseNoteCommentUuid
      date
      text
      actionStatus
      user {
        firstName
        lastName
      }
      file {
        name
        content
      }
      oldLineValues
    }
    createdAt
    expenseNoteUuid
    participantUuid
    lastUpdateDate
    status
    origin
    totalPrice
    visualIdentifier
    paymentDate
    lines {
      comment
      createdAt
      description
      expenseNoteLineUuid
      amountKilometers
      departureAddress
      expenseNoteCategory {
        expenseNoteCategoryUuid
        label
      }
      file {
        name
        content
        fileUuid
      }
      expenseNoteLineUuid
      feeDate
      price
      status
      updatedAt
    }
  }
`

export const GET_EXPENSE_NOTES = gql`
  query ExpenseNotes($gatheringUuid: GUID!) {
    expenseNotes(gatheringUuid: $gatheringUuid, limit: 5000) {
      createdAt
      expenseNoteUuid
      participant {
        lastName
        firstName
      }
      lines {
        expenseNoteLineUuid
        price
        status
      }
      status
      totalPrice
      visualIdentifier
    }
  }
`

export const GET_FILE = gql`
  query file($fileUuid: GUID!) {
    file(fileUuid: $fileUuid) {
      content
      fileUuid
      name
    }
  }
`

export const GET_EXPENSE_NOTE = gql`
  query ExpenseNote($expenseNoteUuid: GUID!) {
    expenseNote(expenseNoteUuid: $expenseNoteUuid) {
      ...expenseNote
      participant {
        ...ParticipantWithUser
      }
    }
  }
  ${WITH_USER}
  ${EXPENSE_NOTE}
`

export const ASK_ARBITRATION = gql`
  mutation askExpenseNoteArbitration(
    $expenseNoteUuid: GUID!
    $comment: String!
    $validatedExpenseNoteLineUuids: [GUID]
  ) {
    askExpenseNoteArbitration(
      expenseNoteUuid: $expenseNoteUuid
      comment: $comment
      validatedExpenseNoteLineUuids: $validatedExpenseNoteLineUuids
    ) {
      expenseNoteUuid
    }
  }
`

export const VALIDATE_EXPENSE_NOTE = gql`
  mutation validateExpenseNote($expenseNoteUuid: GUID!, $comment: String, $refusedExpenseNoteLineUuids: [GUID]) {
    validateExpenseNote(
      expenseNoteUuid: $expenseNoteUuid
      comment: $comment
      refusedExpenseNoteLineUuids: $refusedExpenseNoteLineUuids
    ) {
      expenseNoteUuid
    }
  }
`

export const REFUSE_EXPENSE_NOTE = gql`
  mutation refuseExpenseNote($expenseNoteUuid: GUID!, $comment: String) {
    refuseExpenseNote(expenseNoteUuid: $expenseNoteUuid, comment: $comment) {
      expenseNoteUuid
    }
  }
`

export const CONFIRM_PAYMENT_NOTE = gql`
  mutation confirmExpenseNotePayment($expenseNoteUuid: GUID!, $date: String!) {
    confirmExpenseNotePayment(expenseNoteUuid: $expenseNoteUuid, date: $date) {
      expenseNoteUuid
    }
  }
`

export const GET_ALL_EXPENSE_NOTE_CATEGORIES = gql`
  query expenseNoteCategories {
    expenseNoteCategories {
      expenseNoteCategoryUuid
      label
    }
  }
`

export const CREATE_EXPENSE_NOTE = gql`
  mutation createInternalExpenseNote($gatheringUuid: GUID!, $participantUuid: GUID!, $lines: [InputExpenseNoteLine!]!) {
    createInternalExpenseNote(gatheringUuid: $gatheringUuid, participantUuid: $participantUuid, lines: $lines) {
      expenseNoteUuid
    }
  }
`

export const UPDATE_EXPENSE_NOTE = gql`
  mutation updateExpenseNote(
    $gatheringUuid: GUID!
    $participantUuid: GUID!
    $expenseNoteUuid: GUID!
    $lines: [InputExpenseNoteLine!]!
  ) {
    updateExpenseNote(
      gatheringUuid: $gatheringUuid
      participantUuid: $participantUuid
      expenseNoteUuid: $expenseNoteUuid
      lines: $lines
    ) {
      expenseNoteUuid
    }
  }
`

export const UPDATE_EXPENSE_NOTE_LINE = gql`
  mutation updateExpenseNoteLine(
    $expenseNoteUuid: GUID!
    $expenseNoteLineEntry: ExpenseNoteLineInput!
    $comment: String!
    $attachment: FileInput
  ) {
    updateExpenseNoteLine(
      expenseNoteUuid: $expenseNoteUuid
      expenseNoteLineEntry: $expenseNoteLineEntry
      comment: $comment
      attachment: $attachment
    ) {
      ...expenseNote
    }
  }
  ${EXPENSE_NOTE}
`

export const UPDATE_EXPENSE_NOTE_LINES = gql`
  mutation updateInternalExpenseNoteLines(
    $expenseNoteUuid: GUID!
    $expenseNoteLineEntries: [ExpenseNoteLineWithCommentInput!]!
  ) {
    updateInternalExpenseNoteLines(expenseNoteUuid: $expenseNoteUuid, expenseNoteLineEntries: $expenseNoteLineEntries) {
      ...expenseNote
    }
  }
  ${EXPENSE_NOTE}
`

export const GET_EXPENSE_NOTES_CATEGORIES = gql`
  query expenseNoteCategories {
    expenseNoteCategories {
      expenseNoteCategoryUuid
      label
    }
  }
`
