import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify)

const theme = {
  primary: '#1b9af7',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',

  // ADMIN
  'admin-primary': '#9C27B0',

  //SERF
  'serf-primary': '#1b9af7',
  'serf-signed': '#4caf50',
  'serf-soon-expire': '#ff9800',
  'serf-expired': '#f44336',
  'serf-not-signed': '#979797',
  'serf-refused': '#9c27b0',
  'serf-repealed': '#275bb0',

  // CONGREX
  'congrex-primary': '#005b7e',
  'congrex-primary-lighten1': '#093B68',
  'congrex-disabled': '#f0f0f0',

  // MY BIOGEN
  'mybb-success': '#AFCE79',
  'mybb-warning': '#FFAE44',
  'mybb-error': '#CE7979',
  'mybb-grey': '#475059',
  'mybb-grey-lighten1': '#9a9fa4',
  'mybb-grey-darken1': '#364450',
  'mybb-grey-border': '#5b646a',
  'mybb-grey-blue-light': '#90a3b7',
  'mybb-grey-divider': '#cdcfd2',
  'mybb-blue': '#51ABFE',
  'mybb-green': '#33CA7F',

  'mybb-text': '#0F3756',
  'mybb-light': '#B5C4D1',
  'mybb-text-lighten1': '#9AA0A5',
  'mybb-primary': '#0F3756',
  'mybb-primary-lighten1': '#2573BA',
  'mybb-primary-darken1': '#205C92',
  'mybb-primary-sidebar': '#093B68',

  'gathering-status-draft': '#FFAE44',
  'gathering-status-correction': '#FFAE44',
  'gathering-status-published': '#51ABFE',
  'gathering-status-published-front': '#33CA7F',
  'gathering-status-to-publish': '#F75590',
  'gathering-status-to-validate': '#624CAB',

  'franchises-SMA': '#624CAB',
  'franchises-BBU': '#F75590',
  'franchises-AD': '#AFCE79',
  'franchises-MS': '#2573BA'
}

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme,
      dark: theme
    }
  }
})
