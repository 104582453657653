module.exports = {
  container: 'activity/addList/container',
  item: (lastName, firstName) => ({
    lastName: `activity/addList/item/${lastName}/${firstName}/lastName`
  }),
  subscribe: {
    button: 'activity/addList/subscribe/button',
    confirm: 'activity/addList/subscribe/confirm'
  }
}
