<template>
  <v-textarea v-bind="props" v-on="$listeners" class="CboTextarea" outlined>
    <template v-if="icon" v-slot:prepend-inner>
      <v-icon>{{ icon }}</v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'CongrexBoTextarea',
  props: {
    // Reverse [hide-details] props to [true] by default (most used case)
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false
    }
  },
  computed: {
    props() {
      const hideDetails = this.hideDetails && !this.$attrs['error-messages']
      return Object.assign({}, this.$props, this.$attrs, { hideDetails })
    }
  }
}
</script>

<style lang="scss">
@import './mixin.scss';

.CboTextarea {
  @include cbo-fields;

  &.v-textarea.v-text-field--enclosed.v-text-field--outlined textarea {
    margin-top: 8px; //4px; // Override from 12px
    font-size: 14px;
  }
  &.v-text-field.v-text-field--enclosed.v-textarea .v-input__prepend-inner {
    margin-top: 17px; // for rollback ui
  }

  // Text input
  &.v-textarea.v-text-field--outlined {
    border-radius: 8px;

    .v-label {
      font-size: 14px; //12px;
      color: var(--v-mybb-grey-lighten1);
      padding-right: 4px;
      top: 14px; // Override from 18px

      &.v-label--active {
        transform: translateY(-20px) scale(0.75); // Override translate from -24 to -20
      }
    }

    & > .v-input__control > .v-input__slot {
      min-height: 46px; // Override from 56px
      border-color: var(--v-mybb-grey-lighten1);
    }
  }
}
</style>
