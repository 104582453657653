const addList = require('./addList')
const details = require('./details')
const form = require('./form')
const list = require('./list')

module.exports = {
  addList,
  details,
  form,
  list
}
