import gql from 'graphql-tag'

import { HCP_PRIMARY_DATA } from '../../Hcp/Hcp'

import BASE from './Base'

export default gql`
  fragment ParticipantWithSolicitation on Participant {
    ...ParticipantBase
    healthCareProfessional {
      ...HealthCareProfessional
    }
    solicitation {
      businessUnitUuid
      actualAndNewTherapeutic
      cancellationDate
      clinicalStudyPresentation
      confirmationDate
      congressUuid
      emailAddress
      healthCareProfessionalUuid
      initiatorUuid
      isTherapeuticCommittee
      newsSearchPath
      noBiogenObligation
      noOtherFunding
      noOtherIntercontinentalFunding
      others
      phoneNumber
      requestedSupport
      sentAt
      shareClinicalKnowledge
      signature
      solicitationUuid
      status
      supportRegistration
      supportTransport
      supportAccommodation
      therapeuticArea
      therapeuticCommitteeRole
      zoneUuid
      createdAt
      updatedAt
    }
  }
  ${BASE}
  ${HCP_PRIMARY_DATA}
`
