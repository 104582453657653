<template>
  <div>
    <mybb-text weight="bold">{{ title }}</mybb-text>

    <mybb-radios
      v-if="noScheduleActivities.length"
      :value="activitySubscriptions[null]"
      @input="value => $emit('activity-subscription', { date, interval: null, value })"
      :label="t('noSchedule')"
      :items="noScheduleActivitiesItem"
      weight="semi-bold"
      class="mt-3 mb-4"
      as-checkbox
    />

    <div v-for="interval of intervals" :key="interval.start.toISOString() + interval.end.toISOString()">
      <mybb-radios
        :value="activitySubscriptions[intervalStartKey(interval)]"
        @input="value => $emit('activity-subscription', { date, interval: intervalStartKey(interval), value })"
        :label="interval | intervalFormat"
        :items="activitiesInIntervalAsItems(interval)"
        weight="semi-bold"
        class="mt-3 mb-4"
        as-checkbox
      />

      <mybb-textarea
        v-if="intervalHasComments(interval)"
        :icon="intervalHasRestauration(interval) ? 'mdi-silverware-fork-knife' : 'mdi-comment-text'"
        :label="intervalHasRestauration(interval) ? t('commentRestauration') : t('comment')"
        :disabled="activitiesInIntervalAsItems(interval).every(item => item.disabled)"
        rows="3"
        class="mb-3"
      />
    </div>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
// import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import isSameMinute from 'date-fns/isSameMinute'
import { fr } from 'date-fns/locale'

export default {
  name: 'ActivitiesOnDay',
  props: {
    activitiesByDate: {
      type: Object
    },
    date: {
      type: String,
      required: true
    },
    gathering: {
      type: Object
    },
    subscriptions: {
      type: Object
    },
    participant: {
      type: Object
    },
    dataUser: {
      type: Object
    }
  },
  created() {
    const jobs = []

    if (this.dataUser && this.dataUser.invitationStatus === 'CONFIRMED') {
      this.activities.forEach(activity => {
        const findParticipantOnActivity = activity.activityParticipants.find(
          participant => participant.participant.participantUuid === this.dataUser.participantUuid
        )
        if (!findParticipantOnActivity) jobs.push({ date: activity.date, interval: activity.startHour, value: 0 })
        else jobs.push({ date: activity.date, interval: activity.startHour, value: activity.activityUuid })
      })

      jobs.forEach(el => {
        /**
         * tricky but we need to fake an async
         */
        setTimeout(() => {
          this.$emit('activity-subscription', { date: this.date, interval: el.interval, value: el.value })
        }, 2000)
      })
    }
  },
  computed: {
    activitySubscriptions() {
      return this.$get(this.subscriptions, [this.date], {})
    },
    title() {
      if (!this.date) return null

      return dateFormat(new Date(this.date), 'EEEE d LLLL yyyy', { locale: fr }).toUpperCase()
    },
    activities() {
      if (!this.activitiesByDate || !this.date) return []

      return Array.from(this.activitiesByDate[this.date]).sort((act1, act2) => {
        if (!act1.startHour && act2.startHour) return 1
        if (act1.startHour && !act2.startHour) return -1
        if (!act1.startHour && !act2.startHour) return 0

        const date1 = new Date(this.date)
        date1.setHours(...act1.startHour.split(':'))
        const date2 = new Date(this.date)
        date2.setHours(...act2.startHour.split(':'))

        /**
         * For non-merged intervals, if the interval start at the same hour:minute,
         * sort them by ending date
         */
        if (isSameMinute(date1, date2)) {
          if (!act1.endHour && act2.endHour) return 1
          if (act1.endHour && !act2.endHour) return -1
          if (!act1.endHour && !act2.endHour) return 0

          date1.setHours(...act1.endHour.split(':'))
          date2.setHours(...act2.endHour.split(':'))
        }

        return date1 - date2
      })
    },
    noScheduleActivities() {
      return this.activities.filter(activity => !activity.startHour || !activity.endHour)
    },
    noScheduleActivitiesItem() {
      const items = Array.from(this.noScheduleActivities)
        .sort((act1, act2) => act1.name.localeCompare(act2.name))
        .map(this.formatActivityToItem)

      return [{ value: null, disabled: items.every(item => item.disabled), label: this.t('doNothing') }, ...items]
    },
    intervals() {
      const intervals = []

      for (const activity of this.activities) {
        if (!activity.startHour || !activity.endHour) continue

        const [startHour, startMinute] = activity.startHour.split(':')
        const start = new Date(this.date)
        start.setHours(startHour, startMinute, 0, 0)

        const [endHour, endMinute] = activity.endHour.split(':')
        const end = new Date(this.date)
        end.setHours(endHour, endMinute)

        const activityId = activity.activityUuid

        const activityInterval = { start, end, activityId }

        const interval = intervals.find(
          itv => isSameMinute(itv.start, activityInterval.start) && isSameMinute(itv.end, activityInterval.end)
        )
        if (!interval) {
          intervals.push(activityInterval)
        }
        /**
         * The commented block below allow to merge activities interval properly
         * For now, they are split and an interval is immuable (start and end must be exactly the same)
         */
        // const interval = intervals.find(itv => areIntervalsOverlapping(itv, activityInterval))
        // if (interval) {
        // if (interval.start > activityInterval.start) {
        //   interval.start = activityInterval.start
        // }
        // if (interval.end < activityInterval.end) {
        //   interval.end = activityInterval.end
        // }
        // } else {
        //   intervals.push(activityInterval)
        // }
      }
      return intervals
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.activities.date.${key}`, params)
    },
    intervalStartKey(interval) {
      const { start } = interval
      const hour = start.getHours()
      const minute = start.getMinutes()
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    },
    activitiesInInterval(interval) {
      return this.activities.filter(activity => {
        if (interval && (!activity.startHour || !activity.endHour)) return false

        const [startHour, startMinute] = activity.startHour.split(':')
        const start = new Date(this.date)
        start.setHours(startHour, startMinute, 0, 0)

        const [endHour, endMinute] = activity.endHour.split(':')
        const end = new Date(this.date)
        end.setHours(endHour, endMinute, 0, 0)

        return isSameMinute(interval.start, start) && isSameMinute(interval.end, end)
        /**
         * Use the commented block below to use merged activities intervals
         */
        // const activityInterval = { start, end }
        // return areIntervalsOverlapping(activityInterval, interval)
      })
    },
    activitiesInIntervalAsItems(interval) {
      const items = this.activitiesInInterval(interval)
        .sort((act1, act2) => act1.name.localeCompare(act2.name))
        .map(this.formatActivityToItem)

      return [{ value: null, label: this.t('doNothing'), disabled: items.every(item => item.disabled) }, ...items]
    },
    formatActivityToItem(activity) {
      const item = {
        value: activity.activityUuid,
        label: activity.name,
        disabled: false
      }

      const confirmedParticipantCounter = activity.activityParticipants.filter(
        ap => !ap.presence || ap.presence === this.$const.presence.present
      ).length

      if (activity.quota > 0 && confirmedParticipantCounter >= activity.quota) {
        item.disabled = true
        item.append = this.t('full')
        item.appendItalic = true
      }

      return item
    },
    intervalHasComments(interval) {
      return this.intervalHasRestauration(interval)
    },
    intervalHasRestauration(interval) {
      return this.activitiesInInterval(interval).some(activity => activity.activityCategory.isRestauration)
    }
  },
  filters: {
    intervalFormat(interval) {
      if (!interval) return null

      const { start, end } = interval

      return `${dateFormat(start, "HH'h'mm")} - ${dateFormat(end, "HH'h'mm")}`
    }
  },
  mounted() {
    const count = this.intervals.length + (this.noScheduleActivities.length ? 1 : 0)
    this.$emit('activity-settings', { date: this.date, count })
  }
}
</script>
