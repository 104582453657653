import gql from 'graphql-tag'

export const TRANSPORT_NOTE = gql`
  fragment TransportNote on TransportNote {
    agencyFee
    participantUuid
    comments {
      isHealthCareProfessionalMessage
      text
      date
    }
    travels {
      mode
      travelUuid
      type
      ticketNumber
      arrivalDate
      arrivalPlace
      arrivalTime
      departureDate
      departurePlace
      departureTime
    }
    files {
      fileUuid
      content
      name
    }
    origin
    ticketPrice
    goDeparturePlace
    goArrivalPlace
    goDepartureDate
    goDepartureTime
    returnDeparturePlace
    returnArrivalPlace
    returnDepartureDate
    returnDepartureTime
    preferenceModes
    lastUpdate
    noteReceivalDate
    status
    transportNoteUuid
    visualIdentifier
  }
`

const TRANSPORT_TRAVELS_FRAGMENT = gql`
  fragment travels on Travel {
    mode
    travelUuid
    type
    ticketNumber
    arrivalDate
    arrivalPlace
    arrivalTime
    departureDate
    departurePlace
    departureTime
  }
`

export const SEARCH_TRANSPORTS_FOR_GATHERING = gql`
  query transportNotes(
    $gatheringUuid: GUID!
    $searchText: String
    $status: TransportStatus
    $offset: Int = 0
    $limit: Int = -1
  ) {
    transportNotes(
      gatheringUuid: $gatheringUuid
      searchText: $searchText
      status: $status
      offset: $offset
      limit: $limit
    ) {
      participantUuid
      participant {
        participantUuid
        firstName
        lastName
      }
      travels {
        departurePlace
        departureTime
        mode
      }
      lastUpdate
      noteReceivalDate
      status
      transportNoteUuid
      visualIdentifier
    }
  }
`

export const GET_TRANSPORT_NOTE = gql`
  query transportNote($transportNoteUuid: GUID) {
    transportNote(transportNoteUuid: $transportNoteUuid) {
      ...TransportNote
      participant {
        firstName
        lastName
        category
        userUuid
      }
    }
  }
  ${TRANSPORT_NOTE}
`

export const GET_TRAVELS_TYPES = gql`
  query getTravelByType($transportNoteUuid: GUID!, $type: TravelType!) {
    getTravelByType(transportNoteUuid: $transportNoteUuid, type: $type) {
      mode
      travelUuid
      transportNoteUuid
      type
      ticketNumber
      arrivalDate
      arrivalPlace
      arrivalTime
      departureDate
      departurePlace
      departureTime
    }
  }
`

export const GET_FAVORITE_TRAVELS = gql`
  query getFavoriteTravels($gatheringUuid: GUID!, $type: TravelType!) {
    getFavoriteTravels(gatheringUuid: $gatheringUuid, type: $type) {
      ...travels
    }
  }
  ${TRANSPORT_TRAVELS_FRAGMENT}
`

export const UPDATE_TRANSPORT_NOTE = gql`
  mutation updateTransportNote($transportNoteEntry: InputTransportNoteUpdate!, $notify: Boolean) {
    updateTransportNote(transportNoteEntry: $transportNoteEntry, notify: $notify) {
      ...TransportNote
    }
  }
  ${TRANSPORT_NOTE}
`

export const SET_TRAVELS = gql`
  mutation setTravelsForTransportNote($transportNoteUuid: GUID!, $travels: [InputTravel!]!) {
    setTravelsForTransportNote(transportNoteUuid: $transportNoteUuid, travels: $travels) {
      transportNoteUuid
    }
  }
`

export const CANCEL_TRANSPORT_NOTE = gql`
  mutation refuseTransportNote($transportNoteUuid: GUID!) {
    refuseTransportNote(transportNoteUuid: $transportNoteUuid) {
      ...TransportNote
    }
  }
  ${TRANSPORT_NOTE}
`

export const ACCEPT_TRANSPORT_NOTE = gql`
  mutation acceptTransportNote($transportNoteUuid: GUID!) {
    acceptTransportNote(transportNoteUuid: $transportNoteUuid) {
      ...TransportNote
    }
  }
  ${TRANSPORT_NOTE}
`

export const SEND_TRANSPORT_NOTE_PROPOSITION = gql`
  mutation sendTransportNoteProposition($transportNoteUuid: GUID!) {
    sendTransportNoteProposition(transportNoteUuid: $transportNoteUuid) {
      ...TransportNote
    }
  }
  ${TRANSPORT_NOTE}
`

export const CREATE_TRANSPORT_NOTE = gql`
  mutation createTransportNote($gatheringUuid: GUID!, $participantUuid: GUID!, $transportNote: InputTransportNote!) {
    createTransportNote(
      gatheringUuid: $gatheringUuid
      participantUuid: $participantUuid
      transportNote: $transportNote
    ) {
      transportNoteUuid
    }
  }
`

export const VALIDATE_BIOGEN_STAFF_TRANSPORT_NOTE = gql`
  mutation validateBiogenStaffTransportNote($participantUuid: GUID!, $transportNoteUuid: GUID!) {
    validateBiogenStaffTransportNote(participantUuid: $participantUuid, transportNoteUuid: $transportNoteUuid) {
      ...TransportNote
    }
  }
  ${TRANSPORT_NOTE}
`
