<template>
  <div>
    <slot name="button" :click="$refs">
      <v-btn @click="$refs.inputFile.click()" color="success" depressed>
        <v-icon left>mdi-plus</v-icon>
        {{ $t`add-document` }}
      </v-btn>
    </slot>

    <slot name="input">
      <v-file-input
        v-if="document && !noDisplayInput"
        v-model="document"
        v-bind="$attrs"
        @change="updateFile"
        outlined
        dense
        data-e2e="file-input-base"
        prepend-icon=""
        clear-icon="mdi-close"
        class="mt-2"
      />
    </slot>

    <input ref="inputFile" type="file" accept=".pdf" class="d-none" @change="convertToFile" />
  </div>
</template>

<script>
import { toBase64 } from '@/services/file'

export default {
  name: 'InputFile',

  inheritAttrs: false,

  model: {
    prop: 'file',
    event: 'change'
  },

  props: {
    file: String,
    noDisplayInput: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      document: ''
    }
  },

  methods: {
    async convertToFile() {
      const file = this.$refs[`inputFile`].files[0]
      this.updateFile(file)
    },
    async updateFile(file) {
      if (!file) {
        this.$emit('change', '')
        return
      }

      const base64 = await toBase64(file)
      this.$emit('change', base64)
      this.document = file
      this.prop.file = file
    }
  }
}
</script>
