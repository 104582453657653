<!--
=====================================================
                HorizontalTimeLine
=====================================================

  props:
    title: optional string,
    items: array[{
      id: string,
      topLabel: optional string,
      bottomLabel: optional string,
      logo: optional string,
      completed: boolean
    }]
-->

<template>
  <div class="HorizontalTimeLine">
    <h3 class="headline">{{ title }}</h3>
    <div class="HorizontalTimeLine-line">
      <div class="HorizontalTimeLine-progress">
        <div class="HorizontalTimeLine-progressContent" :style="`width: ${percentStep}%;`"></div>
      </div>
      <div
        v-for="item in items"
        class="HorizontalTimeLine-step"
        :class="{ 'HorizontalTimeLine-step--active': item.completed }"
        :key="item.id"
      >
        <p v-if="item.topLabel" class="HorizontalTimeLine-stepDate">{{ item.topLabel }}</p>
        <v-icon class="ManagerLogistics-returnLink">{{ item.logo || 'mdi-check' }}</v-icon>
        <p v-if="item.bottomLabel" class="HorizontalTimeLine-stepLabel">{{ item.bottomLabel }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalTimeLine',
  props: {
    items: Array,
    title: String
  },
  computed: {
    percentStep() {
      return (this.items.filter(item => item.completed).length / (this.items.length - 1)) * 100
    }
  }
}
</script>

<style scoped lang="scss">
.HorizontalTimeLine {
  background: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 21px 48px 63px 48px;

  h3.headline {
    margin-bottom: 24px;
  }
}
.HorizontalTimeLine-line {
  display: flex;
  position: relative;
  justify-content: space-between;
  text-align: center;
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 36px;
  padding-bottom: 36px;
}
.HorizontalTimeLine-progress {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--v-congrex-disabled-base);
  transform: translateY(-50%);
  overflow: hidden;
}
.HorizontalTimeLine-progressContent {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--v-congrex-primary-base);
}
.HorizontalTimeLine-step {
  display: inline-block;
  position: relative;
  width: 33px;
  height: 33px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: var(--v-congrex-disabled-base);

  &--active {
    background: var(--v-congrex-primary-base);
  }

  .v-icon {
    color: white;
    font-size: 16px;
    margin-top: 3px;
  }
}
.HorizontalTimeLine-stepDate {
  position: absolute;
  top: -13px;
  left: 50%;
  width: 120px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: var(--v-primary-base);
  transform: translate(-50%, -100%);
}
.HorizontalTimeLine-stepLabel {
  position: absolute;
  bottom: -17px;
  left: 50%;
  width: 120px;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  transform: translate(-50%, 100%);
}
</style>
