import gql from 'graphql-tag'

import {
  WITH_ACTIVITY,
  WITH_HOSTING,
  WITH_TRANSPORT,
  WITH_USER,
  WITH_ALL_COMMENT,
  WITH_EXPENSE_NOTE,
  WITH_TRANSFER,
  WITH_FEES,
  WITH_EMAIL_HISTORIC,
  WITH_SOLICITATION,
  WITH_SOLICITATION_STATUS,
  BASE
} from './fragments'

export const PARTICIPANT_OVERNIGHTS = gql`
  query participantOverNights($participantUuid: GUID!) {
    participant(participantUuid: $participantUuid) {
      participantUuid
      overnightStays {
        date
        overnightStayUuid
        hostingNight {
          hosting {
            name
            roomType
          }
        }
        status
      }
    }
  }
`

export const LIGHT_PARTICIPANT = gql`
  fragment LightParticipant on Participant {
    participantUuid
    category
    createdAt
    presence
    invitationStatus
    participationType
    subscriptionDate
    subscriptionPrice
    participantComments {
      fileUuid
      participantCommentUuid
      createdAt
      comment
      reason
      file {
        name
        fileUuid
        content
      }
    }
    hostingComments {
      comment
      hostingCommentUuid
      participantUuid
    }
    expenseNotes {
      comments {
        actionStatus
        expenseNoteCommentUuid
        date
        text
        oldLineValues
        user {
          firstName
          lastName
        }
        file {
          name
          fileUuid
          content
        }
      }
    }
    firstName
    lastName
  }
`

export const PARTICIPANTS_FOR_GATHERING = gql`
  query participantsForGathering($gatheringUuid: GUID!) {
    participantsForGathering(gatheringUuid: $gatheringUuid) {
      ...ParticipantWithActivities
      ...ParticipantWithHostings
      ...ParticipantWithTransports
      ...ParticipantWithUser
      ...ParticipantWithAllComments
      ...ParticipantWithExpenseNotes
      ...ParticipantWithTransfer
    }
  }
  ${WITH_ACTIVITY}
  ${WITH_HOSTING}
  ${WITH_TRANSPORT}
  ${WITH_USER}
  ${WITH_ALL_COMMENT}
  ${WITH_EXPENSE_NOTE}
  ${WITH_TRANSFER}
`

export const PARTICIPANTS_FOR_GATHERING__USER = gql`
  query participantsForGathering($gatheringUuid: GUID!) {
    participantsForGathering(gatheringUuid: $gatheringUuid) {
      ...ParticipantWithUser
    }
  }
  ${WITH_USER}
`

export const PARTICIPANTS_FOR_GATHERING_CONGREX = gql`
  query participantsForGathering($gatheringUuid: GUID!) {
    participantsForGathering(gatheringUuid: $gatheringUuid) {
      ...ParticipantWithUser
      ...ParticipantWithTransports
      ...ParticipantWithHostings
      healthCareProfessional {
        solicitations {
          solicitationUuid
          congressUuid
          status
        }
        hcpGatheringDates(gatheringUuid: $gatheringUuid) {
          healthCareProfessionalUuid
          gatheringUuid
          gatheringDate
          signature
          signatureDate
        }
      }
    }
  }
  ${WITH_USER}
  ${WITH_TRANSPORT}
  ${WITH_HOSTING}
`

export const PARTICIPANTS_FOR_SIGNATURES = gql`
  query participantsForGathering($gatheringUuid: GUID!) {
    participantsForGathering(gatheringUuid: $gatheringUuid) {
      ...ParticipantWithUser
      healthCareProfessional {
        solicitations {
          solicitationUuid
          congressUuid
          status
        }
        hcpGatheringDates(gatheringUuid: $gatheringUuid) {
          healthCareProfessionalUuid
          gatheringUuid
          gatheringDate
          signature
          signatureDate
        }
      }
    }
  }
  ${WITH_USER}
`

export const PARTICIPANTS_FOR_GATHERING__PAGINATED = gql`
  query participantsForGatheringWithPagination(
    $gatheringUuid: GUID!
    $pagination: Pagination!
    $search: String
    $filters: ParticipantFilterInput
  ) {
    participantsForGatheringWithPagination(
      gatheringUuid: $gatheringUuid
      pagination: $pagination
      search: $search
      filters: $filters
    ) {
      hits {
        ...ParticipantWithActivities
        ...ParticipantWithHostings
        ...ParticipantWithTransports
        ...ParticipantWithUser
        ...ParticipantWithAllComments
        ...ParticipantWithExpenseNotes
        ...ParticipantWithTransfer
        ...ParticipantWithEmailHistorics
        ...ParticipantWithSolicitationStatus
      }
      total
      counter
      counterTotal
    }
  }
  ${WITH_ACTIVITY}
  ${WITH_HOSTING}
  ${WITH_TRANSPORT}
  ${WITH_USER}
  ${WITH_ALL_COMMENT}
  ${WITH_EXPENSE_NOTE}
  ${WITH_TRANSFER}
  ${WITH_EMAIL_HISTORIC}
  ${WITH_SOLICITATION_STATUS}
`

export const PARTICIPANT_FOR_GATHERING_AND_HCP = gql`
  query healthCareProfessionalParticipantForGathering($gatheringUuid: GUID!, $healthCareProfessionalUuid: GUID!) {
    healthCareProfessionalParticipantForGathering(
      gatheringUuid: $gatheringUuid
      healthCareProfessionalUuid: $healthCareProfessionalUuid
    ) {
      ...ParticipantWithActivities
      ...ParticipantWithHostings
      ...ParticipantWithTransports
      ...ParticipantWithUser
      ...ParticipantWithAllComments
      ...ParticipantWithExpenseNotes
      ...ParticipantWithTransfer
      ...ParticipantWithFees
      lvc
      healthCareProfessional {
        healthCareProfessionalFiles {
          healthCareProfessionalFileUuid
          name
          source
          gatheringUuid
          expirationDate
          addedDate
          updatedAt
          file {
            fileUuid
            name
            content
          }
        }
      }
      statusInformations {
        context
        date
        healthCareProfessionalUuid
        initiatorUuid
        initiator {
          userUuid
          firstName
          lastName
        }
        newStatus
        participantUuid
        previousStatus
        statusInformationUuid
      }
    }
  }
  ${WITH_ACTIVITY}
  ${WITH_HOSTING}
  ${WITH_TRANSPORT}
  ${WITH_USER}
  ${WITH_ALL_COMMENT}
  ${WITH_EXPENSE_NOTE}
  ${WITH_TRANSFER}
  ${WITH_FEES}
`

export const PARTICIPANTS_PICKER_SEARCH = gql`
  query searchParticipants($gatheringUuid: GUID!, $search: String, $tags: [String]) {
    searchParticipants(gatheringUuid: $gatheringUuid, search: $search, tags: $tags) {
      ...ParticipantWithUser
    }
  }
  ${WITH_USER}
`

export const PARTICIPANT_HAS_PENDING_TRANSPORT_NOTE = gql`
  query participantHasPendingTransportNote($gatheringUuid: GUID!, $participantUuid: GUID!) {
    participantHasPendingTransportNote(gatheringUuid: $gatheringUuid, participantUuid: $participantUuid)
  }
`

export const PARTICIPANT = gql`
  query participant($participantUuid: GUID!) {
    participant(participantUuid: $participantUuid) {
      ...ParticipantBase
    }
  }
  ${BASE}
`

export const PARTICIPANT_MANUAL = gql`
  query participant($participantUuid: GUID!) {
    participant(participantUuid: $participantUuid) {
      manualParticipant {
        lastName
        firstName
        email
        phoneNumber
        birthDate
      }
    }
  }
`

export const PARTICIPANT_STAFF = gql`
  query participant($participantUuid: GUID!) {
    participant(participantUuid: $participantUuid) {
      ...ParticipantBase
      ...ParticipantWithActivities
      ...ParticipantWithHostings
      ...ParticipantWithTransports
      ...ParticipantWithUser
      ...ParticipantWithAllComments
      ...ParticipantWithExpenseNotes
      ...ParticipantWithTransfer
      ...ParticipantWithFees
      user {
        phone
      }
    }
  }
  ${BASE}
  ${WITH_ACTIVITY}
  ${WITH_HOSTING}
  ${WITH_TRANSPORT}
  ${WITH_USER}
  ${WITH_ALL_COMMENT}
  ${WITH_EXPENSE_NOTE}
  ${WITH_TRANSFER}
  ${WITH_FEES}
`

export const PARTICIPANT_WITH_SOLICITATION = gql`
  query participant($participantUuid: GUID!) {
    participant(participantUuid: $participantUuid) {
      ...ParticipantWithSolicitation
    }
  }
  ${WITH_SOLICITATION}
`

export const PARTICIPANT_FROM_HCP_AND_GATHERING = gql`
  query healthCareProfessionalParticipantForGathering($healthCareProfessionalUuid: GUID!, $gatheringUuid: GUID!) {
    healthCareProfessionalParticipantForGathering(
      healthCareProfessionalUuid: $healthCareProfessionalUuid
      gatheringUuid: $gatheringUuid
    ) {
      ...ParticipantBase
    }
  }
  ${BASE}
`

export const REMOVE_PARTICIPANTS = gql`
  mutation removeParticipants($gatheringUuid: GUID!, $participantUuids: [GUID!]!) {
    removeParticipants(gatheringUuid: $gatheringUuid, participantUuids: $participantUuids)
  }
`

export const SET_PARTICIPANT_CATEGORY = gql`
  mutation setParticipantsCategory(
    $gatheringUuid: GUID!
    $participantUuids: [GUID!]!
    $category: String!
    $comment: String
  ) {
    setParticipantsCategory(
      gatheringUuid: $gatheringUuid
      participantUuids: $participantUuids
      category: $category
      comment: $comment
    ) {
      participantUuid
    }
  }
`

export const SET_PARTICIPANTS_SUBSCRIPTION = gql`
  mutation setParticipantsSubscription($participantUuids: [GUID!]!, $price: Int, $date: String!, $comment: String!) {
    setParticipantsSubscription(participantUuids: $participantUuids, price: $price, date: $date, comment: $comment) {
      participantUuid
    }
  }
`

export const SET_PRESENT = gql`
  mutation setParticipantsPresent($participantUuids: [GUID!]!) {
    setParticipantsPresent(participantUuids: $participantUuids) {
      participantUuid
    }
  }
`

export const SET_ABSENT = gql`
  mutation setParticipantsAbsent($participantUuids: [GUID!]!) {
    setParticipantsAbsent(participantUuids: $participantUuids) {
      participantUuid
    }
  }
`

export const SET_NO_SHOW = gql`
  mutation setParticipantsNoShow($participantUuids: [GUID!]!) {
    setParticipantsNoShow(participantUuids: $participantUuids) {
      participantUuid
    }
  }
`

export const INVITE_HEALTH_CARE_PROFESSIONALS = gql`
  mutation inviteHealthCareProfessionals($gatheringUuid: GUID!, $selected: [GUID!]!) {
    inviteHealthCareProfessionals(gatheringUuid: $gatheringUuid, selected: $selected) {
      participantUuid
    }
  }
`

export const INVITE_INTERNAL_USERS = gql`
  mutation inviteInternalUsers($gatheringUuid: GUID!, $userUuids: [GUID!]!) {
    inviteInternalUsers(gatheringUuid: $gatheringUuid, userUuids: $userUuids) {
      participantUuid
    }
  }
`

export const CREATE_PARTICIPANT = gql`
  mutation createParticipant(
    $gatheringUuid: GUID!
    $participantDetails: ManualParticipantInput!
    $participationType: ParticipationType
  ) {
    createParticipant(
      gatheringUuid: $gatheringUuid
      participantDetails: $participantDetails
      participationType: $participationType
    ) {
      participantUuid
    }
  }
`

export const CREATE_PARTICIPANT_COMMENT = gql`
  mutation createParticipantComment($participantUuid: GUID!, $comment: String!, $file: FileInput) {
    createParticipantComment(participantUuid: $participantUuid, comment: $comment, file: $file) {
      createdAt
      participantCommentUuid
      participantUuid
      comment
      reason
    }
  }
`

export const CHECK_PARTICIPANTS_EMAIL_MODEL_CRITERIAS = gql`
  query checkParticipantEmailModelCriteria($participantUuids: [GUID]!, $model: ParticipantEmailModel!) {
    checkParticipantEmailModelCriteria(participantUuids: $participantUuids, model: $model) {
      participantUuid
      lastName
      firstName
    }
  }
`
