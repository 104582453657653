import gql from 'graphql-tag'

export default gql`
  fragment ParticipantBase on Participant {
    category
    createdAt
    gatheringUuid
    healthCareProfessionalUuid
    userUuid
    invitationStatus
    learnedSocietyMemberId
    participantUuid
    presence
    presenceDate
    participationType
    subscriptionDate
    subscriptionPrice
    firstName
    lastName
  }
`
