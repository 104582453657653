module.exports = {
  category: 'activity/form/category',
  container: 'activity/form/container',
  date: 'activity/form/date',
  startHour: 'activity/form/startHour',
  endHour: 'activity/form/endHour',
  deletion: 'activity/form/deletion',
  deletionModal: {
    submit: 'activity/form/deletionModal/submit'
  },
  endDate: 'activity/form/endDate',
  entryType: 'activity/form/entryType',
  name: 'activity/form/name',
  nature: 'activity/form/nature',
  orator: 'activity/form/orator',
  oratorAdd: 'activity/form/oratorAdd',
  oratorItem: name => `activity/form/oratorItem/${name}`,
  organizer: 'activity/form/organizer',
  place: 'activity/form/place',
  price: 'activity/form/price',
  quota: 'activity/form/quota',
  room: 'activity/form/room',
  saveModal: {
    notification: {
      yes: 'activity/form/saveModal/notification/true',
      no: 'activity/form/saveModal/notification/false'
    },
    submit: 'activity/form/saveModal/submit'
  },
  startDate: 'activity/form/startDate',
  submit: 'activity/form/submit',
  target: 'activity/form/target',
  targetItem: category => `activity/form/target/${category}`,
  visio: 'activity/form/visio',
  website: 'activity/form/website'
}
