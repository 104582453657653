<template>
  <v-menu v-model="menu" offset-y max-width="100%" :disabled="disabled" :close-on-content-click="!multiple">
    <template v-slot:activator="{ on }">
      <mybb-text-field
        :value="date | date"
        v-on="on"
        :label="label || $t`dates`"
        readonly
        @click:append="on.click ? on.click : undefined"
        :disabled="disabled"
        :icon="icon"
        v-bind="$attrs"
        v-cy="cypress"
      >
      </mybb-text-field>
    </template>
    <v-date-picker v-model="date" locale="fr" no-title first-day-of-week="1" :min="min" :max="max" :range="multiple" />
  </v-menu>
</template>

<script>
import dateFormat from 'date-fns/format'
import dateValid from 'date-fns/isValid'

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: [Date, String, Array]
    },
    label: {
      type: String
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    icon: {
      type: String,
      default: 'mdi-calendar'
    },
    multiple: {
      type: Boolean
    },
    cypress: {
      type: String
    }
  },
  data() {
    return {
      menu: false,
      dates: []
    }
  },
  computed: {
    date: {
      get() {
        if (this.multiple) return this.dates

        return this.value instanceof Date ? this.value.toISOString() : this.value
      },
      set(value) {
        if (this.multiple) {
          this.dates = value
            .map(date => new Date(date).toISOString().slice(0, 10))
            .sort((a, b) => new Date(a) - new Date(b))

          if (this.dates.length >= 2) {
            this.$emit('input', this.dates)
            this.menu = false
          }

          return
        }

        this.$emit('input', value)
        this.menu = false
      }
    }
  },
  filters: {
    date(val) {
      if (!val) return null

      if (Array.isArray(val)) return val.map(date => dateFormat(new Date(date), 'dd/MM/yy')).join(' - ')

      return dateFormat(new Date(val), 'dd/MM/yy')
    }
  },
  mounted() {
    if (this.multiple && Array.isArray(this.value)) {
      this.dates = this.value
    }
  },
  watch: {
    value(value, old) {
      if (!this.multiple) return
      if (old && old.some(date => !dateValid(new Date(date)))) return

      this.dates = value
    }
  }
}
</script>
