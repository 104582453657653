<template>
  <div class="Select">
    <v-menu offset-y :close-on-content-click="false" open-delay="3000" max-height="400">
      <template v-slot:activator="{ on }">
        <div>
          <span :class="{ 'grey--text text--lighten-1': disable }">{{ text }}</span>
          <v-btn v-if="!disable" v-on="on" icon :ripple="false" class="ml-1">
            <v-avatar v-if="selected.length" color="primary" size="18">
              <span class="white--text font-weight-bold" style="font-size: 10px;">{{ selected.length }}</span>
            </v-avatar>
            <v-icon v-else small>mdi-filter-variant</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list class="list" subheader dense>
        <v-list-item v-for="(item, index) in items" :key="index" @click="change(item.value)">
          <v-list-item-content>
            <v-list-item-title class="pl-3">
              <v-checkbox
                @click.stop="change(item.value)"
                :input-value="value"
                :value="item.value"
                hide-details
                :color="item.color || 'primary'"
                class="my-2"
              >
                <template v-slot:label>
                  <span :style="{ color: item.color }">{{ item.text }}</span>
                </template>
              </v-checkbox>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'Select',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    text: String,
    items: Array,
    disable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: this.value
    }
  },

  methods: {
    change(value) {
      const indexItem = this.selected.findIndex(item => item === value)
      if (indexItem < 0) {
        this.selected.push(value)
      } else {
        this.selected.splice(indexItem, 1)
      }

      this.$emit('input', this.selected)
    }
  }
}
</script>

<style lang="scss">
.Select {
  white-space: nowrap;

  .list {
    .item {
      background: red;
    }
  }
}
</style>
