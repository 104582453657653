module.exports = {
  EventType: {
    CongressLong: 'CONGRESS_LONG',
    CongressShort: 'CONGRESS_SHORT',
    StandaloneLong: 'STANDALONE_LONG',
    StandaloneShort: 'STANDALONE_SHORT'
  },
  Module: {
    ExpenseNote: 'EXPENSE_NOTE',
    Hosting: 'HOSTING',
    Restauration: 'RESTAURATION',
    Transport: 'TRANSPORT'
  },
  NoCategoryId: 'NO_CATEGORY',
  Tag: {
    CountTowardQuota: 'COUNT_TOWARD_QUOTA',
    Orator: 'ORATOR',
    Staff: 'STAFF',
    Subscription: 'SUBSCRIPTION',
    TourLeader: 'TOUR_LEADER',
    Transparence: 'TRANSPARENCE'
  }
}
