import gql from 'graphql-tag'

export const FRAGMENT_ALL_SETTINGS_GATHERING_WITHOUT_FILE = gql`
  fragment gathering on Gathering {
    areHostingShared
    beginDate
    biogenEvent {
      biogenEventUuid
      availablePlace
      eventType
      invitationType
    }
    mainBusinessUnitUuid
    businessUnits {
      businessUnitUuid
      label
    }
    categoryVersion
    city
    congress {
      congressUuid
      additionalLinks
      criterias
      learnedSociety
      nationalityType
      websiteUrl
      zoneCongresses {
        zone {
          label
          businessUnitUuid
        }
        zoneUuid
        zoneQuota
      }
    }
    country
    description
    endDate
    eventSchedule
    gatheringBusinessUnits {
      businessUnit {
        label
      }
      businessUnitUuid
      doctorQuota
      hasAutomatedValidation
      hasZoneQuota
      mslResponsibleCriteria
      otherDisciplinesQuota
      solicitationEndDate
    }
    gatheringType
    gatheringUuid
    gatheringSensibleChanges {
      changes
      gatheringSensibleChangeUuid
      gatheringUuid
      initiatorUuid
      initiator {
        firstName
        lastName
      }
      status
      updatedAt
    }
    hostingConfiguration {
      eveArrival
      chooseEveArrivalAllowed
      chooseNightsAllowed
      comment
      gatheringUuid
      hostingConfigurationUuid
    }
    transportConfiguration {
      comment
      favouredDepartureDate
      favouredDepartureTime
      favouredReturnDate
      favouredReturnTime
      gatheringUuid
      transfertOptionEnabled
      transportConfigurationUuid
    }
    isPhysical
    isVirtual
    location
    participants {
      participantUuid
      userUuid
      invitationStatus
      category
    }
    name
    status
    timezone
    users {
      businessUnitUuid
      gatheringUserType
      isBackup
      userUuid
      user {
        firstName
        lastName
      }
    }
    visioUrl
  }
`

export const FRAGMENT_ALL_SETTINGS_GATHERING = gql`
  fragment gathering on Gathering {
    areHostingShared
    beginDate
    biogenEvent {
      biogenEventUuid
      availablePlace
      eventType
      invitationType
    }
    mainBusinessUnitUuid
    businessUnits {
      businessUnitUuid
      label
    }
    categoryVersion
    city
    congress {
      congressUuid
      additionalLinks
      criterias
      learnedSociety
      nationalityType
      symposiumProgramFile {
        content
        name
      }
      websiteUrl
      zoneCongresses {
        zone {
          label
          businessUnitUuid
        }
        zoneUuid
        zoneQuota
      }
    }
    country
    description
    endDate
    eventSchedule
    gatheringBusinessUnits {
      businessUnit {
        label
      }
      businessUnitUuid
      doctorQuota
      hasAutomatedValidation
      hasZoneQuota
      mslResponsibleCriteria
      otherDisciplinesQuota
      solicitationEndDate
    }
    gatheringType
    gatheringUuid
    gatheringSensibleChanges {
      changes
      gatheringSensibleChangeUuid
      gatheringUuid
      initiatorUuid
      initiator {
        firstName
        lastName
      }
      status
      updatedAt
    }
    hostingConfiguration {
      eveArrival
      chooseEveArrivalAllowed
      chooseNightsAllowed
      comment
      gatheringUuid
      hostingConfigurationUuid
    }
    transportConfiguration {
      comment
      favouredDepartureDate
      favouredDepartureTime
      favouredReturnDate
      favouredReturnTime
      gatheringUuid
      transfertOptionEnabled
      transportConfigurationUuid
    }
    isPhysical
    isVirtual
    location
    limitDate
    logoFile {
      content
      fileUuid
      name
    }
    lvcLocalFile {
      content
      name
    }
    participants {
      participantUuid
      userUuid
      invitationStatus
      category
    }
    programFile {
      content
      name
    }
    lvcGlobalFile {
      content
      name
    }
    lvcMilitaryFile {
      content
      name
    }
    name
    status
    timezone
    users {
      businessUnitUuid
      gatheringUserType
      isBackup
      userUuid
      user {
        firstName
        lastName
      }
    }
    otherFiles {
      fileGatheringUuid
      label
      file {
        content
        name
      }
    }
    gatheringFiles {
      type
      categories
      fileGatheringUuid
      gatheringUuid
      displayToHcp
      file {
        fileUuid
        name
        content
      }
      label
      isPhysical
      isVirtual
    }
    visioUrl
  }
`

export const GET_ALL_SETTINGS_GATHERING = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING_WITHOUT_FILE}
`

export const GET_ALL_SETTINGS_GATHERING_WITH_FILES = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const GET_ALL_SETTINGS_GATHERING_FOR_SUBSCRIPTION = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
      hostings {
        hostingUuid
      }
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING_WITHOUT_FILE}
`

export const GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
      gatheringValidations {
        section
        businessUnitUuid
        validator {
          firstName
          lastName
        }
        date
        status
      }
      historicalRecords {
        gatheringUuid
        data
        historicalRecordUuid
        user {
          firstName
          lastName
        }
        userUuid
        createdAt
      }
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION_NO_HISTORIC = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
      gatheringValidations {
        section
        businessUnitUuid
        validator {
          firstName
          lastName
        }
        date
        status
      }
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const GET_ALL_SETTINGS_GATHERING_WITH_HISTORIC = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      ...gathering
      historicalRecords {
        gatheringUuid
        data
        historicalRecordUuid
        user {
          firstName
          lastName
        }
        userUuid
        createdAt
      }
    }
  }
  ${FRAGMENT_ALL_SETTINGS_GATHERING}
`

export const GET_GATHERING_INFO = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      name
      isPhysical
      isVirtual
      status
    }
  }
`
export const GET_GATHERING_TYPE = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      isPhysical
      isVirtual
      status
      biogenEvent {
        biogenEventUuid
      }
      congress {
        congressUuid
      }
    }
  }
`

export const FRAGMENT_GATHERING_CONGRESS = gql`
  fragment Congress on Congress {
    criterias
    solicitationEndDate
    maximalQuota
    nationalityType
    hasAutomatedValidation
    hasBiogenStand
    hasBiogenSymposium
    hasBiogenWorkshop
    hasZoneQuota
    medicalDirectorUuid
    medicalDirector {
      firstName
      lastName
    }
    nationalityType
    otherQuota
    solicitations {
      solicitationUuid
      status
      updatedAt
      zone {
        label
      }
      healthCareProfessional {
        city
        firstName
        lastName
        targetBbu
        targetMs
        targetSma
      }
    }
    sponsorships
    stats {
      zones {
        filledZoneQuota
        zoneUuid
      }
      otherFilled
      totalFilled
    }
    websiteUrl
    zoneCongresses {
      zoneUuid
      zoneQuota
      zone {
        label
        businessUnit {
          label
        }
      }
      visioUrl
    }
  }
`

export const FRAGMENT_GATHERING_BIOGEN_EVENT = gql`
  fragment BiogenEvent on BiogenEvent {
    availablePlace
    invitationEndDate
    invitationType
    confirmedInvitations
    eventType
  }
`

export const GET_GATHERING_EVENT_DATE_AND_PLACE = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      name
      city
      beginDate
      endDate
      eveniumEventId
      gatheringType
      congress {
        congressUuid
        nationalityType
      }
      biogenEvent {
        biogenEventUuid
      }
    }
  }
`

export const GET_GATHERING_EVENT_GENERAL = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      status
      beginDate
      endDate
      city
      country
      eventSchedule
      location
      isVirtual
      description
      businessUnits {
        businessUnitUuid
        label
      }
      biogenEvent {
        ...BiogenEvent
      }
      congress {
        ...Congress
      }
      projectManagerUuid
      projectManager {
        firstName
        lastName
      }
      importedAttendees {
        firstName
        lastName
        status
      }
      inChargeMedical {
        firstName
        lastName
      }
      inChargeMci {
        firstName
        lastName
      }
      inChargeMarketing {
        firstName
        lastName
      }
      inChargeRoc {
        firstName
        lastName
      }
    }
  }
  ${FRAGMENT_GATHERING_BIOGEN_EVENT}
  ${FRAGMENT_GATHERING_CONGRESS}
`

export const GET_GATHERING_EVENT_GENERAL_FOR_EDIT = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      logo
      status
      beginDate
      endDate
      eveniumEventId
      oseusMeetingId
      eventSchedule
      city
      country
      isVirtual
      location
      description
      program
      lvcGlobal
      lvcLocal
      lvcMilitary
      businessUnits {
        businessUnitUuid
        label
      }
      historicalRecords {
        createdAt
        data
        user {
          firstName
          lastName
          userTypes
        }
      }
      biogenEvent {
        ...BiogenEvent
      }
      congress {
        ...Congress
      }
      projectManager {
        userUuid
        firstName
        lastName
      }
      inChargeMedical {
        userUuid
        firstName
        lastName
      }
      inChargeMci {
        userUuid
        firstName
        lastName
      }
      inChargeMarketing {
        userUuid
        firstName
        lastName
      }
      inChargeRoc {
        userUuid
        firstName
        lastName
      }
    }
  }
  ${FRAGMENT_GATHERING_BIOGEN_EVENT}
  ${FRAGMENT_GATHERING_CONGRESS}
`

export const GET_GATHERING_EVENT_GENERAL_FOR_EDIT_WITHOUT_HISTORICAL_RECORD = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      logo
      status
      beginDate
      endDate
      eveniumEventId
      eventSchedule
      city
      country
      oseusMeetingId
      location
      description
      program
      lvcGlobal
      lvcLocal
      lvcMilitary
      isVirtual
      businessUnits {
        businessUnitUuid
        label
      }
      biogenEvent {
        ...BiogenEvent
      }
      congress {
        ...Congress
      }
      projectManager {
        userUuid
        firstName
        lastName
      }
      inChargeMedical {
        userUuid
        firstName
        lastName
      }
      inChargeMci {
        userUuid
        firstName
        lastName
      }
      inChargeMarketing {
        userUuid
        firstName
        lastName
      }
      inChargeRoc {
        userUuid
        firstName
        lastName
      }
    }
  }
  ${FRAGMENT_GATHERING_BIOGEN_EVENT}
  ${FRAGMENT_GATHERING_CONGRESS}
`

export const GET_GATHERING_STATUS = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      categoryVersion
      status
      gatheringType
      isVirtual
      isPhysical
      beginDate
      endDate
    }
  }
`

export const GET_GATHERING_SOLICITATIONS = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      beginDate
      endDate
      congress {
        solicitations {
          healthCareProfessional {
            lastName
            firstName
            city
            targetBbu
            targetMs
            targetSma
          }
          status
          updatedAt
        }
      }
    }
  }
`

export const GET_GATHERING_CONGRESS_TO_CREATE_SOLICITATION = gql`
  query gathering($gatheringUuid: GUID, $token: String) {
    gathering(gatheringUuid: $gatheringUuid, token: $token) {
      name
      city
      location
      beginDate
      endDate
      status
      congress {
        congressUuid
        nationalityType
      }
    }
  }
`
export const GET_GATHERING_INVITATIONS = gql`
  query Gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      name
      biogenEvent {
        availablePlace
      }
      eveniumEventId
      status
      importedAttendees {
        firstName
        lastName
        status
        postalCode
        veevaId
        healthCareProfessional {
          zone {
            label
          }
          city
          targetAd
          targetBbu
          targetKme
          targetMs
          targetSma
        }
      }
      isVirtual
      oseusMeetingId
    }
  }
`
export const GET_GATHERING_EVENIUM_EVENT_ID = gql`
  query Gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      eveniumEventId
      isVirtual
    }
  }
`

export const GET_GATHERING_CONGRESS_SPONSORSHIPS = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      congress {
        congressUuid
        sponsorships
      }
    }
  }
`

export const GET_GATHERING_DATES = gql`
  query gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      beginDate
      endDate
    }
  }
`
