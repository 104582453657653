import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'

import CONST from '@/const/shared'
import router from '@/router'

Vue.use(Vuex)

const initialState = () => ({
  applications: [],
  bus: [],
  drawer: false,
  eventSelected: {},
  impersonificatedUser: null,
  modal: {
    dialogEventList: { display: false },
    dialogHcpList: { display: false },
    modalMessage: { display: false }
  },
  myUser: {},
  zones: []
})

export default new Vuex.Store({
  state: initialState(),
  getters: {
    hasRight: state => right => {
      return state.myUser.allRights.includes(CONST.right.superAdmin) || state.myUser.allRights.includes(right)
    },
    userBusinessUnitUuid(state) {
      const user = state.myUser

      if (!user) return null

      if (user.mslMeta) {
        let businessUnitUuid = _get(user, 'mslMeta.zone.businessUnit.businessUnitUuid')

        if (businessUnitUuid) {
          return businessUnitUuid
        }

        businessUnitUuid = _get(user, 'mslMeta.businessUnitUuid')

        if (businessUnitUuid) {
          return businessUnitUuid
        }
      }

      if (user.abmMeta) {
        const businessUnitUuid = _get(user, 'abmMeta.businessUnitUuid')

        if (businessUnitUuid) {
          return businessUnitUuid
        }
      }

      return null
    },
    businessUnits(state) {
      return state.bus
    }
  },
  mutations: {
    RESET_STORE(state) {
      const init = initialState()
      Object.keys(init).forEach(key => {
        state[key] = init[key]
      })
    },
    SET_BUS_AND_ZONES(state, payload) {
      state.bus = _sortBy(payload, ['label']).map(bu => ({ ...bu, zones: _sortBy(bu.zones, 'label') }))
      state.zones = _sortBy(payload.flatMap(bu => bu.zones), ['label'])
    },
    SET_DIALOG_EVENT_LIST(state, payload) {
      state.modal.dialogEventList = payload
    },
    SET_DIALOG_HCP_LIST(state, payload) {
      state.modal.dialogHcpList = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_EVENT_SELECTED(state, payload) {
      state.eventSelected = payload
    },
    SET_MODAL_MESSAGE(state, payload) {
      state.modal.modalMessage = payload
    },
    SET_MY_APPLICATIONS(state, payload) {
      state.applications = payload.map(app => {
        if (app.url.includes('://')) {
          app.href = app.url
        } else {
          app.to = app.url
        }
        return app
      })
    },
    SET_MY_USER(state, payload) {
      state.myUser = payload
    },
    SET_IMPERSONIFICATED_USER(state, userUuid) {
      state.impersonificatedUser = userUuid
    }
  },
  actions: {
    IMPERSONIFICATE_USER({ commit }, userUuid) {
      commit('SET_IMPERSONIFICATED_USER', userUuid)

      if (userUuid) {
        router.push({
          name: 'Home'
        })

        Vue.nextTick(() => {
          window.location.reload()
        })
      }
    }
  },
  plugins: [
    createPersistedState({
      filter: ({ type }) =>
        ![
          'SET_DIALOG_EVENT_LIST',
          'SET_DIALOG_HCP_LIST',
          'SET_MODAL_MESSAGE',
          'SET_DRAWER'
          // 'SET_IMPERSONIFICATED_USER'
        ].includes(type),
      key: `store-${STORE_VERSION}`
    })
  ]
})
