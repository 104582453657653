import gql from 'graphql-tag'

import BASE from './Base'

export default gql`
  fragment ParticipantWithSolicitationStatus on Participant {
    ...ParticipantBase
    solicitation {
      solicitationUuid
      status
    }
  }
  ${BASE}
`
