const upperFirst = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

const logContexts = {
  ACTIVITY: 'activity',
  ACTIVITY_PARTICIPANT: 'activityParticipant',
  APPLICATION: 'application',
  BIOGEN_EVENT: 'biogenEvent',
  COMMENT: context => `comment/${context}`,
  CONGRESS: 'congress',
  EXPENSE_NOTE: 'expenseNote',
  GATHERING: 'gathering',
  HCP_GATHERING_DATE: 'hcpGatheringDate',
  HEALTH_CARE_PROFESSIONAL: 'healthCareProfessional',
  HOSTING: 'hosting',
  PARTICIPANT: 'participant',
  SERF: 'scientifcExchangeRequestForm',
  SOLICITATION: 'solicitation',
  TRANSFER: 'transfer',
  TRANSPORT: 'transport',
  USER: 'user'
}

const gatheringContextRight = {
  cancelSolicitation: 'cancelSolicitation',
  confirmSolicitation: 'confirmSolicitation'
}

const gatheringStatus = {
  CLOSED: 'CLOSED',
  CORRECTION: 'CORRECTION',
  DRAFT: 'DRAFT',
  EX_NIHILO: 'EX_NIHILO',
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_FRONT: 'PUBLISHED_FRONT',
  TO_PUBLISH: 'TO_PUBLISH',
  TO_VALIDATE: 'TO_VALIDATE',
  WINDED_UP: 'WINDED_UP'
}

const gatheringType = {
  BIOGEN_EVENT: 'biogenEvent',
  CONGRESS: 'congress'
}

const gatheringUserType = {
  MARKETING: 'marketing',
  MCI: 'mci',
  MEDICAL: 'medical',
  MEDICAL_DIRECTOR: 'medicalDirector',
  PROJECT_MANAGER: 'projectManager',
  ROC: 'roc'
}

const gatheringValidationSection = {
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  GLOBAL: 'GLOBAL',
  SUPPORT_TEAM: 'SUPPORT_TEAM'
}

const right = {
  admin: 'admin',
  askExpenseNoteArbitration: 'askExpenseNoteArbitration',
  cancelSolicitation: 'cancelSolicitation',
  collectSolicitation: 'collectSolicitation',
  confirmExpenseNotePayment: 'confirmExpenseNotePayment',
  confirmSolicitation: 'confirmSolicitation',
  createActivity: 'createActivity',
  createBiogenEvent: 'createBiogenEvent',
  createCongress: 'createCongress',
  createHosting: 'createHosting',
  createHostingRequests: 'createHostingRequests',
  createInteralExpenseNote: 'createInteralExpenseNote',
  createOseusGuest: 'createOseusGuest',
  createParticipant: 'createParticipant',
  createSerf: 'createSerf',
  createSponsorshipProof: 'createSponsorshipProof',
  createUser: 'createUser',
  deleteActivity: 'deleteActivity',
  deleteSponsorshipProof: 'deleteSponsorshipProof',
  deleteTransfers: 'deleteTransfers',
  downloadSerf: 'downloadSerf',
  downloadSolicitation: 'downloadSolicitation',
  downloadSponsorshipProofs: 'downloadSponsorshipProofs',
  editMyPublishedBiogenEvent: 'editMyPublishedBiogenEvent',
  editMyPublishedCongress: 'editMyPublishedCongress',
  exportHcp: 'exportHcp',
  exportHcpSignature: 'exportHcpSignature',
  exportLvc: 'exportLvc',
  exportSolicitation: 'exportHcpSolicitation',
  exportSolicitationMci: 'exportSolicitationMci',
  externalConsumer: 'externalConsumer',
  getHcpToken: 'getHcpToken',
  getMyUser: 'getMyUser',
  impersonificate: 'impersonificate',
  importAttendees: 'importAttendees',
  importGatheringParticipants: 'importGatheringParticipants',
  importTransfer: 'importTransfer',
  inviteHealthCareProfessionals: 'inviteHealthCareProfessionals',
  inviteInternalUsers: 'inviteInternalUsers',
  manageDashboard: 'manageDashboard',
  manageHcpDocument: 'manageHcpDocument',
  manageTransportNote: 'manageTransportNote',
  publishGathering: 'publishGathering',
  putOnHoldSolicitation: 'putOnHoldSolicitation',
  readAbmMeta: 'readAbmMeta',
  readActivity: 'readActivity',
  readAllSponsorshipProofs: 'readAllSponsorshipProofs',
  readApplication: 'readApplication',
  readBiogenEvent: 'readBiogenEvent',
  readBusinessUnit: 'readBusinessUnit',
  readCongress: 'readCongress',
  readEveniumEvent: 'readEveniumEvent',
  readEveniumGuest: 'readEveniumGuest',
  readExpenseNote: 'readExpenseNote',
  readFeedbackStatus: 'readFeedbackStatus',
  readGathering: 'readGathering',
  readGatheringCorrectionRequests: 'readGatheringCorrectionRequests',
  readGatheringParticipation: 'readGatheringParticipation',
  readGeographicalUnit: 'readGeographicalUnit',
  readHcp: 'readHcp',
  readHcpGatheringDates: 'readHcpGatheringDates',
  readHistoricalRecord: 'readHistoricalRecord',
  readHosting: 'readHosting',
  readImportedAttendee: 'readImportedAttendee',
  readMslMeta: 'readMslMeta',
  readOseusEvent: 'readOseusEvent',
  readParticipant: 'readParticipant',
  readSerf: 'readSerf',
  readSerfHistoricalRecord: 'readSerfHistoricalRecord',
  readSolicitation: 'readSolicitation',
  readSponsorshipProof: 'readSponsorshipProof',
  readTransfer: 'readTransfer',
  readTransport: 'readTransport',
  readUser: 'readUser',
  readVeevaUser: 'readVeevaUser',
  readVeevaUserByToken: 'readVeevaUserByToken',
  readZone: 'readZone',
  readZoneCongress: 'readZoneCongress',
  refuseExpenseNote: 'refuseExpenseNote',
  refuseGathering: 'refuseGathering',
  refuseSerf: 'refuseSerf',
  refuseSolicitation: 'refuseSolicitation',
  removeParticipants: 'removeParticipants',
  renewExternal: 'renewExternal',
  renewSerf: 'renewSerf',
  reopenGathering: 'reopenGathering',
  repealSerf: 'repealSerf',
  saveOvernightStays: 'saveOvernightStays',
  searchAllCongresses: 'searchAllCongresses',
  searchArchivedGatherings: 'searchArchivedGatherings',
  searchClosedGathering: 'searchClosedGathering',
  searchCorrectionGatherings: 'searchCorrectionGatherings',
  searchDraftGatherings: 'searchDraftGatherings',
  searchPublishedFrontGatherings: 'searchPublishedFrontGatherings',
  searchPublishedGatherings: 'searchPublishedGatherings',
  searchTextAllGatherings: 'searchTextAllGatherings',
  searchToPublishGatherings: 'searchToPublishGatherings',
  searchToValidateGatherings: 'searchToValidateGatherings',
  searchWindedUpGathering: 'searchWindedUpGathering',
  setActivityParticipantAbsent: 'setActivityParticipantAbsent',
  setHostingConfiguration: 'setHostingConfiguration',
  setMailFreeTexts: 'setMailFreeTexts',
  setParticipantsAbsent: 'setParticipantsAbsent',
  setParticipantsCategory: 'setParticipantsCategory',
  setParticipantsNoShow: 'setParticipantsNoShow',
  setParticipantsPresent: 'setParticipantsPresent',
  setParticipantsSubscription: 'setParticipantsSubscription',
  setTransferConfiguration: 'setTransferConfiguration',
  shareHostings: 'shareHostings',
  signPresence: 'signPresence',
  subscribeInternalUser: 'subscribeInternalUser',
  subscribeParticipant: 'subscribeParticipant',
  superAdmin: 'superAdmin',
  toPublishAllBiogenEvents: 'toPublishAllBiogenEvents',
  toPublishAllCongresses: 'toPublishAllCongresses',
  toPublishMyBiogenEvent: 'toPublishMyBiogenEvent',
  toPublishMyCongress: 'toPublishMyCongress',
  toValidateGathering: 'toValidateGathering',
  unsubscribeActivityParticipant: 'unsubscribeActivityParticipant',
  updateActivity: 'updateActivity',
  updateBiogenEvent: 'updateBiogenEvent',
  updateCongress: 'updateCongress',
  updateExpenseNote: 'updateExpenseNote',
  updateExpenseNoteLine: 'updateExpenseNoteLine',
  updateHosting: 'updateHosting',
  updateInternalExpenseNoteLines: 'updateInternalExpenseNoteLines',
  updateSolicitation: 'updateSolicitation',
  updateTransportConfiguration: 'updateTransportConfiguration',
  updateUser: 'updateUser',
  validateExpenseNote: 'validateExpenseNote',
  viewBusinessUnitStats: 'viewBusinessUnitStats',
  viewEmptySerf: 'viewEmptySerf',
  viewSerfArchives: 'viewSerfArchives',
  viewZoneStats: 'viewZoneStats'
}

/**
 * @readonly
 * @enum {string}
 */
const businessUnitExternalId = {
  AD: 'AD',
  BBU: 'BBU',
  MS: 'SEP',
  SMA: 'SMA'
}

/**
 * @readonly
 * @enum {string}
 */
const serfType = {
  BBU: 'BBU',
  DEFAULT: 'DEFAULT'
}

/**
 * @readonly
 * @type {Object<businessUnitExternalId, serfType>}
 */
const businessUnitFormType = {
  [businessUnitExternalId.AD]: serfType.DEFAULT,
  [businessUnitExternalId.BBU]: serfType.BBU,
  [businessUnitExternalId.MS]: serfType.DEFAULT,
  [businessUnitExternalId.SMA]: serfType.DEFAULT
}

/**
 * @readonly
 * @enum {string}
 */
const fieldType = {
  BOOLEAN: 'Boolean',
  STRING: 'String'
}

/**
 * @type {Object<serfType, Object<string, {fieldType: fieldType, label: string}>>}
 */
const serfFields = {
  [serfType.BBU]: {
    autres: {
      fieldType: fieldType.STRING,
      label: 'serfSpecialties.BBU.autres'
    },
    biomarqueur: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.biomarqueur'
    },
    caracteristiquesBiosimilaire: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.caracteristiquesBiosimilaire'
    },
    effetNocebo: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.effetNocebo'
    },
    etudesReelles: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.etudesReelles'
    },
    inflammatoireIntestin: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.inflammatoireIntestin'
    },
    parcoursDeSoins: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.parcoursDeSoins'
    },
    pathologiesOphtalmiques: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.pathologiesOphtalmiques'
    },
    patientReportedOutcomes: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.patientReportedOutcomes'
    },
    pipelineEtResultatClinique: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.pipelineEtResultatClinique'
    },
    psoriasisEtHidrosadenite: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.psoriasisEtHidrosadenite'
    },
    rhumatisme: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.rhumatisme'
    },
    strategieEtGuidelines: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.strategieEtGuidelines'
    },
    therapeuticDrugMonitoringEtOptiomisation: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.therapeuticDrugMonitoringEtOptiomisation'
    },
    therapeutiquesBiogen: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.BBU.therapeutiquesBiogen'
    }
  },
  [serfType.DEFAULT]: {
    alzheimer: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.alzheimer'
    },
    amyotrophieSpinale: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.amyotrophieSpinale'
    },
    autres: {
      fieldType: fieldType.STRING,
      label: 'serfSpecialties.default.autres'
    },
    biomarqueur: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.biomarqueur'
    },
    cognition: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.cognition'
    },
    diagnostic: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.diagnostic'
    },
    etudesReellesFr: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.etudesReellesFr'
    },
    etudesReellesInter: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.etudesReellesInter'
    },
    imagerie: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.imagerie'
    },
    parcoursDeSoins: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.parcoursDeSoins'
    },
    pipelineEtResultatClinique: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.pipelineEtResultatClinique'
    },
    scleroseEnPlaques: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.scleroseEnPlaques'
    },
    strategieEtGuidelines: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.strategieEtGuidelines'
    },
    therapeutiquesBiogen: {
      fieldType: fieldType.BOOLEAN,
      label: 'serfSpecialties.default.therapeutiquesBiogen'
    }
  }
}

/**
 * @param {string} fieldName
 * @param {serfType} type
 * @returns {SerfFieldSpec}
 */
function getFieldSpecification(fieldName, type) {
  return {
    ...serfFields[type][fieldName],
    fieldFullName: fieldName + (type === serfType.DEFAULT ? '' : upperFirst(type.toLocaleLowerCase())),
    fieldName,
    serfType: type,
    suffix: type === serfType.DEFAULT ? '' : upperFirst(type.toLocaleLowerCase())
  }
}
/**
 * @param {string[]} fieldNameList
 * @param {serfType} type
 * @returns {SerfFieldSpec[]}
 */
function getFields(fieldNameList, type) {
  return fieldNameList.map(fieldName => getFieldSpecification(fieldName, type))
}

/**
 * @type {Object<serfType, Object<string, {fields: SerfFieldSpec[], label: string}>>}
 */
const serfGroups = {
  [serfType.BBU]: {
    bu: {
      fields: getFields(
        [
          'inflammatoireIntestin',
          'rhumatisme',
          'psoriasisEtHidrosadenite',
          'pathologiesOphtalmiques',
          'therapeutiquesBiogen',
          'strategieEtGuidelines',
          'etudesReelles',
          'pipelineEtResultatClinique',
          'caracteristiquesBiosimilaire'
        ],
        serfType.BBU
      ),
      label: 'serfSpecialties.groups.therapeuticArea'
    },
    crossCutting: {
      fields: [
        ...getFields(
          [
            'biomarqueur',
            'patientReportedOutcomes',
            'parcoursDeSoins',
            'therapeuticDrugMonitoringEtOptiomisation',
            'effetNocebo',
            'autres'
          ],
          serfType.BBU
        )
      ],
      label: 'serfSpecialties.groups.crossCutting'
    }
  },
  [serfType.DEFAULT]: {
    bu: {
      fields: getFields(
        [
          'scleroseEnPlaques',
          'amyotrophieSpinale',
          'alzheimer',
          'therapeutiquesBiogen',
          'strategieEtGuidelines',
          'etudesReellesFr',
          'etudesReellesInter',
          'pipelineEtResultatClinique'
        ],
        serfType.DEFAULT
      ),
      label: 'serfSpecialties.groups.therapeuticArea'
    },
    crossCutting: {
      fields: getFields(
        ['biomarqueur', 'cognition', 'diagnostic', 'imagerie', 'parcoursDeSoins', 'autres'],
        serfType.DEFAULT
      ),
      label: 'serfSpecialties.groups.crossCutting'
    }
  }
}

/**
 * @param {serfType} [serfType=null]
 * @returns {SerfFieldSpec[]}
 */
function getAllFields(serfType = null) {
  return [].concat(
    ...Object.keys(serfFields)
      .filter(type => serfType === null || serfType === type)
      .map(type => Object.keys(serfFields[type]).map(fieldName => getFieldSpecification(fieldName, type)))
  )
}

/**
 * @class CONST
 */
module.exports = {
  activity: {
    entryType: {
      /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
      open: 'OPEN',
      onInvitation: 'ON_INVITATION',
      onInvitationWithoutQuota: 'ON_INVITATION_WITHOUT_QUOTA'
      /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
    },
    nature: {
      /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
      physical: 'PHYSICAL',
      virtual: 'VIRTUAL',
      hybrid: 'HYBRID'
      /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
    },
    organizer: {
      biogen: 'BIOGEN',
      congress: 'CONGRESS'
    },
    participationType: {
      physical: 'PHYSICAL',
      virtual: 'VIRTUAL'
    }
  },
  authEmailModel: {
    EMAIL_ADDRESS_CHANGED: 'EMAIL_ADDRESS_CHANGED',
    PASSWORD_CHANGED: 'PASSWORD_CHANGED',
    PASSWORD_RECOVER: 'PASSWORD_RECOVER',
    REGISTRATION_CONFIRMATION: 'REGISTRATION_CONFIRMATION',
    REGISTRATION_VERIFICATION: 'REGISTRATION_VERIFICATION',
    RESEND_VERIFICATION: 'RESEND_VERIFICATION'
  },
  authStrategy: {
    ACTIVE_DIRECTORY: 'activeDirectory',
    JWT: 'jwt',
    LOCAL: 'local',
    NONE: 'none'
  },
  biogenEventInvitationType: {
    COMMERCIAL: 'commercial',
    MEDICAL: 'medical',
    MEDICAL_UP_LETTER: 'medicalUpLetter'
  },
  biogenEventType: {
    COMMERCIAL: 'commercial',
    MEDICAL: 'medical'
  },
  businessUnitExternalId,
  businessUnitFormType,
  businessUnitLabel: {
    AD: 'Alzheimer',
    BBU: 'Biosimilaires',
    MS: 'SEP',
    SMA: 'SMA'
  },
  businessUnitOrder: [
    {
      code: 'AD',
      label: 'Alzheimer',
      order: 0
    },
    {
      code: 'SEP',
      label: 'SEP',
      order: 1
    },
    {
      code: 'BBU',
      label: 'Biosimilaires',
      order: 2
    },
    {
      code: 'SMA',
      label: 'SMA',
      order: 3
    }
  ],
  criterias: {
    'AD-Global': 'AD-Global',
    'AD-Local': 'AD-Local',
    'AD-Regional': 'AD-Regional',
    BBU_A: 'A',
    BBU_B: 'B',
    BBU_C: 'C',
    BBU_D: 'D',
    BBU_NO: 'HC',
    BBU_OTHERS: 'OTHERS',
    'DERM-Local': 'DERM-Local',
    'DERM-Regional': 'DERM-Regional',
    'IBD-Global': 'IBD-Global',
    'IBD-Local': 'IBD-Local',
    'IBD-Regional': 'IBD-Regional',
    'MS-Global': 'MS-Global',
    'MS-Local': 'MS-Local',
    'MS-Regional': 'MS-Regional',
    MSL_TARGET: 'MSL',
    MS_H: 'H',
    MS_L: 'L',
    MS_M: 'M',
    MS_NO: 'HC',
    'RA-Global': 'RA-Global',
    'RA-Local': 'RA-Local',
    'RA-Regional': 'RA-Regional',
    'SMA-Global': 'SMA-Global',
    'SMA-Local': 'SMA-Local',
    SMA_A: 'A',
    SMA_B: 'B',
    SMA_C: 'C',
    SMA_D: 'D',
    SMA_NO: 'HC'
  },
  defaultCountry: 'France',
  defaultCountryCode: 'FR',

  defaultExportValues: {
    beneficiaryCategories: [
      'Académie fondation société savante organisme de conseils',
      'Association de professionnels de santé',
      "Association d'étudiants",
      "Association d'usagers du système de santé",
      'Editeur de logiciel',
      'Etablissement de santé',
      'Etudiant',
      'Groupement professionnel agricole',
      'Groupement sanitaire',
      'Influenceur',
      'Personne morale assurant la formation initiale ou continue des professionnels de santé',
      'Presse et média',
      'Professionnel de santé',
      'Vétérinaire',
      'Vétérinaire personne morale'
    ],
    countries: [
      'AFGHANISTAN',
      'AFRIQUE DU SUD',
      'ÅLAND ÎLES',
      'ALBANIE',
      'ALGÉRIE',
      'ALLEMAGNE',
      'ANDORRE',
      'ANGOLA',
      'ANGUILLA',
      'ANTARCTIQUE',
      'ANTIGUA-ET-BARBUDA',
      'ARABIE SAOUDITE',
      'ARGENTINE',
      'ARMÉNIE',
      'ARUBA',
      'AUSTRALIE',
      'AUTRICHE',
      'AZERBAÏDJAN',
      'BAHAMAS',
      'BAHREÏN',
      'BANGLADESH',
      'BARBADE',
      'BÉLARUS',
      'BELGIQUE',
      'BELIZE',
      'BÉNIN',
      'BERMUDES',
      'BHOUTAN',
      "BOLIVIE l'ÉTAT PLURINATIONAL DE",
      'BONAIRE SAINT-EUSTACHE ET SABA',
      'BOSNIE-HERZÉGOVINE',
      'BOTSWANA',
      'BOUVET ÎLE',
      'BRÉSIL',
      'BRUNEI DARUSSALAM',
      'BULGARIE',
      'BURKINA FASO',
      'BURUNDI',
      'CAÏMANS ÎLES',
      'CAMBODGE',
      'CAMEROUN',
      'CANADA',
      'CAP-VERT',
      'CENTRAFRICAINE RÉPUBLIQUE',
      'CHILI',
      'CHINE',
      'CHRISTMAS ÎLE',
      'CHYPRE',
      'COCOS (KEELING) ÎLES',
      'COLOMBIE',
      'COMORES',
      'CONGO',
      'CONGO LA RÉPUBLIQUE DÉMOCRATIQUE DU',
      'COOK ÎLES',
      'CORÉE RÉPUBLIQUE DE',
      'CORÉE RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE',
      'COSTA RICA',
      "CÔTE D'IVOIRE",
      'CROATIE',
      'CUBA',
      'CURAÇAO',
      'DANEMARK',
      'DJIBOUTI',
      'DOMINICAINE RÉPUBLIQUE',
      'DOMINIQUE',
      'ÉGYPTE',
      'EL SALVADOR',
      'ÉMIRATS ARABES UNIS',
      'ÉQUATEUR',
      'ÉRYTHRÉE',
      'ESPAGNE',
      'ESTONIE',
      'ÉTATS-UNIS',
      'ÉTHIOPIE',
      'FALKLAND ÎLES (MALVINAS)',
      'FÉROÉ ÎLES',
      'FIDJI',
      'FINLANDE',
      'FRANCE',
      'GABON',
      'GAMBIE',
      'GÉORGIE',
      'GÉORGIE DU SUD-ET-LES ÎLES SANDWICH DU SUD',
      'GHANA',
      'GIBRALTAR',
      'GRÈCE',
      'GRENADE',
      'GROENLAND',
      'GUADELOUPE',
      'GUAM',
      'GUATEMALA',
      'GUERNESEY',
      'GUINÉE',
      'GUINÉE-BISSAU',
      'GUINÉE ÉQUATORIALE',
      'GUYANA',
      'GUYANE FRANÇAISE',
      'HAÏTI',
      'HEARD-ET-ÎLES MACDONALD ÎLE',
      'HONDURAS',
      'HONG KONG',
      'HONGRIE',
      'ÎLE DE MAN',
      'ÎLES MINEURES ÉLOIGNÉES DES ÉTATS-UNIS',
      'ÎLES VIERGES BRITANNIQUES',
      'ÎLES VIERGES DES ÉTATS-UNIS',
      'INDE',
      'INDONÉSIE',
      "IRAN RÉPUBLIQUE ISLAMIQUE D'",
      'IRAQ',
      'IRLANDE',
      'ISLANDE',
      'ISRAËL',
      'ITALIE',
      'JAMAÏQUE',
      'JAPON',
      'JERSEY',
      'JORDANIE',
      'KAZAKHSTAN',
      'KENYA',
      'KIRGHIZISTAN',
      'KIRIBATI',
      'KOWEÏT',
      'LAO RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE',
      'LESOTHO',
      'LETTONIE',
      'LIBAN',
      'LIBÉRIA',
      'LIBYE',
      'LIECHTENSTEIN',
      'LITUANIE',
      'LUXEMBOURG',
      'MACAO',
      "MACÉDOINE L'EX-RÉPUBLIQUE YOUGOSLAVE DE",
      'MADAGASCAR',
      'MALAISIE',
      'MALAWI',
      'MALDIVES',
      'MALI',
      'MALTE',
      'MARIANNES DU NORD ÎLES',
      'MAROC',
      'MARSHALL ÎLES',
      'MARTINIQUE',
      'MAURICE',
      'MAURITANIE',
      'MAYOTTE',
      'MEXIQUE',
      'MICRONÉSIE ÉTATS FÉDÉRÉS DE',
      'MOLDOVA RÉPUBLIQUE DE',
      'MONACO',
      'MONGOLIE',
      'MONTÉNÉGRO',
      'MONTSERRAT',
      'MOZAMBIQUE',
      'MYANMAR',
      'NAMIBIE',
      'NAURU',
      'NÉPAL',
      'NICARAGUA',
      'NIGER',
      'NIGÉRIA',
      'NIUÉ',
      'NORFOLK ÎLE',
      'NORVÈGE',
      'NOUVELLE-CALÉDONIE',
      'NOUVELLE-ZÉLANDE',
      "OCÉAN INDIEN TERRITOIRE BRITANNIQUE DE L'",
      'OMAN',
      'OUGANDA',
      'OUZBÉKISTAN',
      'PAKISTAN',
      'PALAOS',
      'PALESTINE ÉTAT DE',
      'PANAMA',
      'PAPOUASIE-NOUVELLE-GUINÉE',
      'PARAGUAY',
      'PAYS-BAS',
      'PÉROU',
      'PHILIPPINES',
      'PITCAIRN',
      'POLOGNE',
      'POLYNÉSIE FRANÇAISE',
      'PORTO RICO',
      'PORTUGAL',
      'QATAR',
      'RÉUNION',
      'ROUMANIE',
      'ROYAUME-UNI',
      'RUSSIE FÉDÉRATION DE',
      'RWANDA',
      'SAHARA OCCIDENTAL',
      'SAINT-BARTHÉLEMY',
      'SAINTE-HÉLÈNE ASCENSION ET TRISTAN DA CUNHA',
      'SAINTE-LUCIE',
      'SAINT-KITTS-ET-NEVIS',
      'SAINT-MARIN',
      'SAINT-MARTIN(PARTIE FRANÇAISE)',
      'SAINT-MARTIN (PARTIE NÉERLANDAISE)',
      'SAINT-PIERRE-ET-MIQUELON',
      'SAINT-SIÈGE (ÉTAT DE LA CITÉ DU VATICAN)',
      'SAINT-VINCENT-ET-LES GRENADINES',
      'SALOMON ÎLES',
      'SAMOA',
      'SAMOA AMÉRICAINES',
      'SAO TOMÉ-ET-PRINCIPE',
      'SÉNÉGAL',
      'SERBIE',
      'SEYCHELLES',
      'SIERRA LEONE',
      'SINGAPOUR',
      'SLOVAQUIE',
      'SLOVÉNIE',
      'SOMALIE',
      'SOUDAN',
      'SOUDAN DU SUD',
      'SRI LANKA',
      'SUÈDE',
      'SUISSE',
      'SURINAME',
      'SVALBARD ET ÎLE JAN MAYEN',
      'SWAZILAND',
      'SYRIENNE RÉPUBLIQUE ARABE',
      'TADJIKISTAN',
      'TAÏWAN PROVINCE DE CHINE',
      'TANZANIE RÉPUBLIQUE-UNIE DE',
      'TCHAD',
      'TCHÈQUE RÉPUBLIQUE',
      'TERRES AUSTRALES FRANÇAISES',
      'THAÏLANDE',
      'TIMOR-LESTE',
      'TOGO',
      'TOKELAU',
      'TONGA',
      'TRINITÉ-ET-TOBAGO',
      'TUNISIE',
      'TURKMÉNISTAN',
      'TURKS-ET-CAÏCOS ÎLES',
      'TURQUIE',
      'TUVALU',
      'UKRAINE',
      'URUGUAY',
      'VANUATU',
      'VENEZUELA RÉPUBLIQUE BOLIVARIENNE DU',
      'VIET NAM',
      'WALLIS ET FUTUNA',
      'YÉMEN',
      'ZAMBIE',
      'ZIMBABWE'
    ],
    professionalIdTypes: ['AUTRE', 'FINESS', 'ORDRE', 'RPPS/ADELI', 'SIREN'],
    rawSpecialities: [
      'Aide soignant',
      'Ambulancier',
      'Assistant dentaire',
      'Audioprothésiste',
      'Auxiliaire de puériculture',
      'Chirurgien-dentiste',
      'Diététicien',
      'Ergothérapeute',
      'Infirmier',
      "Manipulateur d'électroradiologie médicale",
      'Masseur-kinésithérapeute',
      'Médecin',
      'Opticien-lunetier',
      'Orthophoniste',
      'Orthoptiste',
      'Pharmacien',
      'Physicien médical',
      "Prothésiste et orthésistes pour l'appareillage de personnes handicapées",
      'Préparateur en pharmacie et préparateur en pharmacie hospitalière',
      'Psychomotricien',
      'Pédicure-podologue',
      'Sage-femme',
      'Technicien de laboratoire médical',
      'Vétérinaire'
    ]
  },
  // When ticket TOOLYTMA-345 deployed on prod
  editableSignatureSerfPivotDate: new Date('2020-10-19 13:40:00'),
  environment: {
    DEV: 'dev',
    PREPROD: 'preprod',
    PRODUCTION: 'production',
    UAT: 'uat'
  },
  eveniumConfirmedStatuses: ['VALID', 'RESERVED', 'OVERBOOKED', 'EXTRA', 'CONFIRMED'],
  expenseNoteColors: {
    ARBITRATION: '#ffae44',
    CANCEL: '#b9b9b9',
    PAYED: '#33ca7f',
    REFUSED: '#b9b9b9',
    TO_BE_PROCESSED: '#51abfe',
    VALIDATED: '#624cab'
  },
  expenseNoteCommentStatus: {
    ARBITRATION: 'ARBITRATION',
    // Custom direct line creation
    DIRECT_ADD: 'DIRECT_ADD',
    DIRECT_DELETION: 'DIRECT_DELETION',
    DIRECT_UPDATE: 'DIRECT_UPDATE',
    PAYED: 'PAYED',
    REFUSED: 'REFUSED',
    UPDATED: 'UPDATED',
    VALIDATED: 'VALIDATED'
  },
  expenseNoteLineCategory: {
    FEEKM: 'fc3fa343-fd39-5d67-88f6-879299b13874'
  },
  expenseNoteLineStatus: {
    DRAFT: 'draft',
    EX_NIHILO: 'ex_nihilo',
    REFUSED: 'refused',
    TO_BE_PROCESSED: 'toBeProcessed',
    VALIDATED: 'validated',
    VALIDATED_BESIDE_ARBITRATION: 'validatedBesideArbitration'
  },
  expenseNoteStatus: {
    ARBITRATION: 'arbitration',
    CANCEL: 'cancel',
    DRAFT: 'draft',
    EX_NIHILO: 'ex_nihilo',
    PAYED: 'payed',
    REFUSED: 'refused',
    TO_BE_PROCESSED: 'toBeProcessed',
    VALIDATED: 'validated'
  },
  fieldType,
  gatheringConfigurationEntity: {
    Hosting: 'GATHERING_HOSTING_CONFIGURATION',
    Transport: 'GATHERING_TRANSPORT_CONFIGURATION'
  },
  gatheringContextRight,
  gatheringEventProvider: {
    EVENIUM: 'EVENIUM_EVENT',
    OSEUS: 'OSEUS_EVENT'
  },
  gatheringFileType: {
    EXPENSE_FEE_FORM: 'EXPENSE_FEE_FORM',
    LOGO: 'LOGO',
    LVC: 'LVC',
    OTHER: 'OTHER',
    PROGRAM: 'PROGRAM'
  },
  gatheringFilterType: {
    ALL: 'ALL',
    BIOGEN_EVENT: 'BIOGEN_EVENT',
    CONGRESS: 'CONGRESS'
  },
  gatheringFormat: {
    HYBRID: 'HYBRID',
    PHYSICAL: 'PHYSICAL',
    VIRTUAL: 'VIRTUAL'
  },
  gatheringPeriod: {
    ARCHIVED: 'ARCHIVED',
    ENDED: 'ENDED',
    NOW: 'NOW',
    TO_COME: 'TO_COME'
  },
  gatheringSensibleChangeStatus: {
    ACCEPTED: 'ACCEPTED',
    PENDING: 'PENDING',
    REFUSED: 'REFUSED'
  },
  gatheringStatus,
  gatheringType,
  gatheringUserType,
  gatheringUserTypeRights: {
    [gatheringUserType.MARKETING]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    },
    [gatheringUserType.MEDICAL]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    },
    [gatheringUserType.MEDICAL_DIRECTOR]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: [
          gatheringContextRight.cancelSolicitation,
          gatheringContextRight.confirmSolicitation
        ]
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    },
    [gatheringUserType.MCI]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    },
    [gatheringUserType.PROJECT_MANAGER]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    },
    [gatheringUserType.ROC]: {
      [gatheringType.CONGRESS]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      },
      [gatheringType.BIOGEN_EVENT]: {
        [gatheringStatus.EX_NIHILO]: [],
        [gatheringStatus.DRAFT]: [],
        [gatheringStatus.TO_VALIDATE]: [],
        [gatheringStatus.TO_PUBLISH]: [],
        [gatheringStatus.PUBLISHED]: []
      }
    }
  },
  gatheringValidationSection,
  gatheringValidationStatus: {
    REQUIRE_CORRECTION: 'REQUIRE_CORRECTION',
    VALIDATED: 'VALIDATED'
  },
  gender: {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    OTHER: 'OTHER'
  },
  guestPostStatus: {
    NO_SHOW: 'NO_SHOW',
    PRESENT: 'PRESENT',
    UNKNOWN: 'UNKNOWN'
  },
  guestStatus: {
    CANCELED: 'CANCELED',
    CONFIRMED: 'CONFIRMED',
    DECLINED: 'DECLINED',
    UNANSWERED: 'UNANSWERED'
  },
  healthCareProfessionalFileSource: {
    FRONT_APP: 'FRONT_APP',
    INTERNAL: 'INTERNAL'
  },
  historicalRecordOperation: {
    CONGRESS_INCREASE_ZONE_QUOTAS: 'CONGRESS_INCREASE_ZONE_QUOTAS',
    CONGRESS_UPDATE_AUTOMATED_VALIDATION: 'CONGRESS_UPDATE_AUTOMATED_VALIDATION',
    CONGRESS_UPDATE_CRITERIAS: 'CONGRESS_UPDATE_CRITERIAS',
    CONGRESS_UPDATE_QUOTAS: 'CONGRESS_UPDATE_QUOTAS',
    CONGRESS_UPDATE_SOLICITATION_END_DATE: 'CONGRESS_UPDATE_SOLICITATION_END_DATE',
    EVENT_UPDATE_QUOTAS: 'EVENT_UPDATE_QUOTAS',
    EVENT_VALIDATION: 'EVENT_VALIDATION',
    GATHERING_CATCH_UP: 'GATHERING_CATCH_UP',
    GATHERING_CLOSED: 'GATHERING_CLOSED',
    GATHERING_CREATION: 'GATHERING_CREATION',
    GATHERING_FORMAT: 'GATHERING_FORMAT',
    GATHERING_FRONT_PUBLICATION: 'GATHERING_FRONT_PUBLICATION',
    GATHERING_PUBLICATION: 'GATHERING_PUBLICATION',
    GATHERING_REFUSAL: 'GATHERING_REFUSAL',
    GATHERING_REOPEN: 'GATHERING_REOPEN',
    GATHERING_TO_FIX: 'GATHERING_TO_FIX',
    GATHERING_TO_PUBLISH: 'GATHERING_TO_PUBLISH',
    GATHERING_TO_VALIDATE: 'GATHERING_TO_VALIDATE',
    GATHERING_VALIDATION: 'GATHERING_VALIDATION',
    GATHERING_WINDED_UP: 'GATHERING_WINDED_UP',
    SERF_REPEAL: 'SERF_REPEAL',
    SOLICITATION_CANCELLED: 'SOLICITATION_CANCELLED',
    SOLICITATION_CREATION: 'SOLICITATION_CREATION',
    SOLICITATION_EXPIRED: 'SOLICITATION_EXPIRED',
    SOLICITATION_PUT_HOLD: 'SOLICITATION_PUT_HOLD',
    SOLICITATION_REFUSAL: 'SOLICITATION_REFUSAL',
    SOLICITATION_UPDATED: 'SOLICITATION_UPDATED',
    SOLICITATION_VALIDATION: 'SOLICITATION_VALIDATION',
    SPONSORSHIP_ADD: 'SPONSORSHIP_ADD'
  },
  honorifics: ['Mrs.', 'Dr.', 'Ms.', 'Prof.', 'Mr.', 'Miss', 'MMME', 'Mademoiselle', 'M', 'Monsieur', 'MRS'],
  hosting: {
    overnightStayStatus: {
      ACCEPTED: 'ACCEPTED',
      PENDING: 'PENDING',
      REFUSED: 'REFUSED'
    },
    statusContext: {
      ASSIGNED: 'ASSIGNED', // Only used when the request is coming from BiogenLinc
      WAITING_LIST: 'WAITING_LIST'
    }
  },
  importedAttendees: {
    profile: {
      GUEST: 'guest',
      PARTNER: 'partner'
    },
    status: {
      ABSENT: 'absent',
      CANCELLED: 'cancelled',
      CONFIRMED: 'confirmed',
      NO_SHOW: 'no_show',
      PRESENT: 'present',
      REGISTERED: 'registered',
      UNANSWERED: 'unanswered',
      WAITING_LIST: 'waiting_list'
    }
  },
  importedUserTypes: {
    doctor: 'Doctor'
  },
  invitationStatus: {
    absent: 'ABSENT',
    confirmed: 'CONFIRMED',
    pending: 'UNANSWERED',
    refused: 'REFUSED',
    unsubscribe: 'UNSUBSCRIBE'
  },
  logContexts,
  loginFailures: {
    AD_404: 'ad_404',
    AD_500: 'ad_500'
  },
  mailFreeText: {
    type: {
      BADGE: 'BADGE',
      CONTACTS: 'CONTACTS',
      EMAIL_CONTENT: 'CONTENT',
      EMAIL_OBJECT: 'OBJECT',
      PRESENCE_CERTIFICATE: 'PRESENCE_CERTIFICATE',
      RECAPITULATION_DIGITAL: 'RECAPITULATION_DIGITAL'
    }
  },
  monitoring: {
    status: {
      DONE: 'DONE',
      ERROR: 'ERROR',
      PENDING: 'PENDING'
    }
  },
  // TODO temporary
  mslAdEmail: 'mslad@bhs.ninja',
  nationalityType: {
    EUROPEAN: 'european',
    FRENCH: 'french',
    INTERNATIONAL: 'international'
  },
  noteOrigin: {
    EXTERNAL_FRONT: 'external_front',
    INTERNAL: 'internal'
  },
  oseusMapping: {
    // value must correspond to one of CONST.importedAttendees.profile values
    attendeeProfile: {
      Invité: 'guest',
      Partenaire: 'partner'
    },
    // value must correspond to one of CONST.importedAttendees.status values
    attendeeStatus: {
      '1. Inscrit': 'registered',
      '2. Présent': 'present',
      '3. Présent WC': 'present',
      '4. Abandon': 'cancelled',
      '5. Absent': 'absent',
      '6. No show': 'no_show'
    },
    exercice: {
      analysisLaboratory: "8. Laboratoire d'analyses",
      cabinet: '5. Cabinet',
      clinic: '4. Clinique',
      hospitalCenter: '1. CHU',
      mixed: '6. Mixte',
      peripheralCenter: '2. Centre périphérique',
      specializedCenter: '3. Centre spécialisé',
      unidentified: '7. Non identifié'
    },
    gender: {
      FEMALE: 'femme',
      MALE: 'homme',
      OTHER: 'aucun'
    },
    meetingStatus: {
      '1. Ouvert': 'open',
      '2. Réalisé': 'done',
      '3. Reporté': 'postponed',
      '4. Annulé': 'cancelled'
    },
    specialty: {
      ADDICTOLOGIE: 'Addictologie',
      ALCOOLOGIE: 'Alcoologie',
      ALLERGOLOGIE_OU_L_IMMUNOLOGIE: "allergologie ou l'immunologie",
      ANATOMO_PATHOLOGIE: 'Anatomo-pathologie',
      ANDROLOGIE: 'Andrologie',
      ANESTHESIOLOGIE: 'Anesthésiologie',
      BIOLOGIE: 'Biologie',
      CADRE_DE_SANTE: 'Cadre de santé',
      CARDIOLOGIE: 'Cardiologie',
      CHIRURGIE: 'Chirurgie',
      DERMATOLOGIE: 'Dermatologie',
      DIETETIQUE: 'Diététique',
      ENDOCRINOLOGIE: 'Endocrinologie',
      GASTRO_ENTEROLOGIE: 'Gastro-entérologie',
      GENETIQUE: 'Génétique',
      GERIATRIE: 'Gériatrie',
      GYNECOLOGIE: 'Gynécologie',
      HEMATOLOGIE: 'Hématologie',
      HEPATOLOGIE: 'Hépatologie',
      HYGIENISTE: 'Hygiéniste',
      IMMUNOLOGIE: 'Immunologie',
      INFIRMIER: 'Infirmier(e)',
      INTERNE: 'Interne',
      MALADIES_INFECTIEUSES: 'Maladies infectieuses',
      MALADIES_INFECTIEUSES_ET_TROPICALES: 'Maladies infectieuses et tropicales',
      MALADIES_TROPICALES: 'Maladies tropicales',
      MEDECINE_DU_TRAVAIL: 'Médecine du travail',
      MEDECINE_GENERALE: 'Médecine générale',
      MEDECINE_INTERNE: 'Médecine interne',
      MEDECINE_NUCLEAIRE: 'Médecine nucléaire',
      MEDECINE_PALLIATIVE: 'Médecine palliative',
      MEDECINE_PHYSIQUE: 'Médecine physique',
      MEDECINE_PREVENTIVE: 'Médecine préventive',
      MEDECINE_SCOLAIRE: 'Médecine scolaire',
      NEONATOLOGIE: 'Néonatologie',
      NEPHROLOGIE: 'Néphrologie',
      NEUROLOGIE: 'Neurologie',
      OBSTETRIQUE: 'Obstétrique',
      ODONTOLOGIE: 'Odontologie',
      ONCOLOGIE: 'Oncologie',
      OPHTALMOLOGIE: 'Ophtalmologie',
      ORTHOPEDIE: 'Orthopédie',
      OTO_RHINO_LARYNGOLOGIE: 'Oto-rhino-laryngologie',
      PARASITOLOGIE: 'Parasitologie',
      PEDIATRIE: 'Pédiatrie',
      PHARMACIE: 'Pharmacie',
      PNEUMOLOGIE: 'Pneumologie',
      PROCTOLOGIE: 'Proctologie',
      PSYCHIATRIE: 'Psychiatrie',
      RADIOLOGIE: 'Radiologie',
      RADIOTHERAPIE: 'Radiothérapie',
      REANIMATION: 'Réanimation',
      RECHERCHE: 'Recherche',
      RHUMATOLOGIE: 'Rhumatologie',
      SOINS_INTENSIFS: 'Soins intensifs',
      TABACOLOGIE: 'Tabacologie',
      URGENCES: 'Urgences',
      UROLOGIE: 'Urologie'
    }
  },
  participantCommentReason: {
    ACTIVITY: 'ACTIVITY',
    GLOBAL_COMMENT: 'GLOBAL_COMMENT',
    REQUIRE_HOSTING: 'REQUIRE_HOSTING',
    RESTAURATION: 'RESTAURATION',
    SET_CUSTOM_CATEGORY: 'SET_CUSTOM_CATEGORY',
    SET_HOSTING_OVERNIGHT_STAY: 'SET_HOSTING_OVERNIGHT_STAY',
    SET_SUBSCRIPTION: 'SET_SUBSCRIPTION'
  },
  participantEmailModel: {
    /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
    // Manual
    ASKING_CANCEL_PARTICIPATION: 'ASKING_CANCEL_PARTICIPATION',
    EMAIL_TEMPLATE: 'EMAIL_TEMPLATE',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SUBSCRIPTION_ORATOR: 'SUBSCRIPTION_ORATOR',
    TRANSPORT_PROPOSAL: 'TRANSPORT_PROPOSAL',
    RECAPITULATION: 'RECAPITULATION',
    RECAPITULATION_DIGITAL: 'RECAPITULATION_DIGITAL',
    RECAPITULATION_STAFF: 'RECAPITULATION_STAFF',
    BEGIN_SOON: 'BEGIN_SOON',
    EXPENSE_NOTE_REVIVAL_HCP: 'EXPENSE_NOTE_REVIVAL_HCP',
    PRESENCE_ATTESTATION_DONE: 'PRESENCE_ATTESTATION_DONE',
    // REGISTRATION_REMINDER: 'REGISTRATION_REMINDER',
    PRESENCE_ATTESTATION: 'PRESENCE_ATTESTATION',
    PAYED_EXPENSE_NOTE: 'PAYED_EXPENSE_NOTE',
    // Automatic
    ACTIVITY_HCP_CANCEL_STAFF: 'ACTIVITY_HCP_CANCEL_STAFF',
    EXPENSE_NOTE_TO_BE_PROCESSED_HCP: 'EXPENSE_NOTE_TO_BE_PROCESSED_HCP',
    EXPENSE_NOTE_TO_BE_PROCESSED_MCI: 'EXPENSE_NOTE_TO_BE_PROCESSED_MCI',
    EXPENSE_NOTE_PAYED_HCP: 'EXPENSE_NOTE_PAYED_HCP',
    EXPENSE_NOTE_ARBITRATION_ROC: 'EXPENSE_NOTE_ARBITRATION_ROC',
    EXPENSE_NOTE_ARBITRATION_DONE_MCI: 'EXPENSE_NOTE_ARBITRATION_DONE_MCI',
    HOSTING_HCP_CANCEL_STAFF: 'HOSTING_HCP_CANCEL_STAFF',
    HOSTING_HCP_CHANGED_STAFF: 'HOSTING_HCP_CHANGED_STAFF',
    INITIATE_EXPENSE_NOTE_HCP: 'INITIATE_EXPENSE_NOTE_HCP',
    INVITATION_STAFF: 'INVITATION_STAFF',
    HOSTING_RESET: 'HOSTING_RESET',
    EDIT_PERSONAL_INFORMATIONS_HCP: 'EDIT_PERSONAL_INFORMATIONS_HCP',
    REGISTRATION_ORATOR: 'REGISTRATION_ORATOR',
    REGISTRATION_REFUSED: 'REGISTRATION_REFUSED',
    REGISTRATION_WITH_LOGISTIC: 'REGISTRATION_WITH_LOGISTIC',
    REGISTRATION_WITHOUT_LOGISTIC: 'REGISTRATION_WITHOUT_LOGISTIC',
    TRANSPORT_REMINDER: 'TRANSPORT_REMINDER',
    TRANSPORT_RESET: 'TRANSPORT_RESET',
    TRANSPORT_HCP_CHANGED_STAFF: 'TRANSPORT_HCP_CHANGED_STAFF',
    TRANSPORT_HCP_CANCEL_STAFF: 'TRANSPORT_HCP_CANCEL_STAFF'
    /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
  },
  participantEmailType: {
    CUSTOM: 'CUSTOM',
    FROM_MODEL: 'FROM_MODEL',
    FROM_MODEL_TEST: 'FROM_MODEL_TEST'
  },
  participantExportType: {
    EXPENSE_NOTE: 'EXPENSE_NOTE',
    GENERAL: 'GENERAL',
    TRANSPARENCE_FULL: 'TRANSPARENCE_FULL',
    TRANSPARENCE_SYNTHETIC: 'TRANSPARENCE_SYNTHETIC'
  },
  participantSubscriptionContext: {
    SUBSCRIBED: 'SUBSCRIBED'
  },
  presence: {
    absent: 'ABSENT',
    noShow: 'NO_SHOW',
    present: 'PRESENT'
  },
  requestedSupport: {
    ACCOMODATION: 'hebergement_repas',
    CONGRESS: 'inscription_congres_formation',
    TRANSPORT: 'transport'
  },
  right,
  serfChoices: getAllFields().map(fieldSpec => fieldSpec.fieldFullName),
  serfFieldSpecifications: getAllFields(),
  serfGroups,
  serfStatus: {
    EXPIRED: 'expired',
    EXPIRES_SOON: 'expires_soon',
    NOT_SIGNED: 'not_signed',
    REFUSED: 'refused',
    REPEALED: 'repealed',
    SIGNED: 'signed'
  },
  serfType,
  solicitationStatus: {
    AD_HOC_VALIDATION: 'ad_hoc_validation',
    CANCELLED: 'cancelled',
    CONFIRMED: 'confirmed',
    CREATED: 'created',
    CRITERIA_VALIDATION: 'criteria_validation',
    QUOTA_VALIDATION: 'quota_validation',
    REFUSED: 'refused',
    SENT: 'sent',
    WAITING_LIST: 'waiting_list'
  },
  specialties: [
    '9',
    'A',
    'ADM',
    'AI',
    'AMD',
    'AN',
    'AS',
    'CCA',
    'CCE',
    'CCG',
    'CCN',
    'CD',
    'CG',
    'CHN',
    'CHP',
    'CMG',
    'CN',
    'D',
    'DEN',
    'DIA',
    'DR',
    'EM',
    'END',
    'FPG',
    'GE',
    'GER',
    'GP',
    'GS',
    'GYN',
    'HEM',
    'HEP',
    'HNS',
    'HO',
    'HOS',
    'HPHARM',
    'HPM',
    'HPR',
    'IBO',
    'ID',
    'ILI',
    'IM',
    'IMG',
    'MCB',
    'MG',
    'MGG',
    'MM',
    'MPM',
    'N',
    'NEP',
    'NM',
    'NMN',
    'NON',
    'NOS',
    'NPR',
    'NR',
    'NS',
    'NSP',
    'NUP',
    'OBG',
    'OM',
    'ON',
    'OPH',
    'ORS',
    'OSG',
    'OTO',
    'P',
    'PA',
    'PD',
    'PDS',
    'PG',
    'PH',
    'PHM',
    'PM',
    'PMD',
    'PMM',
    'PMP',
    'PMR',
    'PPM',
    'PPR',
    'PS',
    'PSN',
    'PSY',
    'PTH',
    'PUD',
    'PYG',
    'R',
    'RES',
    'RHU',
    'RNR',
    'RO',
    'TS',
    'U',
    'US',
    'VM',
    'VN',
    'VS'
  ],
  staffEmailModel: {
    ASKING_EDIT_INFORMATIONS_EVENT_STAFF: 'ASKING_EDIT_INFORMATIONS_EVENT_STAFF',
    CREATING_BIOGEN_EVENT_STAFF: 'CREATING_BIOGEN_EVENT_STAFF',
    EDIT_EVENT_TO_VALIDATE_STAFF: 'EDIT_EVENT_TO_VALIDATE_STAFF',
    SOLICITATION: 'SOLICITATION',
    VALIDATE_EVENT_STAFF: 'VALIDATE_EVENT_STAFF'
  },
  statusInformationContext: {
    EXPENSE_NOTE: 'EXPENSE_NOTE',
    HOSTING: 'HOSTING',
    PRESENCE: 'PRESENCE',
    SOLICITATION: 'SOLICITATION',
    SUBSCRIPTION: 'SUBSCRIPTION',
    TRANSPORT: 'TRANSPORT'
  },
  systemUser: {
    uuid: '4e6be464-4117-11ea-94cd-0242c0a85002'
  },
  targetBu: {
    Alzheimer: 'targetAd',
    Biosimilaires: 'targetBbu',
    SEP: 'targetMs',
    SMA: 'targetSma'
  },
  // new key to not create breaking change with the next key, often used
  targetBusinessUnitWithLabel: {
    /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
    Alzheimer: {
      'AD-Local': 'AD Local',
      'AD-Regional': 'AD Regional'
    },
    Biosimilaires: {
      BBU_A: 'A',
      BBU_B: 'B',
      BBU_C: 'C',
      BBU_D: 'D',
      BBU_OTHERS: 'Others',
      'DERM-Local': 'DERM Local',
      'DERM-Regional': 'DERM Regional',
      'IBD-Global': 'IBD Global',
      'IBD-Local': 'IDB Local',
      'IBD-Regional': 'IBD Regional',
      'RA-Global': 'RA Global',
      'RA-Local': 'RA Local',
      'RA-Regional': 'RA Regional'
    },
    SEP: {
      MS_H: 'H',
      MS_M: 'M',
      MS_L: 'L',
      'MS-Global': 'MS Global',
      'MS-Local': 'MS Local',
      'MS-Regional': 'MS Regional'
    },
    SMA: {
      SMA_A: 'A',
      SMA_B: 'B',
      SMA_C: 'C',
      SMA_D: 'D',
      'SMA-Global': 'SMA Global',
      'SMA-Local': 'SMA Local'
    }
    /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */
  },
  targetBusinnesUnit: {
    Alzheimer: ['AD-Global', 'AD-Local', 'AD-Regional'],
    Biosimilaires: [
      'BBU_A',
      'BBU_B',
      'BBU_C',
      'BBU_D',
      'BBU_OTHERS',
      'DERM-Local',
      'DERM-Regional',
      'IBD-Global',
      'IBD-Local',
      'IBD-Regional',
      'RA-Global',
      'RA-Local',
      'RA-Regional'
    ],
    SEP: ['MS_H', 'MS_L', 'MS_M', 'MS-Global', 'MS-Local', 'MS-Regional'],
    SMA: ['SMA_A', 'SMA_B', 'SMA_C', 'SMA_D', 'SMA-Global', 'SMA-Local']
  },
  targetKme: [
    'AD-Global',
    'AD-Local',
    'AD-Regional',
    'DERM-Local',
    'DERM-Regional',
    'IBD-Global',
    'IBD-Local',
    'IBD-Regional',
    'MS-Global',
    'MS-Local',
    'MS-Regional',
    'RA-Global',
    'RA-Local',
    'RA-Regional',
    'SMA-Global',
    'SMA-Local'
  ],
  targetKmeNo: 'HC',
  targetValue: {
    AD_NO: 'HC',

    BBU_A: 'A',
    BBU_B: 'B',
    BBU_C: 'C',
    BBU_D: 'D',
    BBU_NO: 'HC',
    BBU_PHAR: 'PHAR',

    BBU_RES_GA: 'RES_GA',
    BBU_RES_MI: 'RES_MI',
    BBU_RES_PHAR: 'RES_PHAR',
    BBU_RES_RH: 'RES_RH',

    MS_H: 'H',
    MS_L: 'L',
    MS_M: 'M',
    MS_NO: 'HC',

    SMA_A: 'A',
    SMA_B: 'B',
    SMA_C: 'C',
    SMA_D: 'D',
    SMA_NO: 'HC'
  },
  titles: [
    'Administrative care head',
    'Administrative Department Head',
    'Administrator',
    'Assistant Director',
    'Biological Pharmacist',
    'Biomedical Engineer',
    'Chief Nurse',
    'Childcare assistant',
    'Clinical Research Associate',
    'Delegated Director',
    'Dietician',
    'Director',
    'Distribution Pharmacist',
    'Dual Pharmacist',
    'Dual Practitioner',
    'Financial Officer',
    'Health Care Aide',
    'Hospital Pharmacist',
    'Hospital Practitioner',
    'Industrial Pharmacist',
    'Midwife',
    'Nurse Prescriber',
    'Nurse Specialist Practitioner',
    'Nurse',
    'Occupational therapist',
    'Orthoptist',
    'Pharmacist',
    'Pharmacy Technician',
    'Physiotherapist',
    'Podiatrist',
    'Politician',
    'Private Practitioner',
    'Psychologist',
    'Psychometrist',
    'Purchasing Officer',
    'Radiographer',
    'Scientist',
    'Social Worker',
    'Sophrologist',
    'Specialized Educator',
    'Speech and Language Therapist',
    'Technician',
    'Technician-in-charge',
    'Therapist'
  ],
  transport: {
    colors: {
      ACCEPTED: '#33CA7F',
      PENDING: '#51ABFE',
      REFUSED: '#B9B9B9',
      TO_BE_PROCESSED: '#51ABFE',
      TO_EMIT: '#FFAE44',
      WAITING_FOR_FEEDBACK: '#624CAB'
    },
    modeIcons: {
      PLANE: 'airplane',
      TRAIN: 'train'
    },
    modes: {
      PLANE: 'PLANE',
      TRAIN: 'TRAIN'
    },
    noteStatus: {
      ACCEPTED: 'ACCEPTED',
      EX_NIHILO: 'EX_NIHILO',
      PENDING: 'PENDING',
      REFUSED: 'REFUSED',
      TO_EMIT: 'TO_EMIT',
      WAITING_FOR_FEEDBACK: 'WAITING_FOR_FEEDBACK'
    },
    travelType: {
      BACK: 'BACK',
      GO: 'GO'
    }
  },
  types: [
    'Biogen Custom HCP Type-Doctor (ESE)',
    'Biogen Custom HCP Type-Prescriber (ESE)',
    'Business Professional',
    'Dentist',
    'Doctor',
    'Non-Prescribing Health Care Professional',
    'Nurse',
    'Pharmacist',
    'Prescriber',
    'Resident',
    'Student',
    'Technician'
  ],
  userProfile: {
    AGENCE: 'Agence',
    BIOGEN: 'Biogen'
  },
  userType: {
    ABM: 'abm',
    ABM_MANAGER: 'abmManager',
    ADMIN: 'admin', // Administration
    CP: 'cp', // Chef Projet
    DM: 'dm', // Medical director
    EXTERNAL_CONSUMER: 'externalConsumer', // External API Consumer
    MCI: 'mci', // MCI
    MEGA_ADMIN: 'megaAdmin', // Mega admin
    MSL: 'msl', // MSL
    MSL_MANAGER: 'mslManager',
    OTHERS: 'others',
    RM: 'rm', // in charge of Marketing
    RMCL: 'rmcl', // in charge of Medical
    ROC: 'roc', // ROC
    SUPER_ADMIN: 'superAdmin', // Super admin
    SUPER_DM: 'superDm', // Super Medical director
    SYS: 'system'
  },
  userTypeRights: {
    abm: [
      right.collectSolicitation,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.signPresence,
      right.subscribeInternalUser
    ],
    abmManager: [
      right.collectSolicitation,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.signPresence,
      right.subscribeInternalUser
    ],
    admin: [
      right.admin,
      right.askExpenseNoteArbitration,
      right.createActivity,
      right.cancelSolicitation,
      right.collectSolicitation,
      right.confirmExpenseNotePayment,
      right.confirmSolicitation,
      right.createBiogenEvent,
      right.createCongress,
      right.createHosting,
      right.createHostingRequests,
      right.createInteralExpenseNote,
      right.createExternalExpenseNote,
      right.createParticipant,
      right.createSponsorshipProof,
      right.createUser,
      right.deleteActivity,
      right.deleteSponsorshipProof,
      right.deleteTransfers,
      right.downloadSerf,
      right.downloadSolicitation,
      right.downloadSponsorshipProofs,
      right.editMyPublishedBiogenEvent,
      right.editMyPublishedCongress,
      right.exportHcp,
      right.exportHcpSignature,
      right.exportLvc,
      right.exportSolicitation,
      right.externalConsumer,
      right.getMyUser,
      right.importAttendees,
      right.importTransfer,
      right.inviteInternalUsers,
      right.inviteHealthCareProfessionals,
      right.manageDashboard,
      right.manageHcpDocument,
      right.manageTransportNote,
      right.publishGathering,
      right.putOnHoldSolicitation,
      right.readAbmMeta,
      right.readActivity,
      right.readAllSponsorshipProofs,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readExpenseNote,
      right.readFeedbackStatus,
      right.readGathering,
      right.readGatheringCorrectionRequests,
      right.readGatheringParticipation,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHistoricalRecord,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSerf,
      right.readSerfHistoricalRecord,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransfer,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseExpenseNote,
      right.refuseGathering,
      right.reopenGathering,
      right.saveOvernightStays,
      right.searchAllCongresses,
      right.searchArchivedGatherings,
      right.searchDraftGatherings,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchWindedUpGathering,
      right.searchClosedGathering,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchCorrectionGatherings,
      right.searchToValidateGatherings,
      right.removeParticipants,
      right.setActivityParticipantAbsent,
      right.setHostingConfiguration,
      right.setMailFreeTexts,
      right.setParticipantsAbsent,
      right.setParticipantsCategory,
      right.setParticipantsNoShow,
      right.setParticipantsPresent,
      right.setParticipantsSubscription,
      right.setTransferConfiguration,
      right.shareHostings,
      right.subscribeInternalUser,
      right.subscribeParticipant,
      right.signPresence,
      right.toPublishAllBiogenEvents,
      right.toPublishAllCongresses,
      right.toValidateGathering,
      right.unsubscribeActivityParticipant,
      right.updateActivity,
      right.updateExpenseNote,
      right.updateExpenseNoteLine,
      right.updateInternalExpenseNoteLines,
      right.updateHosting,
      right.updateCongress,
      right.updateTransportConfiguration,
      right.updateUser,
      right.validateExpenseNote,
      right.updateSolicitation,
      right.viewBusinessUnitStats,
      right.viewEmptySerf,
      right.viewSerfArchives,
      right.viewZoneStats
    ],
    anonymous: [right.getMyUser, right.readVeevaUserByToken],
    cp: [
      right.createSponsorshipProof,
      right.editMyPublishedBiogenEvent,
      right.getMyUser,
      right.importAttendees,
      right.publishGathering,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHistoricalRecord,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseGathering,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchToValidateGatherings,
      right.searchCorrectionGatherings,
      right.signPresence,
      right.subscribeInternalUser,
      right.toPublishMyBiogenEvent,
      right.updateBiogenEvent
    ],
    dm: [
      right.cancelSolicitation,
      right.collectSolicitation,
      right.confirmSolicitation,
      right.createSponsorshipProof,
      right.downloadSolicitation,
      right.editMyPublishedCongress,
      right.exportHcp,
      right.exportHcpSignature,
      right.exportSolicitation,
      right.getMyUser,
      right.importAttendees,
      right.putOnHoldSolicitation,
      right.readAbmMeta,
      right.readActivity,
      right.readAllSponsorshipProofs,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGatheringParticipation,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHistoricalRecord,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseGathering,
      right.refuseSolicitation,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchToValidateGatherings,
      right.searchCorrectionGatherings,
      right.signPresence,
      right.subscribeInternalUser,
      right.toPublishMyCongress,
      right.updateBiogenEvent,
      right.updateCongress,
      right.viewBusinessUnitStats,
      right.viewZoneStats
    ],
    externalConsumer: Object.values(right),
    mci: [
      right.askExpenseNoteArbitration,
      right.confirmExpenseNotePayment,
      right.createActivity,
      right.createHosting,
      right.createHostingRequests,
      right.createInteralExpenseNote,
      right.deleteActivity,
      right.deleteTransfers,
      right.downloadSolicitation,
      right.exportLvc,
      right.exportSolicitationMci,
      right.getMyUser,
      right.importTransfer,
      right.manageHcpDocument,
      right.manageTransportNote,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readExpenseNote,
      right.readFeedbackStatus,
      right.readGathering,
      right.readGatheringParticipation,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHistoricalRecord,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransfer,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseExpenseNote,
      right.saveOvernightStays,
      right.searchAllCongresses,
      right.searchDraftGatherings,
      right.searchWindedUpGathering,
      right.searchClosedGathering,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchToValidateGatherings,
      right.searchCorrectionGatherings,
      right.removeParticipants,
      right.setActivityParticipantAbsent,
      right.setHostingConfiguration,
      right.setMailFreeTexts,
      right.setParticipantsAbsent,
      right.setParticipantsCategory,
      right.setParticipantsNoShow,
      right.setParticipantsPresent,
      right.setParticipantsSubscription,
      right.setTransferConfiguration,
      right.shareHostings,
      right.subscribeInternalUser,
      right.subscribeParticipant,
      right.signPresence,
      right.unsubscribeActivityParticipant,
      right.updateActivity,
      right.updateExpenseNoteLine,
      right.updateInternalExpenseNoteLines,
      right.updateExpenseNote,
      right.updateHosting,
      right.validateExpenseNote
    ],
    megaAdmin: [
      right.getMyUser,
      right.impersonificate,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readFeedbackStatus,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readSolicitation,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.readSponsorshipProof,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.removeParticipants,
      right.setMailFreeTexts,
      right.subscribeInternalUser,
      right.updateExpenseNoteLine,
      right.updateInternalExpenseNoteLines
    ],
    msl: [
      right.collectSolicitation,
      right.exportHcp,
      right.getHcpToken,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.signPresence,
      right.subscribeInternalUser,
      right.viewBusinessUnitStats,
      right.viewZoneStats
    ],
    mslManager: [
      right.collectSolicitation,
      right.exportHcp,
      right.getHcpToken,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.signPresence,
      right.subscribeInternalUser,
      right.viewBusinessUnitStats,
      right.viewZoneStats
    ],
    others: [
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.readSponsorshipProof,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings
    ],
    rm: [
      right.createSponsorshipProof,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.readSponsorshipProof,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.signPresence,
      right.subscribeInternalUser
    ],
    rmcl: [
      right.createSponsorshipProof,
      right.downloadSolicitation,
      right.getMyUser,
      right.readAbmMeta,
      right.readActivity,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readGathering,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.signPresence,
      right.subscribeInternalUser
    ],
    roc: [
      right.cancelSolicitation,
      right.confirmExpenseNotePayment,
      right.createActivity,
      right.createHosting,
      right.createHostingRequests,
      right.deleteTransfers,
      right.downloadSolicitation,
      right.editMyPublishedBiogenEvent,
      right.editMyPublishedCongress,
      right.createBiogenEvent,
      right.createCongress,
      right.createInteralExpenseNote,
      right.createParticipant,
      right.createSponsorshipProof,
      right.deleteActivity,
      right.deleteSponsorshipProof,
      right.downloadSponsorshipProofs,
      right.exportHcpSignature,
      right.exportLvc,
      right.exportSolicitation,
      right.getMyUser,
      right.importAttendees,
      right.importGatheringParticipants,
      right.importTransfer,
      right.inviteInternalUsers,
      right.inviteHealthCareProfessionals,
      right.manageHcpDocument,
      right.manageTransportNote,
      right.publishGathering,
      right.readAbmMeta,
      right.readActivity,
      right.readAllSponsorshipProofs,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readExpenseNote,
      right.readFeedbackStatus,
      right.readGathering,
      right.readGatheringCorrectionRequests,
      right.readGatheringParticipation,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHistoricalRecord,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransfer,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseExpenseNote,
      right.removeParticipants,
      right.reopenGathering,
      right.saveOvernightStays,
      right.searchAllCongresses,
      right.searchDraftGatherings,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchWindedUpGathering,
      right.searchClosedGathering,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchToValidateGatherings,
      right.searchCorrectionGatherings,
      right.setActivityParticipantAbsent,
      right.setHostingConfiguration,
      right.setMailFreeTexts,
      right.setParticipantsAbsent,
      right.setParticipantsCategory,
      right.setParticipantsNoShow,
      right.setParticipantsPresent,
      right.setParticipantsSubscription,
      right.setTransferConfiguration,
      right.shareHostings,
      right.signPresence,
      right.subscribeInternalUser,
      right.subscribeParticipant,
      right.toValidateGathering,
      right.unsubscribeActivityParticipant,
      right.updateActivity,
      right.updateBiogenEvent,
      right.updateCongress,
      right.updateExpenseNoteLine,
      right.updateInternalExpenseNoteLines,
      right.updateTransportConfiguration,
      right.updateExpenseNote,
      right.updateHosting,
      right.validateExpenseNote
    ],
    superAdmin: Object.values(right),
    superDm: [
      right.cancelSolicitation,
      right.collectSolicitation,
      right.confirmSolicitation,
      right.createSponsorshipProof,
      right.downloadSolicitation,
      right.editMyPublishedCongress,
      right.exportHcp,
      right.exportHcpSignature,
      right.exportSolicitation,
      right.getMyUser,
      right.importAttendees,
      right.importGatheringParticipants,
      right.putOnHoldSolicitation,
      right.readAbmMeta,
      right.readActivity,
      right.readAllSponsorshipProofs,
      right.readApplication,
      right.readBiogenEvent,
      right.readBusinessUnit,
      right.readCongress,
      right.readEveniumEvent,
      right.readEveniumGuest,
      right.readFeedbackStatus,
      right.readGathering,
      right.readGatheringParticipation,
      right.readGeographicalUnit,
      right.readHcp,
      right.readHcpGatheringDates,
      right.readHistoricalRecord,
      right.readHosting,
      right.readImportedAttendee,
      right.readMslMeta,
      right.readOseusEvent,
      right.readParticipant,
      right.readSolicitation,
      right.readSponsorshipProof,
      right.readTransport,
      right.readUser,
      right.readVeevaUser,
      right.readZone,
      right.readZoneCongress,
      right.refuseGathering,
      right.refuseSolicitation,
      right.searchAllCongresses,
      right.searchPublishedFrontGatherings,
      right.searchPublishedGatherings,
      right.searchTextAllGatherings,
      right.searchToPublishGatherings,
      right.searchToValidateGatherings,
      right.searchCorrectionGatherings,
      right.signPresence,
      right.subscribeInternalUser,
      right.toPublishMyCongress,
      right.updateBiogenEvent,
      right.updateCongress,
      right.viewBusinessUnitStats,
      right.viewZoneStats,
      right.updateSolicitation
    ],
    sys: []
  },

  virtualSession: {
    eventType: 'virtualSession',
    oseusStatusMapping: {
      absent: 'ABSENT',
      cancelled: 'ABSENT',
      no_show: 'ABSENT',
      present: 'PRESENT'
    },
    participant: {
      type: {
        GUEST: 'guest',
        PARTNER: 'partner'
      }
    },
    presence: {
      ABSENT: 'ABSENT',
      NO_SHOW: 'NO_SHOW',
      PRESENT: 'PRESENT',
      UNKNOWN: 'UNKNOWN'
    },
    status: {
      CLOSED: 'CLOSED',
      DRAFT: 'DRAFT',
      PUBLISHED: 'PUBLISHED',
      WINDED_UP: 'WINDED_UP'
    },
    subscription: {
      REFUSED: 'REFUSED',
      SUBSCRIBED: 'SUBSCRIBED',
      UNKNOWN: 'UNKNOWN'
    },
    type: {
      manual: 'MANUAL',
      oseus: 'OSEUS'
    }
  },

  // TODO temporary
  zoneGlobalAdLabel: 'Zone AD Global'
}
