<template>
  <v-dialog
    @input="$emit('change', $event)"
    :content-class="centered ? 'Modal-dialog' : 'Modal-dialog Modal-dialog-notcentered'"
    max-width="90%"
    no-click-animation
    persistent
    v-bind="$attrs"
    :value="modal"
    :width="width"
  >
    <v-card class="Modal-card px-8 pb-8 pt-3">
      <v-card-title v-if="title" class="Modal-title pt-5">
        <mybb-text weight="bold" size="20">{{ title }}</mybb-text>
      </v-card-title>
      <v-card-subtitle v-if="subtitle" class="Modal-subtitle">{{ subtitle }}</v-card-subtitle>
      <v-card-text class="mt-2" :class="$slots.actions ? '' : 'pb-3'">
        <span v-if="text" class="Modal-text">{{ text }}</span>
        <slot v-if="modal" name="text" />
        <slot v-if="modal" />
      </v-card-text>
      <v-card-actions v-if="$slots.actions" class="d-flex justify-center">
        <slot name="actions" />
      </v-card-actions>
      <v-btn v-if="closeIcon" @click="onClose" class="Modal-closeIcon" icon>
        <v-icon color="mybb-light">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',

  model: {
    event: 'change',
    prop: 'modal'
  },

  props: {
    closeIcon: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    width: {
      type: String,
      default: '760px'
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose() {
      if (!this.$listeners.close) {
        return this.$emit('change', false)
      } else {
        this.$listeners.close()
      }
    }
  }
}
</script>

<style lang="scss">
.Modal-dialog {
  position: absolute;
  font-family: 'Mulish', sans-serif;

  .v-text-field--outlined {
    border-radius: 8px;
  }
}

.Modal-dialog-notcentered {
  top: 66px;
}

.Modal-card,
.Modal-card .Modal-title,
.Modal-card .Modal-subtitle,
.Modal-card .Modal-text {
  color: var(--v-mybb-text-base);
}

.Modal-card {
  position: relative;
}

.Modal-title {
  font-weight: 500;
}

.Modal-closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
