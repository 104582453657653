<!--
=====================================================
                PreviewCard
=====================================================

  props:
    content: Object{
      chip: optional String,
      downloadLink: optional String,
      fileName: String,
      previewUrl: optional String,
      type: optional String,
    }
-->

<template>
  <v-card class="PreviewCard" elevation="5" light width="332px">
    <v-chip class="PreviewCard-chip" v-if="content.chip" color="#999">{{ content.chip }}</v-chip>
    <div class="PreviewCard-image grey lighten-4 px-7 pt-7 text-center">
      <template v-if="file || content.previewUrl">
        <div v-if="isPdf">
          <pdf :src="file || content.previewUrl" />
        </div>
        <v-img v-else :src="file || content.previewUrl" position="top center" height="350px" />
      </template>
      <v-icon v-else size="90" color="#ddd" class="pt-12">mdi-help</v-icon>
      <div class="PreviewCard-image-containerButtons">
        <base-input-file v-if="canEdit" @change="$emit('change', $event)" noDisplayInput>
          <template v-slot:button="{ click }">
            <v-btn @click="click.inputFile.click()" class="my-1 text-none" color="primary" elevation="5" rounded>
              {{ $t('previewCard.button.edit') }} <v-icon right small>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </base-input-file>
        <v-btn
          v-if="canDownload && (file || content.downloadLink)"
          :download="content.fileName || 'file.pdf'"
          :href="file || content.downloadLink"
          class="PreviewCard-image-downloadButton my-2 text-none"
          color="primary"
          elevation="3"
          outlined
          rounded
          target="_blank"
        >
          {{ $t('previewCard.button.download') }} <v-icon right small>mdi-download</v-icon>
        </v-btn>
        <v-btn
          v-else-if="canPreview && content.consultLink"
          :href="content.consultLink"
          target="_blank"
          class="my-1 text-none"
          color="primary"
          rounded
        >
          {{ $t('previewCard.button.preview') }} <v-icon right small>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-else-if="canPreview"
          @click="$emit('clickPreview')"
          target="_blank"
          class="my-1 text-none"
          color="primary"
          rounded
        >
          {{ $t('previewCard.button.preview') }} <v-icon right small>mdi-eye</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'PreviewCard',

  components: { pdf },

  model: {
    prop: 'file',
    event: 'change'
  },

  props: {
    canDownload: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canPreview: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => ({})
    },
    file: String
  },

  computed: {
    isPdf() {
      return (
        (this.content.type && this.content.type.toLowerCase() === 'pdf') ||
        (this.file && this.file.startsWith('data:application/pdf'))
      )
    }
  }
}
</script>

<style scoped lang="scss">
.PreviewCard {
  display: inline-block;
  vertical-align: top;
  margin: 8px;
}
.PreviewCard-chip {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 50%;
  height: 24px;
  color: #fff;
  transform: translateX(-50%);
}
.PreviewCard-image {
  position: relative;
  height: 241px;
  overflow: hidden;
}
.PreviewCard-image-containerButtons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PreviewCard-image-downloadButton {
  background: white;
  border: 2px solid var(--v-primary-base);
}
</style>
