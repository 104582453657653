import gql from 'graphql-tag'

import { HCP_PRIMARY_DATA } from '../../Hcp/Hcp'

import BASE from './Base'

export const MANUAL_PARTICIPANT = gql`
  fragment ManualParticipant on ManualParticipant {
    manualParticipantUuid
    participantUuid
    gender
    title
    lastName
    firstName
    email
    phoneNumber
    address
    birthDate
    society
    sncfNumber
    sncfCard
    sncfExpirationDate
    afNumber
    afCard
    afExpirationDate
  }
`

export default gql`
  fragment ParticipantWithUser on Participant {
    ...ParticipantBase
    healthCareProfessional {
      ...HealthCareProfessional
    }
    manualParticipant {
      ...ManualParticipant
    }
  }
  ${BASE}
  ${HCP_PRIMARY_DATA}
  ${MANUAL_PARTICIPANT}
`
