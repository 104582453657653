module.exports = {
  absentModal: {
    button: 'activity/details/absentModal/button',
    cancel: 'activity/details/absentModal/cancel',
    confirm: 'activity/details/absentModal/confirm'
  },
  addParticipants: 'activity/details/addParticipants',
  container: 'activity/details/container',
  counter: 'activity/details/counter',
  editButton: 'activity/details/editButton',
  item: (lastName, firstName) => ({
    lastName: `activity/details/item/${lastName}/${firstName}/lastName`,
    presence: {
      PRESENT: `activity/details/item/${lastName}/${firstName}/presence/present`,
      ABSENT: `activity/details/item/${lastName}/${firstName}/presence/absent`,
      NO_SHOW: `activity/details/item/${lastName}/${firstName}/presence/noShow`
    }
  }),
  title: 'activity/details/title'
}
