module.exports = {
  addButton: 'gathering/participant/addButton',
  container: 'gathering/participant/container',
  field: (lastName, firstName, field) => `gathering/participant/item/${lastName}/${firstName}/${field}`,
  hcpList: {
    invite: {
      button: 'gathering/participant/hcpList/invite/button',
      submit: 'gathering/participant/hcpList/invite/submit'
    },
    item: (lastName, firstName) => `gathering/participant/hcpList/item/${lastName}/${firstName}`,
    search: 'gathering/participant/hcpList/search'
  },
  participantPageButton: (lastName, firstName) =>
    `gathering/participant/hcpList/participantPageButton/${lastName}/${firstName}`,
  search: 'gathering/participant/search',
  setCategory: {
    button: 'gathering/participant/setCategory/button',
    select: 'gathering/participant/setCategory/select',
    submit: 'gathering/participant/setCategory/submit'
  },
  setPresence: {
    button: 'gathering/participant/setPresence/button',
    present: 'gathering/participant/setPresence/present',
    absent: 'gathering/participant/setPresence/absent',
    noShow: 'gathering/participant/setPresence/noShow',
    submit: 'gathering/participant/setPresence/submit'
  },
  setSubscription: {
    button: 'gathering/participant/setSubscription/button',
    submit: 'gathering/participant/setSubscription/submit'
  }
}
