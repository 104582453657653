module.exports = {
  validation: {
    askCorrection: 'congrex/gathering/validation/askCorrection',
    modal: {
      cancel: 'congrex/gathering/validation/modal/cancel',
      doubleConfirmation: 'congrex/gathering/validation/modal/doubleConfirmation',
      submit: 'congrex/gathering/validation/modal/submit'
    },
    validate: 'congrex/gathering/validation/validate'
  }
}
