<template>
  <v-dialog v-model="display" persistent no-click-animation max-width="1000px">
    <v-card class="ModalSignature">
      <div class="elevation-1">
        <div class="wrapper-signature turpud"><VueSignaturePad ref="signaturePad" class="signature-pad" /></div>
        <v-btn top right icon class="v-btn--absolute" @click.stop="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-actions v-if="caption" class="d-block text-center caption" v-html="caption" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="clearSignature"
          class="px-3 px-sm-10 mx-sm-8"
          color="primary"
          data-e2e="button-delete-signature"
          depressed
        >
          {{ $t('delete') }}
        </v-btn>
        <v-btn
          @click="saveSignature"
          color="primary"
          class="px-3 px-sm-10 mx-sm-8"
          data-e2e="button-valid-signature"
          depressed
        >
          {{ $t('validate') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad'
export default {
  name: 'ModalSignature',

  components: { VueSignaturePad },

  props: {
    caption: {
      type: String
    },
    display: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clearSignature() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature()
      }
    },
    closeModal() {
      this.$emit('close')
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

      if (isEmpty) {
        return
      }

      this.$emit('sign', data)
    }
  }
}
</script>

<style lang="scss">
.ModalSignature {
  position: relative;
  .wrapper-signature {
    width: 100%;
    height: 100%;
    padding-top: 50%;
    position: relative;
    .signature-pad {
      position: absolute;
      top: 0;
    }
  }
}
</style>
