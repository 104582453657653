<template>
  <v-btn
    v-on="$listeners"
    v-bind="props"
    :class="`CboBtn text-none white--text ${background}`"
    :width="width ? width : textMode ? null : iconMode ? 46 : 200"
    :height="height"
  >
    <v-icon v-if="innerIcon && !iconMode" size="24">{{ innerIcon }}</v-icon>
    <slot>
      <v-icon v-if="iconMode" size="24">{{ innerIcon }}</v-icon>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'MybbBtn',
  props: {
    color: {
      type: String,
      required: false,
      default: 'mybb-blue'
    },
    background: {
      type: String,
      required: false,
      default: ''
    },
    innerIcon: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: '46'
    },
    width: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    props() {
      return Object.assign(
        {},
        this.$attrs,
        this.$props,
        // Force color for white when not defined with a colored background
        this.iconMode && this.background && (!this.color || this.color === 'mybb-blue') ? { color: 'white' } : {}
      )
    },
    iconMode() {
      return ['', true, 'true'].includes(this.$attrs.icon)
    },
    textMode() {
      return ['', true, 'true'].includes(this.$attrs.text)
    }
  }
}
</script>

<style lang="scss">
.CboBtn {
  @extend .mybb-button-16;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;

  &:not(.icon) {
    .v-icon {
      margin-right: 2px;
    }
  }

  // to fix a true grey for disable case and not a transparent style
  &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #e4e4e4 !important;
  }
}
</style>
