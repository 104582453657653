module.exports = {
  body: 'congrex/solicitation/body',
  comment: 'congrex/solicitation/comment',
  confirmModal: {
    cancel: 'congrex/solicitation/confirmModal/cancel',
    confirm: 'congrex/solicitation/confirmModal/confirm'
  },
  container: 'congrex/solicitation/container',
  email: 'congrex/solicitation/email',
  interest: {
    actualAndNewTherapeutic: 'congrex/solicitation/interest/actualAndNewTherapeutic',
    clinicalStudyPresentation: 'congrex/solicitation/interest/clinicalStudyPresentation',
    newsSearchPath: 'congrex/solicitation/interest/newsSearchPath',
    others: 'congrex/solicitation/interest/others',
    shareClinicalKnowledge: 'congrex/solicitation/interest/shareClinicalKnowledge',
    therapeuticArea: 'congrex/solicitation/interest/therapeuticArea'
  },
  learnedSocietyMember: 'congrex/solicitation/learnedSocietyMember',
  list: {
    container: 'congrex/solicitation/list/container'
  },
  mandatory: {
    noBiogenObligation: 'congrex/solicitation/mandatory/noBiogenObligation',
    noOtherFunding: 'congrex/solicitation/mandatory/noOtherFunding',
    noOtherIntercontinentalFunding: 'congrex/solicitation/mandatory/noOtherIntercontinentalFunding'
  },
  phone: 'congrex/solicitation/phone',
  row: name => `congrex/solicitation/row/${name}`,
  status: name => `congrex/solicitation/status/${name}`,
  submit: 'congrex/solicitation/submit',
  submitValidate: 'congrex/solicitation/submitValidate',
  successModal: {
    confirm: 'congrex/solicitation/successModal/confirm'
  },
  support: {
    accommodation: 'congrex/solicitation/support/accommodation',
    transport: 'congrex/solicitation/support/transport',
    registration: 'congrex/solicitation/support/registration'
  },
  therapeuticCommitteeRole: 'congrex/solicitation/therapeuticCommitteeRole',
  validate: 'congrex/solicitation/validate',
  validation: {
    choice: {
      globalButton: 'congrex/solicitation/validation/choice/globalButton',
      zoneButton: 'congrex/solicitation/validation/choice/zoneButton'
    },
    globalQuota: {
      comment: 'congrex/solicitation/validation/globalQuota/comment',
      confirm: 'congrex/solicitation/validation/globalQuota/confirm'
    },
    zoneQuota: {
      comment: 'congrex/solicitation/validation/zoneQuota/comment',
      submit: 'congrex/solicitation/validation/zoneQuota/submit',
      zone: zone => `congrex/solicitation/validation/zoneQuota/quota/${zone}`
    }
  }
}
