const shared = require('../const/shared')

const availableNeuroBusinessUnitExternalIds = [
  shared.businessUnitExternalId.AD,
  shared.businessUnitExternalId.MS,
  shared.businessUnitExternalId.SMA
]

const availableBbuBusinessUnitExternalIds = [shared.businessUnitExternalId.BBU]

export const routes = [
  {
    path: '/',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "index" */ '@/views/dashboard/Index'),
    children: [
      // Admin
      {
        name: 'Admin',
        path: 'admin',
        meta: { appName: 'Admin' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UserList')
      },
      {
        name: 'AdminUserList',
        path: 'admin/user',
        meta: { appName: 'Admin' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UserList')
      },
      {
        name: 'AdminCreateUser',
        path: 'admin/user/_create',
        meta: { appName: 'Admin' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/CreateUser')
      },
      {
        name: 'AdminUpdateUser',
        path: 'admin/user/:uuid',
        meta: { appName: 'Admin' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UpdateUser')
      },
      {
        name: 'AdminManageDashboard',
        path: 'admin/applications',
        meta: { appName: 'Home' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/ManageDashboard')
      },
      {
        name: 'Debug',
        path: 'debug',
        meta: { appName: 'Home' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/Debug')
      },
      {
        name: 'UI',
        path: 'ui',
        meta: { appName: 'Congrex BO' },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/DebugUI')
      },
      // Dashboard
      {
        name: 'Home',
        path: '',
        meta: { appName: 'Home' },
        component: () => import(/* webpackChunkName: "index" */ '@/views/home/Home')
      },
      // MY BIOGEN
      {
        name: 'CongrexBO',
        path: 'congrex-bo',
        meta: { appName: 'Congrex BO' },
        redirect: { name: 'EventListMyBB' },
        component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/Index'),
        children: [
          {
            name: 'EventListMyBB',
            path: '',
            meta: { appName: 'Congrex BO' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/EventList')
          },
          {
            name: 'ListHCP',
            path: 'list-hcp',
            meta: { appName: 'Congrex BO' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HcpList')
          },
          {
            name: 'CreateEvent',
            path: 'create-event/:step',
            meta: { appName: 'Congrex BO' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/gatheringForm/EventForm')
          },
          {
            name: 'UpdateEvent',
            path: 'update-event/:gatheringUuid/:step',
            meta: { appName: 'Congrex BO' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/gatheringForm/EventForm')
          },

          {
            name: 'HCPPage',
            path: 'hcp/:hcpUuid',
            meta: { appName: 'Congrex BO' },
            redirect: { name: 'HCPProfile' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HCPPage'),
            children: [
              {
                name: 'HCPProfile',
                path: 'profile/:gatheringUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HCPProfile')
              },
              {
                name: 'HCPEvent',
                path: 'event',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HCPEvent')
              },
              {
                name: 'HCPEventDetails',
                path: 'event/:gatheringUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HcpEventDetails')
              }
            ]
          },
          {
            name: 'EventPage',
            path: 'event/:gatheringUuid',
            meta: { appName: 'Congrex BO' },
            redirect: { name: 'AttendeeList' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/EventPage'),
            children: [
              {
                name: 'GatheringDetails',
                path: 'details',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/GatheringDetails')
              },
              {
                name: 'UserProfile',
                path: 'user/:userUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/UserProfile')
              },
              {
                name: 'StaffProfile',
                path: 'staff/:userUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/StaffProfile')
              },
              {
                name: 'ExpenseNoteDetails',
                path: 'expense-note/:expenseNoteUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ExpenseNoteDetails')
              },
              {
                name: 'ExpenseNoteForm',
                path: 'expense-note-form/:expenseNoteUuid?',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ExpenseNoteForm')
              },
              {
                name: 'AttendeeList',
                path: 'attendee-list',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/AttendeeList')
              },
              {
                name: 'ParticipantsAdd',
                path: 'add-participants',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ParticipantsAdd')
              },
              {
                name: 'ParticipantStaffAdd',
                path: 'add-staff-participants',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ParticipantStaffAdd')
              },
              {
                name: 'ActivityForm',
                path: 'activity-form/:activityUuid?',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ActivityForm')
              },
              {
                name: 'ActivityParticipants',
                path: 'activity/:activityUuid/participants',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ActivityParticipants')
              },
              {
                name: 'ExpenseNoteList',
                path: 'expense-notes',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ExpenseNoteList')
              },
              {
                name: 'ActivityList',
                path: 'activities',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ActivityList')
              },
              {
                name: 'TransportNoteList',
                path: 'transport-notes',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/TransportNoteList')
              },
              {
                name: 'TransportNotePage',
                path: 'transport-note/:transportNoteUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/TransportNotePage')
              },
              {
                name: 'TransportNoteForm',
                path: 'transport-note-form/:transportNoteUuid?',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/TransportNoteForm')
              },
              {
                name: 'HostingList',
                path: 'hostings',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HostingList')
              },
              {
                name: 'HostingDetailsPage',
                path: 'hosting/:hostingUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HostingDetailsPage')
              },
              {
                name: 'HostingForm',
                path: 'hosting-form/:hostingUuid?',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HostingForm')
              },
              {
                name: 'HostingParticipants',
                path: 'hosting/:hostingUuid/participants',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/HostingParticipants')
              },
              {
                name: 'ActivityDetail',
                path: 'activity/:activityUuid',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ActivityDetail')
              },
              {
                name: 'TransferList',
                path: 'transfer-list',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/TransferList')
              },
              {
                name: 'SubscriptionForm',
                path: 'subscription-form',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/SubscriptionFormPage')
              }
            ]
          },
          // Virtual session
          {
            name: 'VirtualSessionPage',
            path: 'session/:virtualSessionUuid',
            meta: { appName: 'Congrex BO' },
            redirect: { name: 'VirtualSessionAttendeeList' },
            component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/EventPage'),
            children: [
              {
                name: 'VirtualSessionDetails',
                path: 'details',
                meta: { appName: 'Congrex BO' },
                component: () =>
                  import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/virtualSession/VirtualSessionDetails')
              },
              {
                name: 'VirtualSessionAttendeeList',
                path: 'participants',
                meta: { appName: 'Congrex BO' },
                component: () =>
                  import(
                    /* webpackChunkName: "myBiogen" */ '@/views/myBiogen/virtualSession/VirtualSessionAttendeeList'
                  )
              },
              {
                name: 'VirtualSessionParticipantsAdd',
                path: 'add-participants',
                meta: { appName: 'Congrex BO' },
                component: () => import(/* webpackChunkName: "myBiogen" */ '@/views/myBiogen/ParticipantsAdd')
              }
            ]
          }
        ]
      },
      // SERF Neuro
      {
        name: 'SERF',
        path: 'serf',
        meta: {
          theme: 'serf',
          appName: 'SERF Neuro',
          serfUri: 'SerfVierge_Neuro.pdf',
          availableBusinessUnitExternalIds: availableNeuroBusinessUnitExternalIds
        },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/serf/Home')
      },
      // SERF BBU
      {
        name: 'SERF_BBU',
        path: 'serf_bbu',
        meta: {
          theme: 'serf',
          appName: 'SERF Biosimilaires',
          serfUri: 'SerfVierge_BBU.pdf',
          availableBusinessUnitExternalIds: availableBbuBusinessUnitExternalIds
        },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/serf/Home')
      },
      {
        name: 'PagesError',
        path: 'serf/error',
        meta: {
          theme: 'serf',
          appName: 'SERF Error'
        },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/Error')
      },
      {
        name: 'HcpList',
        path: 'serf/list',
        meta: {
          theme: 'serf',
          appName: 'SERF Neuro',
          availableBusinessUnitExternalIds: availableNeuroBusinessUnitExternalIds
        },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/serf/HcpList')
      },
      {
        name: 'HcpList_BBU',
        path: 'serf_bbu/list',
        meta: {
          theme: 'serf',
          appName: 'SERF Biosimilaires',
          availableBusinessUnitExternalIds: availableBbuBusinessUnitExternalIds
        },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/serf/HcpList')
      },
      {
        name: 'SerfView',
        path: 'serf/:uuid',
        meta: { appName: 'SERF' },
        component: () => import(/* webpackChunkName: "serf" */ '@/views/serf/Serf')
      },
      // CONGREX
      {
        name: 'Congrex',
        path: 'congrex',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/SearchTabs'),
        children: [
          {
            name: 'SearchEvents',
            path: 'events',
            meta: { appName: 'Congrex' },
            component: () => import(/* webpackChunkName: "congrex" */ '@/components/congrex/EventList')
          },
          {
            name: 'SearchHcp',
            path: 'hcps',
            meta: { appName: 'Congrex' },
            component: () => import(/* webpackChunkName: "congrex" */ '@/components/congrex/HcpSearch')
          }
        ]
      },
      {
        name: 'HcpHistorical',
        path: 'congrex/hcp/:hcpUuid/historical',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/HcpHistorical')
      },
      {
        name: 'NewEvent',
        path: 'congrex/new_event/:eventType',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/NewEvent')
      },
      {
        name: 'EditEvent',
        path: 'congrex/edit_event/:eventType/:gatheringUuid',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/NewEvent')
      },
      {
        name: 'GuestLogistics',
        path: 'congrex/:gatheringUuid/event/:eveniumEventId/logistics/guest/:eveniumGuestId',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/ManagerLogistics')
      },
      {
        name: 'PresenceList',
        path: 'congrex/presenceList/:gatheringUuid',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/PresenceList')
      },
      {
        name: 'SolicitationsManagement',
        path: 'congrex/solicitations',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/SolicitationsManagement')
      },
      {
        name: 'CreateSolicitation',
        path: 'congrex/create_solicitation',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/CreateSolicitation')
      },
      {
        name: 'UpdateSolicitation',
        path: 'congrex/update_solicitation',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/UpdateSolicitation')
      },
      {
        name: 'SolicitationView',
        path: 'congrex/solicitations/:solicitationUuid',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/SolicitationView')
      },
      {
        name: 'CongressTabs',
        path: 'congrex/:gatheringUuid/tabs',
        meta: { appName: 'Congrex' },
        component: () => import(/* webpackChunkName: "congrex" */ '@/views/congrex/Tabs')
      },
      // Public
      {
        name: 'Login',
        path: 'login',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login')
      },
      {
        name: 'ResetPassword',
        path: 'reset-password',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "login" */ '@/views/ResetPassword')
      },
      {
        name: 'Serf_for_hcp',
        path: 'serf/hcp/:hcpUuid',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "serf-form" */ '@/views/serf/Form')
      },
      {
        name: 'Solicitation_for_hcp',
        path: 'create_solicitation',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "solicitation-form" */ '@/views/congrex/CreateSolicitation')
      },
      {
        name: 'Skyclarys',
        path: 'skyclarys',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "skyclarys" */ '@/views/skyclarys')
      },
      {
        name: 'SkyclarysAdmin',
        path: 'skyclarys/admin',
        meta: { appName: 'Skyclarys' },
        component: () => import(/* webpackChunkName: "skyclarys" */ '@/views/admin/skyclarys')
      },
      {
        name: 'SkyclarysListDocs',
        path: 'skyclarys/admin/list',
        meta: { appName: 'Skyclarys' },
        component: () => import(/* webpackChunkName: "skyclarys" */ '@/views/admin/skyclarys/ListingDoc')
      }
    ]
  },
  {
    path: '*',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "index" */ '@/views/dashboard/Index'),
    children: [
      {
        name: '404 Error',
        path: '',
        meta: { public: true },
        component: () => import(/* webpackChunkName: "index" */ '@/views/Error')
      }
    ]
  }
]
