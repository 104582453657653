<template>
  <v-autocomplete v-bind="props" v-on="$listeners" class="CboAutocomplete" height="46" outlined>
    <template v-if="icon" v-slot:prepend-inner>
      <v-icon>{{ icon }}</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'MybbAutocomplete',
  props: {
    // Reverse [hide-details] props to [true] by default (most used case)
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false
    }
  },
  computed: {
    props() {
      const hideDetails = this.hideDetails && !this.$attrs['error-messages']
      return Object.assign({}, this.$props, this.$attrs, { hideDetails })
    }
  }
}
</script>

<style lang="scss">
@import './mixin.scss';

.CboAutocomplete {
  @include cbo-fields;

  //&.v-text-field.v-text-field--enclosed .v-input__append-inner {
  //  margin-top: 17px;
  //}
}
</style>
