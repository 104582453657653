import gql from 'graphql-tag'

import { BASE, WITH_USER } from '../Participant/fragments'

const ACTIVITY = gql`
  fragment Activity on Activity {
    activityCategoryUuid
    activityCategory {
      activityCategoryUuid
      label
      color
      isRestauration
      isDiner
    }
    activityUuid
    activityParticipants {
      activityParticipantUuid
      participationType
      presence
      participant {
        ...ParticipantBase
      }
    }
    gatheringUuid
    organizer
    isVirtual
    isPhysical
    name
    date
    startHour
    endHour
    room
    place
    visioLink
    website
    imageUuid
    image {
      content
      name
    }
    documentUuid
    document {
      content
      name
    }
    entryType
    price
    quota
    hasCustomTarget
    activityTarget
    activityOrators {
      activityUuid
      activityOratorUuid
      participantUuid
      participant {
        ...ParticipantBase
      }
    }
  }
  ${BASE}
`

export const GET_ACTIVITY_CATEGORIES = gql`
  query activityCategories {
    activityCategories {
      activityCategoryUuid
      label
    }
  }
`

export const GET_ACTIVITY = gql`
  query activity($activityUuid: GUID!) {
    activity(activityUuid: $activityUuid) {
      ...Activity
    }
  }
  ${ACTIVITY}
`

export const UNSUBSCRIBE_PARTICIPANTS = gql`
  mutation unsubscribeParticipants($activityUuid: GUID!, $activityParticipantsUuid: [GUID!]!) {
    unsubscribeParticipants(activityUuid: $activityUuid, activityParticipantsUuid: $activityParticipantsUuid) {
      ...Activity
    }
  }
  ${ACTIVITY}
`

export const SET_PARTICIPANTS_ABSENT = gql`
  mutation setActivityParticipantsAbsent($activityUuid: GUID!, $activityParticipantsUuid: [GUID!]!) {
    setActivityParticipantsAbsent(activityUuid: $activityUuid, activityParticipantsUuid: $activityParticipantsUuid) {
      activityParticipantUuid
    }
  }
`

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($activityUuid: GUID!) {
    deleteActivity(activityUuid: $activityUuid)
  }
`

export const GET_AVAILABLES_PARTICIPANTS = gql`
  query getAvailableParticipants($activityUuid: GUID!) {
    getAvailableParticipants(activityUuid: $activityUuid) {
      ...ParticipantWithUser
    }
  }
  ${WITH_USER}
`

export const SUBSCRIBE_PARTICIPANTS = gql`
  mutation subscribeParticipants($activityUuid: GUID!, $participantsUuid: [GUID!]!) {
    subscribeParticipants(activityUuid: $activityUuid, participantsUuid: $participantsUuid) {
      activityParticipantUuid
    }
  }
`

export const AVAILABLE_ACTIVITIES_FOR_CATEGORY = gql`
  query availableActivitiesForCategory($gatheringUuid: GUID!, $categoryId: String!) {
    availableActivitiesForCategory(gatheringUuid: $gatheringUuid, categoryId: $categoryId) {
      ...Activity
    }
  }
  ${ACTIVITY}
`
