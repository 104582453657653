module.exports = {
  availablePlace: 'gathering/form/availablePlace',
  businessUnitChip: bu => `gathering/form/businessUnitChip/${bu}`,
  container: 'gathering/form/container',
  confirmCreateModal: {
    cancel: 'gathering/form/confirmCreateModal/cancel',
    confirm: 'gathering/form/confirmCreateModal/confirm'
  },
  cp: bu => `gathering/form/cp/${bu}`,
  cpBackup: bu => `gathering/form/cpBackup/${bu}`,
  criteria: (bu, criteria) => `gathering/form/criteria/${bu}/${criteria}`,
  description: 'gathering/form/description',
  dm: bu => `gathering/form/dm/${bu}`,
  dmBackup: bu => `gathering/form/dmBackup/${bu}`,
  doctorQuota: bu => `gathering/form/doctorQuota/${bu}`,
  eventDates: 'gathering/form/eventDates',
  biogenEvent: 'gathering/form/eventType',
  hasAutomatedValidation: bu => `gathering/form/hasAutomatedValidation/${bu}`,
  hasZoneQuota: bu => `gathering/form/hasZoneQuota/${bu}`,
  learnedSociety: 'gathering/form/learnedSociety',
  mainBusinessUnitChip: bu => `gathering/form/mainBusinessUnitChip/${bu}`,
  mci: 'gathering/form/mci',
  mciBackup: 'gathering/form/mciBackup',
  mslResponsible: bu => `gathering/form/mslResponsible/${bu}`,
  name: 'gathering/form/name',
  nationalityType: 'gathering/form/nationalityType',
  next: 'gathering/form/next',
  otherQuota: bu => `gathering/form/otherQuota/${bu}`,
  prev: 'gathering/form/prev',
  save: 'gathering/form/save',
  solicitationEndDate: bu => `gathering/form/solicitationEndDate/${bu}`,
  statusChange: {
    button: 'gathering/form/statusChange/button',
    cancel: 'gathering/form/statusChange/cancel',
    confirm: 'gathering/form/statusChange/confirm',
    doubleConfirmation: 'gathering/form/statusChange/doubleConfirmation'
  },
  teamFormContainer: bu => `gathering/form/teamFormContainer/${bu}`,
  typeModal: {
    container: 'gathering/form/typeModal/container',
    congress: 'gathering/form/typeModal/congress',
    biogenEvent: 'gathering/form/typeModal/biogenEvent',
    nature: {
      physical: 'gathering/form/typeModal/nature/physical',
      virtual: 'gathering/form/typeModal/nature/virtual',
      hybrid: 'gathering/form/typeModal/nature/hybrid'
    },
    submit: 'gathering/form/typeModal/submit'
  },
  roc: 'gathering/form/roc',
  rocBackup: 'gathering/form/rocBackup',
  zoneQuota: (bu, zone) => `gathering/form/zoneQuota/${bu}/${zone}`
}
