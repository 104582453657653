<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <mybb-btn v-bind="attrs" v-cy="cypress" v-on="on" :width="width" :disabled="disabled">
        <span class="Dropdown-Label pr-1">{{ title }}</span>
        <v-icon
          size="20"
          :class="{ 'Dropdown-Icon': true, 'Dropdown-Icon--expanded': attrs['aria-expanded'] === 'true' }"
        >
          mdi-chevron-down
        </v-icon>
      </mybb-btn>
    </template>

    <div class="Dropdown mt-2">
      <v-list class="Dropdown-List">
        <div v-for="(item, index) in items" :key="index">
          <v-divider v-if="!item" />
          <v-list-item v-else>
            <v-hover v-slot="{ hover }">
              <v-list-item-title
                class="menu-list-item-title text-right"
                @click="$emit(item.event)"
                v-cy="`${cypress}/${item.title}`"
              >
                <mybb-text size="16" :weight="hover ? 'bold' : 'semi-bold'">{{ item.title }}</mybb-text>
              </v-list-item-title>
            </v-hover>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'MybbMenu',
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      validator(prop) {
        return (
          Array.isArray(prop) &&
          prop.every(item => item === null || (item.hasOwnProperty('event') && item.hasOwnProperty('title')))
        )
      }
    },
    width: {
      type: String,
      default: '220'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cypress: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.Dropdown-List {
  border-radius: 8px;
  overflow: hidden;
}

.Dropdown-Label {
  display: inline-block;
  border-right: 1px solid white;
}

.Dropdown-Icon {
  transition: all 0.15s ease-in-out;
  transform: rotate(0);

  &--expanded {
    transform: rotate(-180deg);
  }
}

.menu-list-item-title {
  cursor: pointer;
}
</style>
