const activity = require('./activity')
const congrex = require('./congrex')
const dashboard = require('./dashboard')
const gathering = require('./gathering')
const globalIdentifiers = require('./global')
const login = require('./login')
const participant = require('./participant')

module.exports = {
  activity,
  congrex,
  dashboard,
  gathering,
  global: globalIdentifiers,
  login,
  participant
}
