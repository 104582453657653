import gql from 'graphql-tag'

import BASE from './Base'

export default gql`
  fragment ParticipantWithActivities on Participant {
    ...ParticipantBase
    activityParticipants {
      activityParticipantUuid
      activityUuid
      activity {
        name
        organizer
        activityCategory {
          color
          label
          isRestauration
        }
        date
        startHour
        endHour
      }
      isOrator
      presence
      participationType
    }
  }
  ${BASE}
`
