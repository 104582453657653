import gql from 'graphql-tag'

export const SUBSCRIBE_INTERNAL_USER = gql`
  mutation subscribeInternalUser(
    $participantUuid: GUID!
    $gatheringUuid: GUID!
    $mobilePhone: String
    $participationType: ParticipationType
    $present: Boolean!
    $activityInput: [SubscriptionActivityInput]
    $hostingInput: SubscriptionHostingInput
    $transportInput: SubscriptionTransportInput
  ) {
    subscribeInternalUser(
      participantUuid: $participantUuid
      gatheringUuid: $gatheringUuid
      mobilePhone: $mobilePhone
      participationType: $participationType
      present: $present
      activityInput: $activityInput
      hostingInput: $hostingInput
      transportInput: $transportInput
    ) {
      participantUuid
    }
  }
`
