<template>
  <v-dialog
    @click:outside="$emit('change', false)"
    max-width="490"
    no-click-animation
    :persistent="persistent"
    :value="dialog"
  >
    <template v-slot:activator="{ on }">
      <slot v-bind:on="on" />
    </template>
    <v-card :loading="displayLoading" data-e2e="confirm-modal">
      <v-card-title class="title">{{ title }}</v-card-title>
      <v-card-text class="mb-9">
        {{ text }}
        <slot name="text" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!noCancel"
          @click="cancelModal"
          color="error"
          text
          v-cy="$cy.congrex.solicitation.confirmModal.cancel"
          >{{ $t('cancel') }}</v-btn
        >
        <v-btn
          @click="confirmModal"
          class="mr-4"
          :disabled="disableValidate"
          color="success"
          v-cy="$cy.congrex.solicitation.confirmModal.confirm"
          text
          >{{ $t('confirm') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',

  model: {
    prop: 'dialog',
    event: 'change'
  },

  props: {
    loaded: {
      type: Boolean,
      default: true
    },
    dialog: {
      type: Boolean,
      default: true
    },
    disableValidate: {
      type: Boolean,
      default: false
    },
    noCancel: {
      type: Boolean,
      default: false
    },
    text: String,
    title: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      confirm: false,
      modal: false
    }
  },

  computed: {
    displayLoading() {
      return !this.loaded && this.confirm
    }
  },

  methods: {
    cancelModal() {
      this.$emit('cancel')
      this.$emit('change', false)
    },
    confirmModal() {
      if (this.loaded === false) {
        this.confirm = true
        this.$emit('confirm', true)
      } else {
        this.$emit('change', false)
        this.$emit('confirm', true)
      }
    }
  },

  updated() {
    if (this.confirm && this.loaded) {
      this.modal = false
    }
  }
}
</script>
