import gql from 'graphql-tag'

import BASE from './Base'
import { EXPENSE_NOTE } from '../../ExpenseNote/ExpenseNote'

export default gql`
  fragment ParticipantWithExpenseNotes on Participant {
    ...ParticipantBase
    expenseNotes {
      ...expenseNote
    }
  }
  ${BASE}
  ${EXPENSE_NOTE}
`
