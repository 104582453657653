const eventSearch = require('./eventSearch')
const gathering = require('./gathering')
const hcpList = require('./hcpList')
const list = require('./list')
const solicitation = require('./solicitation')

module.exports = {
  eventSearch,
  gathering,
  global: {
    tab: label => `congrex/global/tab/${label}`
  },
  hcpList,
  list,
  modal: {
    confirm: 'congrex/modal/confirm'
  },
  solicitation
}
