import gql from 'graphql-tag'

import BASE from './Base'
import { TRANSPORT_NOTE } from '../../Transport'

export default gql`
  fragment ParticipantWithTransports on Participant {
    ...ParticipantBase
    transportNotes {
      ...TransportNote
    }
  }
  ${BASE}
  ${TRANSPORT_NOTE}
`
