import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'

import { hasOneOfType } from '@/services/user'
import { userType } from '@/const/shared'

import store from '../store'
import shared from '../const/shared'
import { routes } from './routes'

Vue.use(Router)
Vue.use(VueCookies)

const redirections = [{ from: 'CongrexBO', to: 'EventListMyBB' }]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.public)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Vue.$cookies.get('jwt')) {
      store.commit('RESET_STORE')
      Vue.$cookies.set(
        'loginRedirect',
        { name: to.name, meta: to.meta, params: to.params, query: to.query, path: to.path },
        '1h'
      )
      return next({ name: 'Login' })
    } else {
      if (to.name === 'Congrex') {
        if (hasOneOfType([userType.ABM, userType.MSL])) next({ name: 'SearchHcp' })
        else return next({ name: 'SearchEvents' })
      }

      /**
       * My Biogen check
       */
      if (to.meta.appName === 'Congrex BO') {
        const user = store.state.myUser
        const { MCI, ROC, ADMIN, SUPER_ADMIN } = shared.userType
        const canAccessMyBiogen =
          user &&
          Array.isArray(user.userTypes) &&
          user.userTypes.some(type => [MCI, ROC, ADMIN, SUPER_ADMIN].includes(type))

        if (canAccessMyBiogen) {
          return next()
        } else {
          return next({ name: 'Home' })
        }
      }
    }

    return next()
  } else {
    const redirection = redirections.find(r => r.from === to.name)

    if (redirection) {
      return next({ name: redirection.to })
    } else {
      return next() // does not require auth, make sure to always call next()!
    }
  }
})

router.afterEach((to, from) => {
  if (from.meta.appName === to.meta.appName) return
  let file
  switch (to.meta.appName) {
    case 'Congrex BO':
      file = 'back'
      break
    case 'Congrex':
      file = 'staff'
      break
    default:
      file = 'default'
      break
  }
  const favicon = document.querySelector('link[rel=icon]')
  favicon.href = favicon.href.replace(window.origin, '').replace(/(\/favicon\/)(.+)(\/.+)/gm, `$1${file}$3`)
})

export default router
