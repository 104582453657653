import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr'
import i18n from '@/i18n'

// Install the Plugin.
Vue.use(VeeValidate, { inject: false })

// Localize takes the locale object as the second argument (optional) and merges it.
Validator.localize('fr', fr)

Validator.extend('after_alphabetical', {
  getMessage: (value, [ref]) => i18n.t('customError.afterAlphabetical', { value, ref }),
  validate: (value, [ref]) => value > ref
})

// Password rule
Validator.extend('password', {
  getMessage: () => i18n.t('password-form-message'),
  validate: value => {
    if (typeof value !== 'string' || !value) return false

    const upperRegex = /[A-Z]/
    const lowerRegex = /[a-z]/
    const numberRegex = /\d/

    return value.length >= 8 && upperRegex.test(value) && lowerRegex.test(value) && numberRegex.test(value)
  }
})

Validator.extend('startWithHttp', {
  getMessage: field => i18n.t('errorMessages.startWithHttp', { value: field }),
  validate: value => value.startsWith('http://') || value.startsWith('https://')
})
