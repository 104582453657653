<template>
  <v-img
    aspect-ratio="0"
    :src="imageUrl"
    class="Banner py-5 congrex-primary lighten-3 d-flex align-center text-center white--text"
    data-e2e="banner"
  >
    <div v-if="loading" class="d-flex justify-center align-content-center pt-4">
      <v-progress-circular indeterminate color="white" size="64" />
    </div>
    <template v-else>
      <v-btn
        v-if="returnRoute"
        icon
        class="Banner-returnLink ml-sm-9"
        data-e2e="button-back-banner"
        :to="returnRoute"
        dark
        exact
      >
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <div class="d-flex justify-center flex-column flex-grow-1 ">
        <div class="d-flex justify-center display-1 font-weight-regular">
          <div class="Banner-title" data-e2e="title-banner">{{ title }}</div>
          <v-icon v-if="titleLogo" class="text-left ml-3" color="white" data-e2e="logo-banner">
            {{ titleLogo }}
          </v-icon>
        </div>

        <div class="d-block subtitle-1" v-html="subTitle" />

        <div v-if="isSubscribed || canSubscribe || refusedSubscription" class="mt-2">
          <v-btn
            v-if="canSubscribe"
            color="congrex-primary"
            @click="showSubscriptionForm = true"
            dark
            dense
            depressed
            small
          >
            {{ $t('subscribe') }}
          </v-btn>
          <v-btn
            v-if="isSubscribed"
            color="congrex-primary"
            @click="showSubscriptionForm = true"
            dark
            dense
            depressed
            small
          >
            {{ $t('edit-register') }}
          </v-btn>
          <mybb-text v-else-if="refusedSubscription" color="mybb-error" weight="semi-bold">
            {{ $t('refused-subscription') }}
          </mybb-text>
        </div>
      </div>

      <slot> </slot>

      <subscription-form-dialog
        v-model="showSubscriptionForm"
        :gathering-uuid="gathering && gathering.gatheringUuid"
        :participant-uuid="currentParticipant ? currentParticipant.participantUuid : null"
        @refresh="$emit('refresh')"
      />
    </template>
  </v-img>
</template>

<script>
import Categories from 'mybb-categories'

import SubscriptionFormDialog from '../congrex/participant/SubscriptionFormDialog'

export default {
  name: 'Banner',
  components: { SubscriptionFormDialog },
  props: {
    imageUrl: String,
    loading: {
      type: Boolean,
      default: false
    },
    returnRoute: [String, Object],
    subTitle: String,
    title: String,
    titleLogo: String,
    gathering: Object,
    currentParticipant: Object
  },
  data() {
    return {
      showSubscriptionForm: false
    }
  },
  computed: {
    canSubscribe() {
      if (!this.gathering || !this.currentParticipant) return false

      const { PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      return (
        [PUBLISHED, PUBLISHED_FRONT].includes(this.gathering.status) &&
        this.currentParticipant.invitationStatus === this.$const.invitationStatus.pending &&
        this.currentParticipant.category !== Categories.noCategoryForVersion(this.gathering.categoryVersion).id
      )
    },
    isSubscribed() {
      return (
        this.currentParticipant && this.currentParticipant.invitationStatus === this.$const.invitationStatus.confirmed
      )
    },
    refusedSubscription() {
      return (
        this.currentParticipant && this.currentParticipant.invitationStatus === this.$const.invitationStatus.refused
      )
    }
  }
}
</script>

<style scoped lang="scss">
.Banner-returnLink {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 35px;
  color: white;
  transform: translateY(-50%);
}

.Banner-title {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 900px) {
    width: 70%;
  }
}
</style>
