<template>
  <v-menu v-model="menu" offset-y max-width="100%" :close-on-content-click="false" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <mybb-text-field
        :value="time"
        :disabled="disabled"
        v-on="on"
        :label="label"
        v-bind="$attrs"
        icon="mdi-clock-outline"
        readonly
        v-cy="cypress"
      />
    </template>
    <v-time-picker v-model="time" format="24hr" v-cy="`${cypress}-picker`" />
  </v-menu>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    cypress: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    time: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.menu = false
      }
    }
  }
}
</script>
