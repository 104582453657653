module.exports = {
  activities: {
    container: 'participant/gathering/activities/container',
    item: name => ({
      isOrator: `participant/gathering/activities/${name}/isOrator`,
      name: `participant/gathering/activities/${name}/name`
    })
  },
  advantages: {
    container: 'participant/gathering/advantages/container',
    field: name => `participant/gathering/advantages/field/${name}`
  },
  container: 'participant/gathering/container'
}
