<template>
  <div>
    <v-container v-if="!forUser" class="Container">
      <mybb-text class="d-block text-uppercase mb-4" weight="bold" size="16">
        {{ t('category.title') }}
      </mybb-text>

      <mybb-text class="d-block mb-2">
        {{ t('category.text') }}
      </mybb-text>

      <mybb-text class="d-block mb-5" weight="bold">
        {{ t('category.highlight') }}
      </mybb-text>

      <mybb-select
        v-model="categoryId"
        :label="t('category.label')"
        :items="categories"
        class="Select"
        :disabled="isWindedUpGathering"
      />
    </v-container>

    <div v-if="queriesLoading === 0">
      <v-container
        v-if="categoryId || (participant && participant.category)"
        :class="{ Container: !forUser }"
        :fluid="forUser"
      >
        <div>
          <mybb-text class="Title" weight="bold" size="16">{{ t('participation.title') }}</mybb-text>
        </div>
        <mybb-text class="mb-5">{{ t('participation.text', gathering) }}</mybb-text>

        <mybb-radios
          v-model="confirmedParticipation"
          :label="t('participation.presenceLabel')"
          :items="radioItems"
          class="Radio"
          weight="semi-bold"
          row
        />

        <v-row v-if="confirmedParticipation && internal">
          <v-col cols="3">
            <mybb-text class="mb-3" weight="bold">{{ t('participation.mobilePhoneText') }}</mybb-text>
            <mybb-text-field
              v-model="mobilePhone"
              :label="t('participation.mobilePhoneLabel')"
              icon="mdi-cellphone"
              :placeholder="t('participation.mobilePhonePlaceHolder')"
            />
          </v-col>
        </v-row>

        <v-row v-if="isCongress && confirmedParticipation && !internal">
          <v-col cols="9">
            <mybb-radios
              v-model="isMemberOfLearnedSociety"
              :label="t('participation.isLearnedSocietyMemberLabel', $get(gathering, 'congress'))"
              :items="radioItems"
              class="mt-3 Radio"
              row
            />
          </v-col>

          <v-col cols="3">
            <mybb-text-field
              v-model="memberNumber"
              :label="t('participation.learnedSocietyMemberLabel')"
              :disabled="!isMemberOfLearnedSociety"
              icon="mdi-id-card"
            />
          </v-col>
        </v-row>

        <mybb-radios
          v-if="confirmedParticipation && isHybrid"
          v-model="participationType"
          :label="t('participation.participationTypeLabel')"
          :items="participationItems"
          class="mt-3"
          row
        />
      </v-container>

      <div v-if="confirmedParticipation">
        <v-container
          v-if="category && Array.isArray(availableActivities) && availableActivities.length"
          :class="{ Container: !forUser }"
          :fluid="forUser"
        >
          <mybb-text class="Title" weight="bold" size="16">{{ t('activities.title') }}</mybb-text>
          <activities-form
            v-if="availableParticipant"
            v-model="participantProxy"
            :activities="availableActivities"
            :internal="internal"
            :dataUser="availableParticipant"
            @activity-subscription="handleActivitySubscription"
            @activity-settings="payload => $emit('activity-settings', payload)"
          />
        </v-container>

        <v-container v-if="showTransport && availableParticipant" :class="{ Container: !forUser }" :fluid="forUser">
          <mybb-text class="Title" weight="bold" size="16">{{ t('transport.title') }}</mybb-text>
          <transport-form v-model="participantProxy" :internal="internal" :dataUser="availableParticipant" />
        </v-container>

        <v-container v-if="showHosting && availableParticipant" :class="{ Container: !forUser }" :fluid="forUser">
          <mybb-text class="Title" weight="bold" size="16">{{ t('hosting.title') }}</mybb-text>
          <hosting-form v-model="participantProxy" :gathering="gathering" :dataUser="availableParticipant" />
        </v-container>
      </div>
    </div>

    <div v-else>
      <v-container class="Container">
        <loader-tab />
      </v-container>
    </div>
  </div>
</template>

<script>
import Categories from 'mybb-categories'
import _set from 'lodash/set'

import { GET_AVAILABLE_AND_USED_CATEGORIES } from '@/graphql/Gatherings'
import { AVAILABLE_ACTIVITIES_FOR_CATEGORY } from '@/graphql/Activity'
import { PARTICIPANT_STAFF } from '@/graphql/Participant'

import ActivitiesForm from './ActivitiesForm'
import TransportForm from './TransportForm'
import HostingForm from './HostingForm'
import LoaderTab from '../../congrex/tabs/LoaderTab.vue'

import { yup } from '@/mixins'

export default {
  components: { ActivitiesForm, TransportForm, HostingForm, LoaderTab },
  name: 'SubscriptionForm',
  mixins: [yup],
  model: {
    prop: 'participant'
  },
  props: {
    participant: {
      type: Object
    },
    gatheringUuid: {
      type: String
    },
    gathering: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            prop.hasOwnProperty('categoryVersion') &&
            prop.hasOwnProperty('isPhysical') &&
            prop.hasOwnProperty('isVirtual'))
        )
      }
    },
    forUser: {
      type: Boolean
    },
    internal: {
      type: Boolean
    }
  },
  data() {
    return {
      categoryId: null,
      localConfirmedParticipation: null,
      isMemberOfLearnedSociety: null,
      memberNumber: null,
      localParticipationType: this.$const.activity.participationType.physical,
      queriesLoading: 0
    }
  },
  computed: {
    participantProxy: {
      get() {
        return this.participant
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    mobilePhone: {
      get() {
        if (this.availableParticipant && this.availableParticipant.user.phone) {
          return this.availableParticipant.user.phone
        }
        return ''
      },
      set(value) {
        this.setPartial({ mobilePhone: value })
      }
    },
    confirmedParticipation: {
      get() {
        if (!this.participant) return this.localConfirmedParticipation

        const { confirmed, pending } = this.$const.invitationStatus
        const invitationStatus = this.$get(this.participant, 'invitationStatus', null)

        if (!invitationStatus || invitationStatus === pending) return null
        return invitationStatus === confirmed
      },
      set(value) {
        if (!this.participant) {
          this.localConfirmedParticipation = value
          return
        }

        const { confirmed, refused } = this.$const.invitationStatus
        const invitationStatus = value ? confirmed : refused

        this.setPartial({ invitationStatus })
      }
    },
    participationType: {
      get() {
        if (!this.participant) return this.localParticipationType

        return this.participant.participationType
      },
      set(value) {
        if (!this.participant) {
          this.localConfirmedParticipation = value
          return
        }

        this.setPartial({ participationType: value })
      }
    },
    category() {
      if (!this.categoryId && !this.participant) return null

      return Categories.forVersionAndCategoryId(
        this.gathering.categoryVersion,
        this.participant ? this.participant.category : this.categoryId
      )
    },
    isCongress() {
      return this.gathering ? Boolean(this.gathering.congress) : false
    },
    isHybrid() {
      if (!this.gathering) return false

      return this.gathering.isPhysical && this.gathering.isVirtual
    },
    showTransport() {
      if (
        !this.category ||
        (this.isHybrid && this.participationType === this.$const.activity.participationType.virtual)
      )
        return false

      return this.category.modules.includes(Categories.constants.Module.Transport)
    },
    showHosting() {
      if (
        !this.category ||
        (this.isHybrid && this.participationType === this.$const.activity.participationType.virtual)
      )
        return false

      if (this.internal && this.forUser) {
        if (!this.gathering.hostingConfiguration) return false
        if (!Array.isArray(this.gathering.hostings) || this.gathering.hostings.length <= 0) return false
      }

      return this.category.modules.includes(Categories.constants.Module.Hosting)
    },
    categories() {
      if (!this.relativeCategories) return []

      return this.relativeCategories.map(category => ({
        value: category.id,
        text: category.label
      }))
    },
    radioItems() {
      return [{ value: true, label: this.$t('yes') }, { value: false, label: this.$t('no') }]
    },
    participationItems() {
      const { physical, virtual } = this.$const.activity.participationType

      return [
        { value: physical, label: this.t('participation.physical') },
        { value: virtual, label: this.t('participation.virtual') }
      ]
    },
    isPhysicalParticipation() {
      return this.participationType === this.$const.activity.participationType.physical
    },
    isVirtualParticipation() {
      return this.participationType === this.$const.activity.participationType.virtual
    },
    availableParticipant() {
      if (!this.checkIfParticipantIsAlreadyRegister) return false

      return this.checkIfParticipantIsAlreadyRegister
    },
    availableActivities() {
      if (!this.availableActivitiesForCategory) return

      return this.availableActivitiesForCategory.filter(activity => {
        const { physical, virtual } = this.$const.activity.participationType

        if (this.internal && !activity.activityCategory.isDiner) return false
        if (!this.isHybrid || (this.isHybrid && !this.confirmedParticipation)) return true

        return (
          (activity.isVirtual && this.participationType === virtual) ||
          (activity.isPhysical && this.participationType === physical)
        )
      })
    },
    isWindedUpGathering() {
      return this.gathering && this.gathering.status === this.$const.gatheringStatus.WINDED_UP
    }
  },
  apollo: {
    relativeCategories: {
      query: GET_AVAILABLE_AND_USED_CATEGORIES,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid || this.$route.params.gatheringUuid
        }
      },
      update({ getAvailableAndUsedCategories }) {
        return getAvailableAndUsedCategories
      },
      skip() {
        return Boolean(this.participant)
      }
    },
    availableActivitiesForCategory: {
      query: AVAILABLE_ACTIVITIES_FOR_CATEGORY,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid || this.$route.params.gatheringUuid,
          categoryId: this.participant ? this.participant.category : this.categoryId
        }
      },
      skip() {
        return !this.categoryId && !this.participant
      },
      loadingKey: 'queriesLoading'
    },
    checkIfParticipantIsAlreadyRegister: {
      query: PARTICIPANT_STAFF,
      variables() {
        return {
          participantUuid: this.participant.participantUuid
        }
      },
      skip() {
        return !this.participant.participantUuid
      },
      update(data) {
        return data.participant
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.${key}`, params)
    },
    setPartial(partial) {
      this.$emit('input', { ...this.participant, ...partial })
    },
    handleActivitySubscription({ date, interval, value }) {
      const activitiesSubscription = this.participant.activitiesSubscription
        ? JSON.parse(JSON.stringify(this.participant.activitiesSubscription))
        : {}
      _set(activitiesSubscription, [date, interval], value)
      this.setPartial({ activitiesSubscription })
    }
  },
  watch: {
    isMemberOfLearnedSociety(val, old) {
      if (!val && old) {
        this.memberNumber = null
      }
    },
    categoryId(value, old) {
      if (value === old) return

      this.confirmedParticipation = null
      this.isMemberOfLearnedSociety = null
      this.memberNumber = null
      this.participationType = this.$const.activity.participationType.physical
    },
    mobilePhone(value, old) {
      if (value && !old) {
        this.setPartial({ mobilePhone: value })
      }
    }
  },
  mounted() {
    if (!this.participant) return

    if (!this.participant.participationType) {
      this.participationType = this.$const.activity.participationType.physical
    }
  }
}
</script>
<style scoped>
.container {
  padding: 12px 0;
}
</style>
<style lang="scss" scoped>
.Container {
  border-radius: 8px;
  background-color: white;
  padding: 20px 24px;
  margin-bottom: 24px;

  .Select {
    width: 50%;
  }
}

.Title {
  display: block;
  padding-bottom: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--v-mybb-grey-lighten1);
  text-transform: uppercase;
}
</style>
