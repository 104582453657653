import gql from 'graphql-tag'

export const CAN_SEND_TO_VALIDATION = gql`
  query canSendToValidation($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    canSendToValidation(gatheringUuid: $gatheringUuid, congressUuid: $congressUuid, biogenEventUuid: $biogenEventUuid)
  }
`

export const GET_MISSING_CONFIGURATION_FOR_PUBLICATION = gql`
  query missingConfigurationForPublication($gatheringUuid: GUID!) {
    missingConfigurationForPublication(gatheringUuid: $gatheringUuid)
  }
`

export const SEND_TO_VALIDATION = gql`
  mutation setGatheringStatus($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    setGatheringStatus(
      gatheringUuid: $gatheringUuid
      congressUuid: $congressUuid
      biogenEventUuid: $biogenEventUuid
      targetStatus: TO_VALIDATE
    ) {
      gatheringUuid
    }
  }
`

export const PUBLISH_INTERNAL = gql`
  mutation setGatheringStatus($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    setGatheringStatus(
      gatheringUuid: $gatheringUuid
      congressUuid: $congressUuid
      biogenEventUuid: $biogenEventUuid
      targetStatus: PUBLISHED
    ) {
      gatheringUuid
    }
  }
`

export const PUBLISH_FRONT = gql`
  mutation setGatheringStatus($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    setGatheringStatus(
      gatheringUuid: $gatheringUuid
      congressUuid: $congressUuid
      biogenEventUuid: $biogenEventUuid
      targetStatus: PUBLISHED_FRONT
    ) {
      gatheringUuid
    }
  }
`

export const CLOSE = gql`
  mutation setGatheringStatus($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    setGatheringStatus(
      gatheringUuid: $gatheringUuid
      congressUuid: $congressUuid
      biogenEventUuid: $biogenEventUuid
      targetStatus: CLOSED
    ) {
      gatheringUuid
    }
  }
`

export const WINDED_UP = gql`
  mutation setGatheringStatus($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    setGatheringStatus(
      gatheringUuid: $gatheringUuid
      congressUuid: $congressUuid
      biogenEventUuid: $biogenEventUuid
      targetStatus: WINDED_UP
    ) {
      gatheringUuid
    }
  }
`

export const REOPEN = gql`
  mutation reopenGathering($gatheringUuid: GUID!, $congressUuid: GUID, $biogenEventUuid: GUID) {
    reopenGathering(gatheringUuid: $gatheringUuid, congressUuid: $congressUuid, biogenEventUuid: $biogenEventUuid) {
      gatheringUuid
    }
  }
`

export const GET_AVAILABLE_AND_USED_CATEGORIES = gql`
  query getAvailableAndUsedCategories($gatheringUuid: GUID!) {
    getAvailableAndUsedCategories(gatheringUuid: $gatheringUuid) {
      id
      label
      eventTypes
      color
      tags
      modules
    }
  }
`

export const GET_GATHERING_CORRECTION_REQUESTS = gql`
  query gatheringCorrectionRequests($gatheringUuid: GUID!) {
    gatheringCorrectionRequests(gatheringUuid: $gatheringUuid) {
      gatheringCorrectionRequestUuid
      gatheringUuid
      gathering {
        updatedAt
      }
      userUuid
      user {
        userUuid
        firstName
        lastName
      }
      comment
      createdAt
    }
  }
`

export const IS_GATHERING_NAME_AVAILABLE = gql`
  query isGatheringNameAvailable($name: String!, $gatheringUuid: GUID) {
    isGatheringNameAvailable(name: $name, gatheringUuid: $gatheringUuid)
  }
`
