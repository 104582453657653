<template>
  <div class="MybbRadios">
    <mybb-text
      v-if="label"
      :weight="weight"
      :size="size"
      :class="{ 'd-block': twoRows }"
      :color="Boolean($attrs['error-messages']) ? 'error' : textColor"
    >
      {{ label }}
    </mybb-text>
    <v-radio-group
      v-model="val"
      :class="{ 'mt-2': !row, 'd-inline-block': row || twoRows, 'ml-5': row && !dense }"
      hide-details
      dense
      :row="row || twoRows"
      v-bind="$attrs"
    >
      <v-radio
        v-for="item in items"
        :key="item.value"
        :value="item.value"
        :disabled="item.disabled || disabled"
        :color="item.disabled || disabled ? 'mybb-grey-lighten1' : 'mybb-primary-lighten1'"
        :off-icon="asCheckbox ? '$checkboxOff' : '$radioOff'"
        :on-icon="asCheckbox ? '$checkboxOn' : '$radioOn'"
        v-bind="$attrs"
      >
        <mybb-text
          slot="label"
          :color="Boolean($attrs['error-messages']) ? 'error' : item.disabled || disabled ? 'mybb-grey-lighten1' : null"
          v-cy="`${cypress}/${item.value}`"
        >
          {{ item.label }}
          <mybb-text
            v-if="item.append"
            :class="{ 'font-italic': item.appendItalic, 'mybb-grey-lighten1--text': item.disabled || disabled }"
          >
            {{ item.append }}
          </mybb-text>
        </mybb-text>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'MybbRadios',
  props: {
    label: {
      type: String
    },
    size: {
      type: String,
      required: false,
      default: '14',
      validator(prop) {
        return ['24', '20', '18', '16', '14', '12', '10'].includes(prop)
      }
    },
    value: {
      type: [String, Boolean, Number]
    },
    items: {
      type: Array,
      validator(prop) {
        return (
          !prop ||
          (Array.isArray(prop) && prop.every(item => item.hasOwnProperty('value') && item.hasOwnProperty('label')))
        )
      }
    },
    row: {
      type: Boolean
    },
    textColor: {
      type: String,
      default: ''
    },
    twoRows: {
      type: Boolean
    },
    weight: {
      type: String,
      default: 'regular'
    },
    dense: {
      type: Boolean
    },
    cypress: {
      type: String
    },
    asCheckbox: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.MybbRadios {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;

    .v-radio {
      position: relative;

      .v-input--selection-controls__input {
        display: inline-block;
        transform: translateY(2px);
      }
    }
  }
}
</style>
