import { reach } from 'yup'

export default {
  data() {
    return {
      yupErrors: [],
      yupErrorMessageMap: {}
    }
  },
  methods: {
    validateSchema(object, schema = this.schema, additionalContext = {}) {
      try {
        const pojo = schema.validateSync(object, {
          strict: false,
          abortEarly: false,
          stripUnknow: true,
          recursive: true,
          context: { ...object, ...additionalContext }
        })

        this.yupErrors = []

        return pojo
      } catch (error) {
        // console.error(JSON.stringify(error, null, 2))
        if (!error.inner) {
          throw error
        }

        const errorProperties = []

        for (const field of error.inner) {
          errorProperties.push(field.type && field.type.startsWith('custom') ? field.type : field.path)
        }

        this.yupErrors = errorProperties
        return false
      }
    },
    castSchema(object, schema = this.schema) {
      return schema.cast(object, {
        strict: false,
        abortEarly: false,
        stripUnknow: true,
        recursive: true,
        context: object
      })
    },
    collect(field, message) {
      const hasError = this.yupErrors.includes(field)

      if (!hasError) return null

      return field in this.yupErrorMessageMap
        ? this.yupErrorMessageMap[field]
        : message || this.$t('customError.required')
      // return this.yupErrors.includes(field) ? message || this.$t('customError.required') : null
    },
    revalidate(field, value) {
      if (!this.yupErrors.includes(field)) return

      try {
        reach(this.schema, field).validateSync(value)

        this.yupErrors = this.yupErrors.filter(error => error !== field)
      } catch (error) {
        if (!this.yupErrors.includes(field)) {
          this.yupErrors.push(field)
        }
      }
    },
    clear(field) {
      this.yupErrors = this.yupErrors.filter(error => error !== field)

      if (field in this.yupErrorMessageMap) {
        delete this.yupErrorMessageMap[field]
      }
    },
    setError(field, message) {
      this.yupErrors.push(field)

      if (message) {
        this.yupErrorMessageMap[field] = message
      }
    },
    checkMobilePhoneFormat(value) {
      return (
        (value.length === 12 && value.charAt(0) === '+' && /^\d+$/.test(value.substring(1))) ||
        (value.length === 13 && value.includes('0033') && /^\d+$/.test(value)) ||
        (value.length === 10 && value.charAt(0) === '0' && /^\d+$/.test(value))
      )
    }
  }
}
