const details = require('./details')
const form = require('./form')
const list = require('./list')
const participant = require('./participant')
const sidePanel = require('./sidePanel')
const transport = require('./transport')
const hosting = require('./hosting')

module.exports = {
  details,
  form,
  header: link => `gathering/header/${link}`,
  list,
  participant,
  sidePanel,
  transport,
  hosting
}
