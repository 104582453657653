import gql from 'graphql-tag'

import BASE from './Base'

export default gql`
  fragment ParticipantWithHostings on Participant {
    ...ParticipantBase
    overnightStays {
      date
      overnightStayUuid
      hostingNight {
        hosting {
          name
          roomType
        }
      }
      status
    }
    hostingParticipantRequests {
      hostingParticipantRequestUuid
      hostingUuid
      hosting {
        name
        roomType
      }
      participantUuid
    }
  }
  ${BASE}
`
