<template>
  <router-view />
</template>

<script>
import moment from 'moment'

export default {
  name: 'App',
  created() {
    moment.locale('fr')
  }
}
</script>
