const semver = require('semver')

const constants = require('./constants')
const { availablesVersions, versionByKey } = require('./manifest.json')

/**
 * Get the categories for the given version
 * @param {string} version
 * @returns {Category[]}
 */
function forVersion(version = null) {
  if (!version) throw new Error(`version is required, got "${version}"`)

  const coercedVersion = semver.coerce(version)
  if (!semver.valid(coercedVersion)) throw new Error(`version is not a valid semver, got "${version}"`)

  const { major, minor } = coercedVersion
  const maxVersion = semver.maxSatisfying(availablesVersions, `${major}.${minor}.x`)

  if (!maxVersion) throw new Error(`No categories found with given version`)

  return Object.freeze(versionByKey[maxVersion])
}

/**
 * Get category for given version and category ID
 * @param {string} version
 * @param {string} categoryId
 * @returns {Category}
 */
function forVersionAndCategoryId(version, categoryId) {
  if (!categoryId) throw new Error('categoryId are required')

  const categories = forVersion(version)

  return categories.find(category => category.id === categoryId)
}

/**
 * Get all the categories which contains at least 1 tag from the request one, based on the given version
 * @param {CategoryTag} tags
 * @param {string} verion
 * @param {CategoryEventType} eventType
 * @returns {Category[]}
 */
function withTags(tags, version = null, eventType = null) {
  if (!tags || !Array.isArray(tags)) throw new Error(`tags are required`)
  if (!version) throw new Error(`You must provide a category version`)

  const categories = !eventType ? forVersion(version) : forEventType(eventType, version)
  return categories.filter(category => category.tags.some(tag => tags.includes(tag)))
}

/**
 * Retrieve the [CategoryEventType] from a gathering
 * @param {{ beginDate: string, endDate: string, congress: Object, biogenEvent: Object }} gathering
 * @returns {CategoryEventType}
 */
function getEventTypeFromGathering(gathering) {
  if (!gathering) throw new Error('gathering must be provided')
  if (!gathering.beginDate || !gathering.endDate) throw new Error('gathering must have "beginDate" and "endDate"')

  const { CongressLong, CongressShort, StandaloneLong, StandaloneShort } = constants.EventType

  const isShort = gathering.beginDate === gathering.endDate
  const isCongress = Boolean(gathering.congress)
  const isStandalone = Boolean(gathering.biogenEvent)

  if (!isCongress && !isStandalone) {
    throw new Error('gathering is neither a congress nor a standalone')
  } else if (isCongress) {
    return isShort ? CongressShort : CongressLong
  } else if (isStandalone) {
    return isShort ? StandaloneShort : StandaloneLong
  }
}

/**
 * Get the categories available for the given event type and version
 * @param {CategoryEventType} eventType
 * @param {string} version
 * @returns {Category[]}
 */
function forEventType(eventType, version = null) {
  if (!eventType) throw new Error('You must provide an eventType')
  if (!version) throw new Error(`You must provide a category version`)

  const categories = forVersion(version)
  return categories.filter(category => category.eventTypes.includes(eventType))
}

/**
 * Get the "NO_CATEGORY" category for the given version
 * @param {string} version
 * @returns {Category}
 */
function noCategoryForVersion(version = null) {
  if (!version) throw new Error(`You must prove a category version`)

  return forVersion(version).find(category => category.id === constants.NoCategoryId)
}

const categoryIdsSet = new Set()
for (const version of availablesVersions) {
  versionByKey[version].forEach(category => categoryIdsSet.add(category.id))
}

const sortedCategories = availablesVersions.sort(semver.compare)

/**
 * @typedef {"CONGRESS_SHORT" | "CONGRESS_LONG" | "STANDALONE_SHORT" | "STANDALONE_LONG"} CategoryEventType
 *
 * @typedef {"ORATOR" | "STAFF" | "TRANSPARENCE"} CategoryTag
 *
 * @typedef {"EXPENSE_NOTE" | "HOSTING" | "RESTAURATION" | "TRANSPORT"} CategoryModule
 *
 * @typedef {{
 *  id: string,
 *  label: string,
 *  eventTypes: CategoryEventType[],
 *  color: string,
 *  tags: CategoryTag[],
 *  modules: CategoryModule[]
 * }} Category
 *
 * @typedef {{
 *  EventType: { CongressShort: CategoryEventType, CongressLong: CategoryEventType, StandaloneShort: CategoryEventType, StandaloneLong: CategoryEventType },
 *  Tag: { Orator: CategoryTag, Staff: CategoryTag, Transparent: CategoryTag },
 *  Module: { ExpenseNote: CategoryModule, Hosting: CategoryModule, Restauration: CategoryModule, Transport: CategoryModule }
 * }} CategoryConstant
 */

/**
 * @type {{
 *  allIds: string[]
 *  availablesVersions: string[]
 *  constants: CategoryConstant
 *  forEventType: function(eventType: string, version: string): Category[]
 *  forVersion: function(version: string): string
 *  forVersionAndCategoryId: function(version: string, categoryId: string): string
 *  getEventTypeFromGathering: function({ beginDate: string, endDate: string, congress: Object, biogenEvent: Object }): CategoryEventType
 *  latestVersion: string
 *  noCategoryForVersion: function(version: string): Category
 *  withTags: function(tags: CategoryTag[], version: string, eventType: CategoryEventType)
 * }}
 */
module.exports = Object.freeze({
  allIds: Array.from(categoryIdsSet),
  availablesVersions: sortedCategories,
  constants,
  forEventType,
  forVersion,
  forVersionAndCategoryId,
  getEventTypeFromGathering,
  latestVersion: sortedCategories[sortedCategories.length - 1],
  noCategoryForVersion,
  withTags
})
