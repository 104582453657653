import store from '../store'

export function hasOneOfType(pTypes) {
  let types = pTypes

  if (typeof types === 'string') {
    types = [pTypes]
  }

  if (!store.state.myUser.userTypes) {
    return false
  }

  const { userTypes } = store.state.myUser
  return types.some(type => userTypes.includes(type))
}
