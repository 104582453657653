import gql from 'graphql-tag'

import BASE from './Base'

export default gql`
  fragment ParticipantWithTransfer on Participant {
    ...ParticipantBase
    transfer {
      transferUuid
      hasTransport
      hasOther
      totalPrice
    }
  }
  ${BASE}
`
