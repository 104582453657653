import gql from 'graphql-tag'

import BASE from './Base'

export const WITH_PARTICIPANT_COMMENT = gql`
  fragment ParticipantWithParticipantComments on Participant {
    ...ParticipantBase
    participantComments {
      fileUuid
      participantCommentUuid
      createdAt
      comment
      reason
      file {
        name
        fileUuid
        content
      }
    }
  }
  ${BASE}
`

export const WITH_HOSTING_COMMENT = gql`
  fragment ParticipantWithHostingComments on Participant {
    ...ParticipantBase
    hostingComments {
      comment
      hostingCommentUuid
      participantUuid
    }
  }
  ${BASE}
`

export default gql`
  fragment ParticipantWithAllComments on Participant {
    ...ParticipantWithHostingComments
    ...ParticipantWithParticipantComments
  }
  ${WITH_HOSTING_COMMENT}
  ${WITH_PARTICIPANT_COMMENT}
`
