<template>
  <v-select v-bind="props" v-on="$listeners" class="CboSelectField" height="46" outlined>
    <template v-if="icon" v-slot:prepend-inner>
      <v-icon>{{ icon }}</v-icon>
    </template>

    <template v-for="(_, slot) of $scopedSlots || []" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CongrexBoSelectField',
  props: {
    // Reverse [hide-details] props to [true] by default (most used case)
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => ({ offsetY: true })
    }
  },
  computed: {
    props() {
      const hideDetails = this.hideDetails && !this.$attrs['error-messages']
      return Object.assign({}, this.$props, this.$attrs, { hideDetails })
    }
  }
}
</script>

<style lang="scss">
@import './mixin.scss';

.CboSelectField {
  @include cbo-fields;
}
</style>
