export const event = {
  availablePlace: 'required|integer|min_value:0',
  biogenEventType: 'required',
  // validation of displayed value dd/MM/yyyy-dd/MM/yyyy and not the model format
  datesRange: { required: true, regex: /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/ },
  doctorQuota: 'required',
  event: 'required',
  hasAutomatedValidation: 'required',
  hasZoneQuota: 'required',
  invitationType: 'required',
  learnedSociety: 'required',
  name: 'required',
  nationalityType: 'required'
}
