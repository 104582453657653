import gql from 'graphql-tag'

export const SERF = gql`
  fragment ScientificExchangeRequestForm on ScientificExchangeRequestForm {
    signatureDate
    status
    expirationDate

    alzheimer
    amyotrophieSpinale
    autres
    biomarqueur
    cognition
    diagnostic
    etudesReellesFr
    etudesReellesInter
    imagerie
    parcoursDeSoins
    pipelineEtResultatClinique
    scleroseEnPlaques
    strategieEtGuidelines
    therapeutiquesBiogen

    autresBbu
    biomarqueurBbu
    caracteristiquesBiosimilaireBbu
    effetNoceboBbu
    etudesReellesBbu
    inflammatoireIntestinBbu
    parcoursDeSoinsBbu
    pathologiesOphtalmiquesBbu
    patientReportedOutcomesBbu
    pipelineEtResultatCliniqueBbu
    psoriasisEtHidrosadeniteBbu
    rhumatismeBbu
    strategieEtGuidelinesBbu
    therapeuticDrugMonitoringEtOptiomisationBbu
    therapeutiquesBiogenBbu
  }
`

export const FULL_SERF = gql`
  fragment AllSerfTopics on ScientificExchangeRequestForm {
    alzheimer
    amyotrophieSpinale
    autres
    biomarqueur
    cognition
    diagnostic
    etudesReellesFr
    etudesReellesInter
    expirationDate
    imagerie
    parcoursDeSoins
    pipelineEtResultatClinique
    scleroseEnPlaques
    signatureDate
    strategieEtGuidelines
    therapeutiquesBiogen

    autresBbu
    biomarqueurBbu
    caracteristiquesBiosimilaireBbu
    effetNoceboBbu
    etudesReellesBbu
    inflammatoireIntestinBbu
    parcoursDeSoinsBbu
    pathologiesOphtalmiquesBbu
    patientReportedOutcomesBbu
    pipelineEtResultatCliniqueBbu
    psoriasisEtHidrosadeniteBbu
    rhumatismeBbu
    strategieEtGuidelinesBbu
    therapeuticDrugMonitoringEtOptiomisationBbu
    therapeutiquesBiogenBbu
  }
`
