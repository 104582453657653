import 'babel-polyfill'
import 'isomorphic-fetch'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { createProvider } from './vue-apollo'
import get from 'lodash/get'
import CONST from './const/shared'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import eventBus from './services/bus'
import DataTableHelper from './plugins/data-table-helper'
require('moment/locale/fr')
import * as validate from '@/services/validator'
import '@/components/mybb/global'
import '@/directives'
import cypressIdentifier from '../tests/e2e/identifiers'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'dev'
Vue.prototype.$log = console.log
Vue.prototype.$baseUrl = process.env.VUE_APP_HTTP
Vue.prototype.$get = get
Vue.prototype.$bus = eventBus
Vue.prototype.$cy = cypressIdentifier

/**
 * @type {CONST}
 */
Vue.prototype.$const = CONST
Vue.prototype.$validate = validate
Vue.use(DataTableHelper)
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
