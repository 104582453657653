<template>
  <div class="d-flex justify-center align-content-center py-12">
    <v-progress-circular indeterminate :color="color" size="64" />
  </div>
</template>

<script>
export default {
  name: 'LoaderTab',

  props: {
    color: {
      type: String,
      default: 'congrex-primary'
    }
  }
}
</script>
