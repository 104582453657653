import gql from 'graphql-tag'

import { SERF } from '../Serf/Serf'
import { ZONE } from '../Zone/Zone'

export const HCP_LIGHT_PRIMARY_DATA = gql`
  fragment LightHealthCareProfessional on HealthCareProfessional {
    abmBbuEmail
    abmSmaEmail
    abmTecEmail
    abmTysEmail
    adeliNumber
    address
    businessUnitUuid
    businessUnit {
      businessUnitUuid
      label
      externalId
    }
    healthCareOrganization
    healthCareProfessionalUuid
    honorific
    firstName
    gender
    lastName
    postalCode
    city
    hasRefused
    refusedDate
    emailAddress
    emailConsent
    rawSpecialty
    rppsNumber
    specialty
    targetMs
    targetBbu
    targetSma
    title
    type
    veevaId
    phoneNumber
  }
`

export const HCP_PRIMARY_DATA = gql`
  fragment HealthCareProfessional on HealthCareProfessional {
    abmBbuEmail
    abmSmaEmail
    abmTecEmail
    abmTysEmail
    adeliNumber
    address
    bankFileStatementUuid
    businessUnitUuid
    businessUnit {
      businessUnitUuid
      label
      externalId
    }
    zone {
      label
      zoneUuid
      mslMeta {
        user {
          email
        }
      }
    }
    healthCareOrganization
    healthCareProfessionalUuid
    honorific
    firstName
    gender
    lastName
    postalCode
    city
    hasRefused
    refusedDate
    emailAddress
    emailConsent
    mslResponsible {
      lastName
      email
    }
    rawSpecialty
    rppsNumber
    specialty
    targetMs
    targetBbu
    targetSma
    title
    type
    veevaId
    hcpAdditionalInformation {
      address
      bankStatement
      birthDate
      country
      emailAddress
      idCard
      passportNumber
      planeSubscriptionExpirationDate
      planeSubscriptionName
      planeSubscriptionNumber
      trainCardExpirationDate
      trainCardName
      trainCardNumber
    }
    solicitations {
      congressUuid
      status
      createdAt
      updatedAt
      initiator {
        firstName
        lastName
      }
    }
    participants {
      participantUuid
      gatheringUuid
      gathering {
        categoryVersion
        biogenEvent {
          biogenEventUuid
        }
        congress {
          congressUuid
        }
      }
      category
    }
    phoneNumber
  }
`

export const HCP_LIST = gql`
  fragment HealthCareProfessional on HealthCareProfessional {
    businessUnitUuid
    businessUnit {
      businessUnitUuid
      label
    }
    healthCareProfessionalUuid
    firstName
    lastName
    postalCode
    city
    hasRefused
    refusedDate
    emailAddress
    emailConsent
    specialty
    targetMs
    targetBbu
    targetSma
    title
    hcpAdditionalInformation {
      address
      birthDate
      country
      idCard
      passportNumber
      planeSubscriptionExpirationDate
      planeSubscriptionName
      planeSubscriptionNumber
      trainCardExpirationDate
      trainCardName
      trainCardNumber
    }
    archives {
      status
      statusDate
    }
    zone {
      ...Zone
    }
    scientificExchangeRequestForm {
      ...ScientificExchangeRequestForm
    }
  }
  ${SERF}
  ${ZONE}
`

export const HCP_PARTIAL = gql`
  query hcpInfo($healthCareProfessionalUuid: GUID) {
    healthCareProfessional(healthCareProfessionalUuid: $healthCareProfessionalUuid) {
      firstName
      lastName
      veevaId
    }
  }
`

export const HCP_FULL = gql`
  query hcpFull($healthCareProfessionalUuid: GUID) {
    healthCareProfessional(healthCareProfessionalUuid: $healthCareProfessionalUuid) {
      ...HealthCareProfessional
      healthCareProfessionalFiles {
        healthCareProfessionalFileUuid
        gatheringUuid
        name
        addedDate
        expirationDate
        file {
          name
          content
        }
      }
    }
  }
  ${HCP_PRIMARY_DATA}
`

export const HCP_NEW_SERF = gql`
  fragment HcpNewSerf on HealthCareProfessional {
    lastName
    firstName
    postalCode
    city
  }
`

export const UPDATE_RIB_LINE_HCP = gql`
  mutation editBankStatementHealthCareProfessional($healthCareProfessionalUuid: GUID!, $bankStatement: String!) {
    editBankStatementHealthCareProfessional(
      healthCareProfessionalUuid: $healthCareProfessionalUuid
      bankStatement: $bankStatement
    )
  }
`

export const SEARCH_HCPS_FOR_DATATABLE = gql`
  query searchHcpForHcpTable(
    $search: String
    $limit: Int
    $offset: Int
    $sort: JSONObject
    $excludeParticipants: Boolean
    $gatheringUuid: GUID
    $virtualSessionUuid: GUID
    $businessUnitFilters: [InputHcpBusinessUnitFilter]
  ) {
    searchHcpForHcpTable(
      search: $search
      limit: $limit
      offset: $offset
      sort: $sort
      excludeParticipants: $excludeParticipants
      gatheringUuid: $gatheringUuid
      virtualSessionUuid: $virtualSessionUuid
      businessUnitFilters: $businessUnitFilters
    ) {
      total
      hcps {
        firstName
        lastName
        healthCareProfessionalUuid
        type
        postalCode
        city
        businessUnit {
          businessUnitUuid
          label
          externalId
        }
        zone {
          label
        }
        mslResponsible {
          lastName
        }
        title
        targetAd
        targetMs
        targetBbu
        targetKme
        targetSma
      }
      missedGatheringForHcps
    }
  }
`

export const GET_HCP_EVENTS = gql`
  query healthCareProfessional($hcpUuid: GUID) {
    healthCareProfessional(healthCareProfessionalUuid: $hcpUuid) {
      participants {
        category
        presence
        invitationStatus
        gathering {
          gatheringUuid
          name
          status
          gatheringType
          beginDate
          city
          congress {
            congressUuid
            nationalityType
          }
          categoryVersion
        }
        healthCareProfessional {
          solicitations {
            solicitationUuid
            status
            congressUuid
          }
        }
      }
    }
  }
`

export const SEARCH_HCPS_FOR_SELECTION = gql`
  query searchHcpForHcpTable(
    $search: String
    $excludeParticipants: Boolean
    $gatheringUuid: GUID
    $businessUnitFilters: [InputHcpBusinessUnitFilter]
  ) {
    searchHcpForHcpTable(
      search: $search
      limit: -1
      offset: 0
      excludeParticipants: $excludeParticipants
      gatheringUuid: $gatheringUuid
      businessUnitFilters: $businessUnitFilters
      uuidOnly: true
    ) {
      total
      hcps {
        healthCareProfessionalUuid
      }
    }
  }
`

export const HCP_FOR_SOLICITATION_CREATION = gql`
  query healthCareProfessionalByExternalId($veevaId: String) {
    healthCareProfessionalByExternalId(veevaId: $veevaId) {
      healthCareProfessionalUuid
      attentendIntercontinentalCongressYears
      isSolicitationBannedFromGathering {
        name
      }
    }
  }
`
