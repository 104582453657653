module.exports = {
  container: 'gathering/hosting/container',
  configuration: {
    button: 'gathering/hosting/configuration/button',
    modal: {
      container: 'gathering/hosting/configuration/container',
      eveArrival: {
        yes: 'gathering/hosting/configuration/eveArrival/yes',
        no: 'gathering/hosting/configuration/eveArrival/no'
      },
      chooseNights: {
        yes: 'gathering/hosting/configuration/chooseNights/yes',
        no: 'gathering/hosting/configuration/chooseNights/no'
      },
      submit: 'gathering/hosting/configuration/submit'
    }
  }
}
