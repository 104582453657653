<template>
  <span :class="classes" v-on="$listeners">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'MybbText',
  props: {
    color: {
      type: String,
      default: ''
    },
    weight: {
      type: String,
      required: false,
      default: 'regular',
      validator(prop) {
        return ['regular', 'medium', 'semi-bold', 'bold', 'black'].includes(prop)
      }
    },
    size: {
      type: String,
      required: false,
      default: '14',
      validator(prop) {
        return ['24', '20', '18', '16', '14', '12', '10'].includes(prop)
      }
    }
  },
  computed: {
    classes() {
      const classes = ['CboText', `CboText-weight-${this.weight}`, `CboText-size-${this.size}`]

      if (this.color) {
        classes.push(`${this.color}--text`)
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  // Default color
  color: var(--v-mybb-primary-base);
}

.CboText {
  font-family: 'Mulish', sans-serif;
  display: inline-block;
  vertical-align: middle;

  &.CboText-weight {
    &-regular {
      font-weight: 400;
    }

    &-medium {
      font-weight: 500;
    }

    &-semi-bold {
      font-weight: 600;
    }

    &-bold {
      font-weight: 700;
    }

    &-black {
      font-weight: 900;
    }
  }

  &.CboText-size {
    &-24 {
      font-size: 24px;
    }

    &-20 {
      font-size: 20px;
    }

    &-18 {
      font-size: 18px;
    }

    &-16 {
      font-size: 16px;
    }

    &-14 {
      font-size: 14px;
    }

    &-12 {
      font-size: 12px;
    }

    &-10 {
      font-size: 10px;
    }
  }
}
</style>
