import get from 'lodash/get'
import isNil from 'lodash/isNil'
import parse from 'date-fns/parse'

const dataTableSort = (sortMapping = {}) => (items, [sortBy], [sortDesc]) => {
  if (!sortBy && !sortDesc) return items

  return items.sort((a, b) => {
    const isEmpty = val => isNil(val) || [null, undefined, '', '- -', '--'].includes(val)

    const property = sortMapping[sortBy] || sortBy

    const value1 = get(a, property)
    const value2 = get(b, property)

    if (!isEmpty(value1) && isEmpty(value2)) return -1
    if (isEmpty(value1) && !isEmpty(value2)) return 1
    if (isEmpty(value1) && isEmpty(value2)) return 0

    let comparison

    if (sortBy && sortBy.toLowerCase().includes('date')) {
      const date1 = parse(value1, 'dd/MM/yy', new Date())
      const date2 = parse(value2, 'dd/MM/yy', new Date())

      comparison = date1.getTime() - date2.getTime()
    } else if (typeof value1 === 'number' && typeof value2 === 'number') {
      comparison = value1 - value2
    } else if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
      comparison = Number(value1) - Number(value2)
    } else if (Array.isArray(value1, value2)) {
      comparison = value1
        .map(i => i[sortMapping.arr[sortBy]])
        .join('')
        .localeCompare(value2.map(i => i[sortMapping.arr[sortBy]]).join(''))
    } else {
      comparison = value1.localeCompare(value2)
    }

    return comparison * (sortDesc ? -1 : 1)
  })
}

const dataTableFilter = (filterFields = {}) => (value, search, item) => {
  const searchSlugify = _slugify(search)

  return Object.keys(item).some(key => {
    const value = get(item, filterFields[key], null)
    return value && _slugify(value).includes(searchSlugify)
  })
}

/**
 * Clear string to delete case & accents
 *
 * @param {string} text
 * @returns {string}
 * @private
 */
const _slugify = text => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export default {
  install(Vue) {
    Vue.prototype.$dataTableSort = dataTableSort
    Vue.prototype.$dataTableFilter = dataTableFilter
  }
}
