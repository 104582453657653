import _omit from 'lodash/omit'

export default {
  methods: {
    /**
     * Format the backend file content to a proper data URI
     * @param {{ name: string, content: string }} file
     * @return {{ name: string, content: string }}
     */
    formatBackendFile(file) {
      if (!file) return file

      const data = _omit(file, '__typename')

      // Build back the base64 data URL as it is trim by the backend
      // Which mean further save of that trimed data will corrupt the file content
      const extension = data.name.split('.').pop()
      const content = data.content.startsWith('data:')
        ? data.content
        : `data:${
            ['jpg', 'jpeg', 'png'].includes(extension) ? `image/${extension}` : `application/${extension}`
          };base64,${data.content}`

      return {
        ...data,
        content
      }
    },
    /**
     * Open a data URI in a new tab
     * @param {{ content: string }} file
     */
    async openFile(file) {
      if (!file || !(file && file.content)) return

      const formatedFile = this.formatBackendFile(file)
      const fetchResult = await fetch(formatedFile.content)
      const blob = await fetchResult.blob()
      const fileUrl = URL.createObjectURL(blob)

      window.open(fileUrl)
    }
  }
}
